import React, { useContext, useEffect, useState } from 'react';
import { MbContext } from './Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImgThumbnail from './ImgThumbnail';

const ImageThumbnailMaker = ({ images, setImages, mainImageIndex = 0, setMainImageIndex = null }) => {
    const mb = useContext(MbContext);
    const [imageCounter, setImageCounter] = useState(0);

    const handleImageChange = (e) => {
        console.log(imageCounter);

        if(imageCounter + e.target.files.length > 19) {
            mb.setDisplayMb(true);
            mb.setMessages(["Maximum 19 képet csatolhatsz!"]);
            return;
        }

        setImageCounter(c=>c+e.target.files.length);
        const fileList = Array.from(e.target.files);
        setImages(prevImages => [...prevImages, ...fileList]);
        e.target.value = "";
    };

    const handleMainImageSelect = (index) => {
        if(setMainImageIndex)
            setMainImageIndex(index);
    };

    const handleDeleteImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
        setImageCounter(c=>--c);

        if (setMainImageIndex && index === mainImageIndex) {
            setMainImageIndex(null);
        } else if (index < mainImageIndex) {
            setMainImageIndex(prevMainIndex => prevMainIndex - 1);
        }
    };

    return (
        <div className="box-secondary p-lg mt-lg">
            <h2 className="color-white">Képek hozzáadása</h2>
            <div className="mb-lg">
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                />
            </div>
            <div className="flex jc-start f-wrap-wrap">
                {images.map((image, index) => (
                    <ImgThumbnail key={index}
                        src={URL.createObjectURL(image)}
                        width={200} height={200}
                        hasDelete={true} delCb={() => handleDeleteImage(index)}
                        imgCb={() => handleMainImageSelect(index)}
                        classes={[
                            "border-primary-2",
                            "cursor-pointer",
                            "m-sm", index === mainImageIndex ? 'border-warning-2' : ''
                        ]}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageThumbnailMaker;