import React, { useState } from 'react';
import { sBaseUrl } from '../../app/url';
import MessageBox from '../../components/MessageBox';
import { useContext } from 'react';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';

function UpdatePass() {
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [newPassAgain, setNewPassAgain] = useState('');
    const mb = useContext(MbContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPass !== newPassAgain) {
            mb.setDisplayMb(true);
            mb.setMessages("Az új jelszavak nem egyeznek!");
            return;
        }

        try {
            const response = await fetch(sBaseUrl + '/api/update-pass', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem("token")
                },
                credentials: 'include',
                body: JSON.stringify({
                    currentPass,
                    newPass,
                    newPassAgain
                })
            });

            const data = await response.json();

            if(data.token && data.token.token) 
                storeToken(data.token.token);

            if (response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages("A jelszó sikeresen frissítve lett!");
                setCurrentPass("");
                setNewPass("");
                setNewPassAgain("");
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(data);
            }
        } catch (error) {
            console.log('Error updating password:', error);
            mb.setDisplayMb(true);
            mb.setMessages("A szerver nem elérhető. Kérjük, próbálja meg később.");
        }
    };

    return (
        <div className="box-light-grey text-center p-md">
            <h3>Jelszó felülírása</h3>

            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
                setButtons={mb.setButtons}
            />

            <h4>Jelenlegi jelszó</h4>
            <input
                type="password"
                className="input-sm input-primary wp-100"
                value={currentPass}
                onChange={(e) => setCurrentPass(e.target.value)}
            />

            <h4>Új jelszó</h4>
            <input
                type="password"
                className="input-sm input-primary wp-100"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
            />

            <h4>Új jelszó újra</h4>
            <input
                type="password"
                className="input-sm input-primary wp-100"
                value={newPassAgain}
                onChange={(e) => setNewPassAgain(e.target.value)}
            />

            <button onClick={handleSubmit} className="input-sm btn-secondary cursor-pointer">
                Jelszó frissítése
            </button>
        </div>
    );
}

export default UpdatePass;