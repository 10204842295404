import { cBaseUrl } from "./url";

function createOrderLink(realEstateID, accommodationID, serviceID) {
    let url = cBaseUrl;

    if(realEstateID)
        url += `/ingatlan/${realEstateID}`;
    else if(accommodationID)
        url += `/szallas/${accommodationID}`;
    else if(serviceID)
        url += `/szolgaltatas/${serviceID}`;
    else
        url = "-";

    return url;
}

export default createOrderLink;