function Facilities({ facilities, fKey, fName }) {
    return (
        <>
            <div className="font-14 mb-sm">{fName}</div>
            <div className="flex jc-center f-wrap-wrap">
                {
                    facilities.length >  0 ? facilities.map((rf, i) =>
                        <div key={i} className="box-secondary color-white p-xs rounded-5 m-xs">
                            {rf[fKey]}
                        </div>
                    ) : 
                    <div className="box-secondary color-white p-xs rounded-5 m-xs">
                        nincs megjelölve
                    </div>
                }
            </div>
        </>
    )
}

export default Facilities;