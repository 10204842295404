import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import getFormData from '../../app/getFormData';
import SideMenuUser from '../../components/SideMenuUser';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';
import Validator from '../../app/Validator';
import Address from '../../components/Address';

function PropertyOwnerProfile() {
    const formRef = useRef(null);
    const mb = useContext(MbContext);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        tel: '',
        mobile: '',
        contactEmail: '',
        countryID: 0,
        postalCode: '',
        countyRegions: "",
        settlement: '',
        street: '',
        publicPlaceType: 0,
        houseNumber: '',
        floorNumber: '',
        doorNumber: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(sBaseUrl + `/api/property-owner-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify(profileData)
            });

            const json = await response.json();

            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (error) {
            console.error('Profil frissítése sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getProfileData = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/property-owner-profile", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();
            setProfileData(json);
        } catch (err) {
            console.error('Profil adatok lekérése sikertelen:', err);
        }
    };

    useEffect(() => {
        getProfileData();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuUser />
            <div className="p-xl text-center">

                <h2>Profil frissítése</h2>

                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">
                                <div className='row'>
                                    <div className="col-sm-6 p-md">
                                        <h4>Keresztnév *</h4>
                                        <input onKeyUp={(e) => new Validator().isString()
                                            .hasMinLength(3).hasMaxLength(100).exec(e.target, "input-error")}
                                            value={profileData.firstName}
                                            onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='firstName'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Vezetéknév *</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isString()
                                                .hasMinLength(3).hasMaxLength(100).exec(e.target, "input-error")}
                                            type="text"
                                            value={profileData.lastName}
                                            onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='lastName'
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Vezetékes telefon</h4>
                                        <input
                                            value={profileData.tel}
                                            onChange={(e) => setProfileData({ ...profileData, tel: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='tel'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Mobiltelefon *</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isPhoneNumber().exec(e.target, "input-error")}
                                            value={profileData.mobile}
                                            onChange={(e) => setProfileData({ ...profileData, mobile: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='mobile'
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 p-md">
                                        <h4>Kapcsolati email *</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isEmail().exec(e.target, "input-error")}
                                            value={profileData.contactEmail}
                                            onChange={(e) => setProfileData({ ...profileData, contactEmail: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='contactEmail'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">

                                <Address
                                    formData={profileData}
                                    setFormData={setProfileData}
                                />

                                <div className="row">

                                    <div className="col-sm-6 p-md">
                                        <h4>Emelet</h4>
                                        <input
                                            value={profileData.floorNumber}
                                            onChange={(e) => setProfileData({ ...profileData, floorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='floorNumber'
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Ajtó</h4>
                                        <input
                                            value={profileData.doorNumber}
                                            onChange={(e) => setProfileData({ ...profileData, doorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='doorNumber'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className='btn-sm btn-secondary' type="submit">Profil frissítése</button>
                </form>
            </div>
        </div>
    );
}

export default PropertyOwnerProfile;