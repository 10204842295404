function getMonthName(monthNumber) {
    const monthNames = [
        "január", "február", "március", "április", "május", "június",
        "július", "augusztus", "szeptember", "október", "november", "december"
    ];

    if (monthNumber < 1 || monthNumber > 12) {
        throw new Error("A hónap sorszámának 1 és 12 között kell lennie.");
    }

    return monthNames[monthNumber - 1];
}

export default getMonthName;