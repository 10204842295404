import React, { useContext, useEffect, useState } from 'react';
import { sBaseUrl } from '../../app/url';
import { MbContext } from '../../components/Nav';
import UserHandler from '../../app/UserHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordInput from '../../components/PasswordInput';

function RegisterPage() {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [passAgain, setPassAgain] = useState("");
    const [message, setMessage] = useState('');
    const [userType, setUserType] = useState("");
    const [uh, setUh] = useState(new UserHandler());
    const [userTypes, setUserTypes] = useState([]);
    const [termsAndCond, setTermsAndCond] = useState(false);
    const [gdpr, setGdpr] = useState(false);
    const mb = useContext(MbContext);

    const register = async (e) => {
        e.preventDefault();

        if (!gdpr || !termsAndCond) {
            mb.setDisplayMb(true);
            mb.setMessages(`
                A regisztrációhoz el kell fogadnod az adatkezelési 
                nyilatkozatot és a felhasználási feltételeket!
            `);

            return;
        }

        try {
            const response = await fetch(sBaseUrl + "/api/register", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    pass,
                    passAgain,
                    userType
                })
            });

            const data = await response.json();

            mb.setDisplayMb(true);
            mb.setMessages(data);

            if (response.ok) {
                setEmail("");
                setPass("");
                setPassAgain("");
            }
        } catch (error) {
            console.log('Error:', error.message);
            mb.setDisplayMb(true);
            setMessage('Hiba történt a regisztráció során.');
        }
    };

    useEffect(() => {
        (async () => {
            const response = await uh.getUserTypes();

            if (response.ok)
                setUserTypes(response.json);
        })();
    }, []);

    return (
        <div className='container-xl text-center'>
            <h2>Regisztráció</h2>

            <form className='mw-400 m-auto box-light-grey p-md' onSubmit={register}>
                <div>
                    <h3>Email:</h3>
                    <input type="email" className='input-md input-secondary wp-90'
                        value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <PasswordInput
                    value={pass}
                    onChange={setPass}
                    text="Jelszó"
                />

                <PasswordInput
                    value={passAgain}
                    onChange={setPassAgain}
                    text="Jelszó újra"
                />

                <div>
                    <h3>Regisztráció típusa</h3>
                    <select value={userType} onChange={e => setUserType(e.target.value)}
                        className="input-md input-secondary wp-90">
                        <option value="">Válassz regisztrációs típust!</option>
                        {
                            userTypes.map((ut, i) =>
                                <option key={i} value={ut.typeID}>{ut.typeName}</option>
                            )
                        }
                    </select>
                </div>

                <div>
                    <div className="mb-sm">
                        <label>
                            <input
                                value={gdpr}
                                onChange={e => setGdpr(e.target.checked)}
                                type="checkbox"
                            />
                            Elfogadom az adatkezelést.
                        </label>
                    </div>

                    <div className="mb-sm">
                        <label>
                            <input
                                value={termsAndCond}
                                onChange={e => setTermsAndCond(e.target.checked)}
                                type="checkbox"
                            />
                            Elfogadom a felhasználási feltételeket.
                        </label>
                    </div>

                    <div className="mb-sm">
                        <a target="_blank" href={sBaseUrl + "/files/pdfs/adatkezelesi_nyilatkozat.pdf"}>Adatkezelési nyilatkozat</a>
                    </div>
                    <div>
                        <a target="_blank" href={sBaseUrl + "/files/pdfs/felhasznaloi_szabalyzat.pdf"}>Felhasználói szabályzat</a>
                    </div>
                </div>

                <button className='btn-sm btn-secondary'
                    type="submit">Regisztráció</button>
            </form>

        </div>
    );
}

export default RegisterPage;