import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';
import SideMenu from '../../components/SideMenu';
import { useParams, useNavigate } from 'react-router-dom';
import getType from '../../app/getType';
import ServicePackageForm from '../../components/ServicePackageForm';
import ServicePackagePriceForm from '../../components/ServicePackagePriceForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideMenuAdmin from '../../components/SideMenuAdmin';

function ServicePackagePage() {
    const mb = useContext(MbContext);
    const [packageTypes, setPackageTypes] = useState([]);
    const [prices, setPrices] = useState([]);

    const menuData = [
        {
            url: "/",
            name: "Kezdőlap"
        },
        {
            url: "/admin/service-packages",
            name: "Szolgáltatási csomagok"
        }
    ];

    const { packageID } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        packageName: '',
        packageType: 0,
        packageDesc: '',
        intervalDays: 0
    });

    const [priceData, setPriceData] = useState({
        price: '',
        startDate: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = sBaseUrl + `/api/service-packages`;
            let method = 'POST';

            if (packageID !== undefined && packageID !== null) {
                url += `/${packageID}`;
                method = 'PUT';
            }

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify(formData)
            });

            const json = await response.json();

            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if (response.ok && json.insertID) {
                navigate("/admin/csomag-letrehozasa/" + json.insertID);
            }
        } catch (err) {
            console.error('Szolgáltatási csomag mentése sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const handlePriceSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = sBaseUrl + `/api/service-package-prices`;
            const method = 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify({ ...priceData, packageID })
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if(!response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }

            if (response.ok) {
                priceData.priceID = json.insertID;
                setPrices([...prices, priceData]);
                mb.setMessages("Ár sikeresen mentve!");
            }
        } catch (err) {
            console.error('Ár mentése sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getServicePackage = async () => {
        if (packageID === undefined || packageID === null)
            return;

        try {
            const url = sBaseUrl + `/api/service-packages/${packageID}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();

            setFormData(json);
        } catch (err) {
            console.error('Szolgáltatási csomag lekérése sikertelen:', err);
            throw new Error("Nem sikerült lekérni a szolgáltatási csomagot!");
        }
    };

    const getServicePackagePrices = async () => {
        if (packageID === undefined || packageID === null) return;

        try {
            const url = sBaseUrl + `/api/service-package-prices/${packageID}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include"
            });

            if (!response.ok) {
                throw new Error('Hálózati hiba történt');
            }

            const json = await response.json();
            console.log(json);
            setPrices(json);
        } catch (err) {
            console.error('Árak lekérése sikertelen:', err);
            throw new Error("Nem sikerült lekérni az árakat!");
        }
    };

    const deletePackagePriceConfirm = (packagePriceData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan le akarod törölni a következő áradatot: `,
            `Kezdő dátum: ${packagePriceData.startDate.toString().substr(0,10)}`,
            `Ár: ${packagePriceData.price}`
        ]);
    
        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deletePackagePrice(packagePriceData.priceID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };
    
    const deletePackagePrice = async (priceID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-package-prices/" + priceID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });
    
            const json = await response.json();
    
            if (response.ok) {
                setPrices(prevPrices => prevPrices.filter(p => p.priceID !== priceID));
                mb.setDisplayMb(false);
            }
    
            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Properties.deletePackagePrice: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };    

    useEffect(() => {
        getType("types_packages", setPackageTypes);
        getServicePackage();
        getServicePackagePrices();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin/>

            <div className="p-xl text-center">
                <h2>Szolgáltatási csomag {packageID ? "szerkesztése" : "létrehozása"}</h2>

                {
                    packageID ?
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <ServicePackageForm
                                    formData={formData}
                                    setFormData={setFormData}
                                    handleSubmit={handleSubmit}
                                    packageTypes={packageTypes}
                                    packageID={packageID}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ServicePackagePriceForm
                                    priceData={priceData}
                                    setPriceData={setPriceData}
                                    handlePriceSubmit={handlePriceSubmit}
                                />
                            </div>
                        </div>
                        :
                        <ServicePackageForm
                            formData={formData}
                            setFormData={setFormData}
                            handleSubmit={handleSubmit}
                            packageTypes={packageTypes}
                            packageID={packageID}
                        />
                }

                <div className="row">
                    {
                        prices.map((p, i) =>
                            <div key={i} className="col-lg-3 col-md-6 p-md">
                                <div className="box-secondary p-md">
                                    <h5 className="color-white">Ár: {p.price} HUF</h5>
                                    <h5 className="color-white">Érvényesség kezdete: {p.startDate.toString().substr(0,10)}</h5>
                                    <FontAwesomeIcon 
                                        onClick={()=>deletePackagePriceConfirm(p)}
                                        className="color-error cursor-pointer"
                                        icon="fa fa-trash"
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ServicePackagePage;