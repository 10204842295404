import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { sBaseUrl } from "../../app/url";
import { useEffect, useState } from "react";
import getRegionsByService from "../../app/getRegionsByService";
import checkImg from "../../app/checkImg";

function Professional({ p, highlightCls }) {
    const [countyRegions, setCountyRegions] = useState([]);
    const [settlementRegions, setSettlementRegions] = useState([]);

    useEffect(() => {
        // getRegionsByService(p.serviceID, 1, setCountyRegions);
        // getRegionsByService(p.serviceID, 2, setSettlementRegions);
    }, []);

    return (
        <div className='p-md col-lg-3 col-md-4'>
            <div className={"p-md box-secondary " + highlightCls}>
                <h4 className="color-white">{p.title}</h4>

                <div className="img-holder mt-md">
                    <Link to={`/szolgaltatas/${p.serviceID}`}>
                        <img src={checkImg(p.mainImg)} />
                    </Link>
                </div>

                <h4 className="color-white">Szolgáltatás</h4>
                <b className="color-primary">{p.serviceTypeName}</b>

                {/* <h4 className="color-white">Régiók/vármegyék</h4>
                <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
                    {countyRegions.map((region, index) => (
                        <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
                            {region.regionName}
                        </div>
                    ))}
                </div>

                <h4 className="color-white">Települések</h4>

                <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
                    {settlementRegions.map((region, index) => (
                        <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
                            {region.regionName}
                        </div>
                    ))}
                </div> */}

                <div className="p-md">
                    <Link to={`/szolgaltatas/${p.professionalID}`}>
                        <FontAwesomeIcon className="color-success cursor-pointer font-20"
                            icon="fa-solid fa-arrow-up-right-from-square" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Professional;