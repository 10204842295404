import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemSelector = ({ items, ids, idsSetter, idName, itemName, cb = null }) => {
    const handleClick = (id) => {
        const isSelected = ids.includes(id);
        const newSelectedIds = isSelected 
            ? ids.filter(itemId => itemId !== id) 
            : [...ids, id];
        
        idsSetter(newSelectedIds);

        if(cb)
            cb(id);
    };

    const isSelected = (id, clsOn, cleOff)=> {
        return (ids?.includes(id) ? clsOn : cleOff);
    };

    return (
        <div className="d-flex f-wrap-wrap jc-center">
            {items.map(item => (
                <div className={"position-relative p-xs rounded-5 d-flex ai-center mr-xs mb-xs " 
                    + isSelected(item[idName], "box-success", "box-white")}
                    key={item[idName]}>
                    <div className="mr-xs">{item[itemName]}</div>

                    <FontAwesomeIcon onClick={() => handleClick(item[idName])}
                        data-entityid={item[idName]}
                        className={"cursor-pointer " + isSelected(item[idName], "color-warning", "color-success")}
                        icon={isSelected(item[idName], "fa-solid fa-circle-minus", "fa-solid fa-circle-plus")}
                    />
                </div>
            ))}
        </div>
    );
};

export default ItemSelector;