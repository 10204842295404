import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';

const PageContent = ({ pageContent, pageContents, setPageContents }) => {
    const mb = useContext(MbContext);

    const deletePageConfirm = (pageData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő oldalt: `,
            `${pageData.pageName}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deletePage(pageData.pageID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deletePage = async (pageID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/page-contents/" + pageID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setPageContents(pageContents.filter(p => p.pageID !== pageID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("PageContent.deletePage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    return (
        <div className="p-md col-lg-3 col-md-2">
            <div className="p-md box-secondary">
                <h4 className="color-white">Oldal neve</h4>
                <b>{pageContent.pageName}</b>

                <h4 className="color-white">Létrehozás dátuma</h4>
                <b>{new Date(pageContent.created).toLocaleDateString('hu-HU')}</b>

                <h4 className="color-white">Utolsó módosítás dátuma</h4>
                <b>{pageContent.updated ? new Date(pageContent.updated).toLocaleDateString('hu-HU') : "Nincs módosítva"}</b>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <Link to={`/admin/oldal-szerkesztese/${pageContent.pageID}`}>
                            <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                icon="fa-solid fa-arrow-up-right-from-square" />
                        </Link>
                    </div>

                    <div>
                        <FontAwesomeIcon onClick={() => deletePageConfirm(pageContent)}
                            className="color-error cursor-pointer font-20"
                            icon="fa-solid fa-trash" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageContent;