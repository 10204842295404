import React, { useContext, useEffect, useRef, useState } from 'react';
import Autocomplete from './Autocomplete';
import getAddressTomTom from "../app/getAddressTomTom";
import { sBaseUrl } from '../app/url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getRegionsByService from '../app/getRegionsByService';
import { MbContext } from './Nav';

function RegionSelector({ serviceID, fieldName, regionType, autocompleteID }) {
    const [inputValue, setInputValue] = useState(null);
    const [regions, setRegions] = useState([]);
    const inputRef = useRef();
    const mb = useContext(MbContext);

    const handleAddRegion = async (e) => {
        e.preventDefault();

        if(inputValue === null)
            return;

        try {
            const response = await fetch(sBaseUrl + `/api/service-regions`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify({
                    serviceID: serviceID,
                    regionName: inputValue[fieldName],
                    regionType:regionType
                })
            });

            const json = await response.json();

            if (response.ok) {
                setRegions([...regions, {relationID:json.relationID, regionName:inputValue[fieldName]}]);
                setInputValue(null);
                inputRef.current.value = "";
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }
        } catch (err) {
            console.error('Régió hozzáadása sikertelen:', err);
        }
    };

    const handleRemoveRegion = async (relationID) => {
        try {
            const response = await fetch(sBaseUrl + `/api/service-regions/${relationID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token")
                },
                credentials: "include"
            });

            if (response.ok) {
                setRegions(regions.filter(region => region.relationID !== relationID));
            } else {
                console.error('Hiba a régió törlésekor');
            }
        } catch (err) {
            console.error('Régió törlése sikertelen:', err);
        }
    };

    useEffect(()=> {
        getRegionsByService(serviceID, regionType, setRegions);
    }, []);

    return (
        <div>
            <div>
                <Autocomplete
                    fetchData={getAddressTomTom}
                    fields={[fieldName]}
                    widthCls={"wp-100"}
                    cb={setInputValue}
                    limit={4}
                    inputRef={inputRef}
                    id={autocompleteID}
                />
                <button
                    onClick={handleAddRegion}
                    disabled={inputValue === null}
                    className={"btn-sm ml-2 " + (inputValue === null ? "btn-mid-grey" : "btn-primary")}
                >
                    Hozzáadás
                </button>
            </div>
            <div className="d-flex mt-3 ai-center f-wrap-wrap">
                {regions.map((region, index) => (
                    <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
                        {region.regionName}

                        <FontAwesomeIcon
                            onClick={() => handleRemoveRegion(region.relationID)}
                            icon="fa fa-trash"
                            className="cursor-pointer color-error ml-sm"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RegionSelector;