import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const clientId = "ATz8iSIjxSF_ycJj3m1s6lm9F3pejhF62R8C7luSIPbGO8LRQd8MCg-6pgnOBmUtiUzu0ZK39YSG9f4g";

const PayPalButton = ({ onApprove, onError, price, currency, isDigitalProduct }) => {
    console.log(isDigitalProduct);
    return (
        <PayPalScriptProvider options={{ "client-id": clientId, currency: currency }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: currency,
                                value: price
                            },
                            // Szállítási cím bekérésének kikapcsolása digitális termékek esetén
                        }],
                        application_context: {
                            shipping_preference: isDigitalProduct ? "NO_SHIPPING" : "GET_FROM_FILE"
                        }
                    });
                }}
                onApprove={async (data, actions) => {
                    const order = await actions.order.capture();
                    const billingAddress = order.payer.address;
                    const shippingAddress = order.purchase_units[0].shipping?.address;

                    console.log("Billing Address: ", billingAddress);
                    console.log("Shipping Address: ", shippingAddress);

                    onApprove(data, actions);
                }}
                onError={(err) => onError(err)}
            />
        </PayPalScriptProvider>
    );
}

export default PayPalButton;