import React, { useState, useEffect, useContext } from "react";
import { sBaseUrl } from "../../app/url";
import { MbContext } from "../../components/Nav";

const ContactForm = ({userID}) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        message: "",
    });

    const [captchaToken, setCaptchaToken] = useState(null);
    const siteKey = "6LdYHTgqAAAAAF6Z963H5mn3RvLsdP3yj2mwdL44";
    const mb = useContext(MbContext);

    // Hook a reCAPTCHA script dinamikus betöltéséhez
    useEffect(() => {
        // Ellenőrizd, hogy a script már betöltött-e
        const scriptExists = document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${siteKey}"]`);

        if (!scriptExists) {
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                console.log("reCAPTCHA script loaded!");
            };
        }
    }, [siteKey]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRecaptchaVerify = async () => {
        // Várj, amíg a script betöltődik, és ellenőrizd, hogy az objektum létezik
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(siteKey, { action: "submit" })
                    .then((token) => {
                        setCaptchaToken(token);
                        handleSubmit(token); // Call handleSubmit here
                    })
                    .catch((error) => {
                        console.error("reCAPTCHA token error:", error);
                        alert("Hiba történt a reCAPTCHA ellenőrzése közben.");
                    });
            });
        } else {
            console.error("reCAPTCHA nem érhető el.");
        }
    };

    const handleSubmit = async (captchaToken) => {
        try {
            formData.userID = userID;

            const response = await fetch(`${sBaseUrl}/api/send-email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...formData,
                    captchaToken,
                }),
            });

            const json = await response.json();
            mb.setDisplayMb(true);
            mb.setMessages(json);

            if (response.ok) {
                setFormData({
                    name: "",
                    phone: "",
                    message: "",
                });
                setCaptchaToken(null); // Reset CAPTCHA token
            } else {
                alert("CAPTCHA validáció sikertelen!");
            }
        } catch (error) {
            console.error("Hiba az üzenet küldése közben:", error);
            alert("Hiba történt az üzenet küldése közben.");
        }
    };

    return (
        <form onSubmit={(e) => { e.preventDefault(); handleRecaptchaVerify(); }}>
            <div className="row">
                <div className="col-lg-6 pl-md pr-md">
                    <div>
                        <h4>Név</h4>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="input-md input-primary wp-100"
                        />
                    </div>
                </div>

                <div className="col-lg-6 pl-md pr-md">
                    <div>
                        <h4>Telefonszám</h4>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="input-md input-primary wp-100"
                        />
                    </div>
                </div>

                <div className="col-lg-12 pl-md pr-md">
                    <h4>Üzenet</h4>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="input-md input-primary minwp-100 maxwp-100 minh-100"
                    ></textarea>

                    <button
                        type="submit"
                        className="input-md btn-primary cursor-pointer"
                    >
                        Küldés
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
