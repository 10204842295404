import React, { useContext, useState } from 'react';
import { sBaseUrl } from '../../app/url';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { useNavigate } from 'react-router-dom';
import storeToken from '../../app/storeToken';
import isCookieConsented from '../../app/isCookieConsented';
import PasswordInput from '../../components/PasswordInput';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const mb = useContext(MbContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!isCookieConsented()) {
            mb.setDisplayMb(true);
            mb.setMessages("A bejelentkezéshez el kell fogadnod az adatkezelési nyilatkozatot!");
            return;
        }

        try {
            const response = await fetch(sBaseUrl + "/api/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials:'include',
                body: JSON.stringify({
                    email: email,
                    pass: password
                })
            });

            const json = await response.json();
            mb.setDisplayMb(true);
            mb.setMessages(json);

            if(response.ok) {
                setEmail("");
                setPassword("");
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <div className='container-xl text-center'>
            <h2>Sign in</h2>

            <form className='box-light-grey mw-400 m-auto p-md' onSubmit={handleSubmit}>
                <div>
                    <h3>Email</h3>
                    <input type="email" className='input-md input-secondary wp-90'
                    value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <PasswordInput
                    value={password}
                    onChange={setPassword}
                    text="Jelszó"
                />
                <button className='btn-sm btn-secondary' 
                type="submit">Bejelentkezés</button>
            </form>
        </div>
    );
}

export default LoginPage;