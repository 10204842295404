const protocol = window.location.protocol;
const clientHost = window.location.hostname;
const isLocalhost = clientHost === "localhost" || clientHost === "127.0.0.1";
const clientPort = isLocalhost ? ":" + window.location.port : "";
const serverPort = isLocalhost ? ":" + 3001 : "";
const serverHost = isLocalhost ? "localhost" : clientHost;

let cBaseUrl = `${protocol}//${clientHost}${clientPort}`;
let sBaseUrl = `${protocol}//${serverHost}${serverPort}`;

export {
    protocol, clientHost, 
    clientPort, serverHost, 
    serverPort, cBaseUrl, sBaseUrl
};