import { useState } from "react";
import getUrlVar from "../../app/getUrlVar";
import { cBaseUrl, sBaseUrl } from "../../app/url";
import { Link, useNavigate } from "react-router-dom";
import storeToken from "../../app/storeToken";

function TwoFactor() {
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const login = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/two-factor-auth", {
                method:"POST",
                headers:{"content-type":"application/json"},
                credentials:"include",
                body:JSON.stringify({
                    key:getUrlVar("key"),
                    userID:getUrlVar("userID")
                })
            });

            const json = await response.json();

            if(response.ok) {
                const tokenInfo = storeToken(json.token);
                
                navigate(`/${tokenInfo.path}/profil`);
                window.location.reload();
            } else {
                setError(json);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useState(()=> {
        login();
    }, []);

    return(
        <div className="container-md text-center">
            <h3 className="color-error">{error}</h3>
            {
                error && 
                <Link to={"/bejelentkezes"}>Vissza a bejelentkezéshez!</Link>
            }
        </div>
    );
}

export default TwoFactor;