import "./styles/style.scss";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import HomePage from './pages/public/HomePage';
import LoginPage from './pages/public/LoginPage';
import RegisterPage from './pages/public/RegisterPage';
import { library } from "@fortawesome/fontawesome-svg-core";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faAngleUp, faArrowUpRightFromSquare, faBars, faBoxArchive, faCheck, faChevronDown, faChevronUp, faCircleChevronLeft, faCircleChevronRight, faCircleInfo, 
    faCircleLeft, 
    faCircleMinus, 
    faCirclePlus, 
    faCircleRight, 
    faCircleXmark, faEye, faMagnifyingGlass, faPowerOff, faSquareCheck, faTrash, 
    faHome, faUser, faBox, faShoppingCart, faBuilding, faUserTie, faEnvelope, faTools, faFile,
    faBed, faStar, faHotel,
    faRightFromBracket,
    faConciergeBell,
    faToolbox,
    faUserPlus,
    faRightToBracket
} 
from "@fortawesome/free-solid-svg-icons";
import ActivateRegPage from "./pages/public/ActivateRegPage";
import PropertyOwnerProfile from "./pages/property_owner/PropertyOwnerProfilePage";
import PropertyPage from "./pages/property_owner/PropertyPage";
import TwoFactor from "./pages/public/TwoFactor";
import Properties from "./pages/property_owner/PropertiesPage";
import FirmProfilePage from "./pages/firms/FirmProfilePage";
import AccommodationPage from "./pages/firms/AccomodationPage";
import ServicePackagePage from "./pages/admin/ServicePackagePage";
import ServicePackagesPage from "./pages/admin/ServicePackagesPage";
import ProfessionalPage from "./pages/firms/ProfessionalPage";
import ProfessionalServicesPage from "./pages/firms/ProfessionalServicesPage";
import ServiceOrdersPage from "./pages/common/ServiceOrdersPage";
import OrdersByPeoplePage from "./pages/admin/OrdersByPeoplePage";
import OrdersByFirmsPage from "./pages/admin/OrdersByFirmsPage";
import ProfessionalServiceOrdersPage from "./pages/firms/ProfessionalServiceOrdersPage";
import HostServiceOrdersPage from "./pages/firms/HostServiceOrdersPage";
import RealEstatesPage from "./pages/public/RealEstatesPage";
import RealEstatePage from "./pages/public/RealEstatePage";
import AccommodationPagePublic from "./pages/public/AccommodationPage";
import AccommodationsPagePublic from "./pages/public/AccommodationsPagePublic";
import Accommodations from "./pages/firms/AccomodationsPage";
import PayPalPayPage from "./pages/common/PayPalPayPage";
import ProfessionalsPagePublic from "./pages/public/ProfessionalsPagePublic";
import ProfessionalPagePublic from "./pages/public/ProfessionalPagePublic";
import FirmUsersPage from "./pages/admin/FirmUsersPage";
import IndividualUsersPage from "./pages/admin/IndividualUsersPage";
import AdminProfile from "./pages/admin/AdminProfile";
import EditEmail from "./pages/admin/EditEmail";
import EmailsPage from "./pages/admin/EmailsPage";
import PageContentsPage from "./pages/admin/PageContentsPage";
import PageContentPage from "./pages/admin/PageContentPage";
import PageContentComponent from "./components/PageContentComponent";
import { sBaseUrl } from "./app/url";
import { useEffect, useState } from "react";
import ProfessionsPage from "./pages/admin/ProfessionsPage";
import ServicePackageInformation from "./pages/common/ServicePackageInformation";

library.add(
    faCircleXmark, faSquareCheck,
    faArrowUpRightFromSquare, faBoxArchive,
    faTrash, faPowerOff, faCircleInfo,
    faCheck, faSquareCheck, faMagnifyingGlass,
    faChevronUp, faChevronDown,
    faCircleRight, faCircleLeft,
    faCircleChevronRight, faCircleChevronLeft,
    faAngleRight, faAngleLeft,
    faAnglesRight, faAnglesLeft,
    faCirclePlus, faCircleMinus,
    faBars, faAngleUp, faEye,
    faHome, faUser, faBox, faShoppingCart, 
    faBuilding, faUserTie, faEnvelope, 
    faTools, faFile, faBed, faStar,
    faHotel, faRightFromBracket, faConciergeBell,
    faToolbox, faUserPlus, faRightToBracket
);

function App() {
    const [pages, setPages] = useState([]);

    const getPages = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/published-pages");
            const json = await response.json();

            if(response.ok) {
                setPages(json);
            } else {
                console.log(json);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(()=> {
        getPages();
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Nav/>}>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/ingatlanok' element={<RealEstatesPage/>}/>
                    <Route path='/ingatlan/:realEstateID' element={<RealEstatePage/>}/>
                    <Route path='/szallasok' element={<AccommodationsPagePublic/>}/>
                    <Route path='/szakemberek' element={<ProfessionalsPagePublic/>}/>
                    <Route path='/szallas/:accommodationID' element={<AccommodationPagePublic/>}/>
                    <Route path='/szolgaltatas/:serviceID' element={<ProfessionalPagePublic/>}/>
                    <Route path='/bejelentkezes' element={<LoginPage/>}/>
                    <Route path='/regisztracio' element={<RegisterPage/>}/>
                    <Route path='/activate-registration' element={<ActivateRegPage/>}/>
                    <Route path='/szolgaltatas-csomagok' element={<ServicePackageInformation/>}/>

                    <Route path="/two-factor-auth" element={<TwoFactor/>}/>
                    {
                        pages.map((p, i)=> 
                            <Route key={i} path={`/${p.pageUrl}`} element={<PageContentComponent/>}/>
                        )
                    }

                    {/* Property Owner Profile */}
                    <Route path="/ingatlan_tulajdonos/profil" element={<PropertyOwnerProfile/>}/>
                    <Route path="/ingatlan_tulajdonos/ingatlan_felvitel" element={<PropertyPage isUpdate={false}/>}/>
                    <Route path="/ingatlan_tulajdonos/ingatlanok" element={<Properties/>}/>
                    <Route path="/ingatlan_tulajdonos/ingatlan/:realEstateID" element={<PropertyPage isUpdate={true}/>}/>
                    <Route path="/ingatlan_tulajdonos/kiemelesek" element={<ServiceOrdersPage/>}/>
                    <Route path="/ingatlan_tulajdonos/paypal/:packageID/:orderID" element={<PayPalPayPage/>}/>

                    {/* Accomodation */}
                    <Route path="/szallasado/profil" element={<FirmProfilePage/>}/>
                    <Route path="/szallasado/szallas_letrehozasa" element={<AccommodationPage/>}/>
                    <Route path="/szallasado/szallas_letrehozasa/:accommodationID" element={<AccommodationPage/>}/>
                    <Route path="/szallasado/szallasaim" element={<Accommodations/>}/>
                    <Route path="/szallasado/kiemelesek" element={<HostServiceOrdersPage/>}/>
                    <Route path="/szallasado/paypal/:packageID/:orderID" element={<PayPalPayPage/>}/>

                    {/* Admin */}
                    <Route path="/admin/csomag-letrehozasa" element={<ServicePackagePage/>}/>
                    <Route path="/admin/csomag-letrehozasa/:packageID" element={<ServicePackagePage/>}/>
                    <Route path="/admin/csomagok" element={<ServicePackagesPage/>}/>
                    <Route path="/admin/maganszemely-megrendelesek" element={<OrdersByPeoplePage/>}/>
                    <Route path="/admin/ceges-megrendelesek" element={<OrdersByFirmsPage/>}/>
                    <Route path="/admin/ceges-felhasznalok" element={<FirmUsersPage/>}/>
                    <Route path="/admin/maganszemely-felhasznalok" element={<IndividualUsersPage/>}/>
                    <Route path="/admin/profil" element={<AdminProfile/>}/>
                    <Route path="/admin/emailek" element={<EmailsPage/>}/>
                    <Route path="/admin/email-szerkesztese/:emailID" element={<EditEmail/>}/>
                    <Route path="/admin/tartalom" element={<PageContentsPage/>}/>
                    <Route path="/admin/oldal-szerkesztese" element={<PageContentPage/>}/>
                    <Route path="/admin/oldal-szerkesztese/:pageID" element={<PageContentPage/>}/>
                    <Route path="/admin/szakmak" element={<ProfessionsPage/>}/>

                    {/* Szakember */}
                    <Route path="/szakember/profil" element={<FirmProfilePage/>}/>
                    <Route path="/szakember/szolgaltatas_letrehozasa" element={<ProfessionalPage/>}/>
                    <Route path="/szakember/szolgaltatas_letrehozasa/:serviceID" element={<ProfessionalPage/>}/>
                    <Route path="/szakember/szolgaltatasaim" element={<ProfessionalServicesPage/>}/>
                    <Route path="/szakember/kiemelesek" element={<ProfessionalServiceOrdersPage/>}/>
                    <Route path="/szakember/paypal/:packageID/:orderID" element={<PayPalPayPage/>}/>

                    {/**/}
                    <Route path="/paypal/:packageID/:orderID" element={<PayPalPayPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
