import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { sBaseUrl } from '../../app/url';
import formatHungarianCurrency from "../../app/formatHungarianCurrency";
import checkImg from "../../app/checkImg";

function RealEstate({p, highlightCls}) {
    return (
        <div className='p-md col-lg-3 col-md-4'>
            <div className={"p-md box-secondary " + highlightCls}>
                <h4 className="color-white">Hirdetés típusa</h4>
                <b className="color-primary">{p.adType}</b>

                <div className="img-holder mt-md">
                    <Link to={"/ingatlan/" + p.realEstateID}>
                        <img src={checkImg(p.mainImg)} />
                    </Link>
                </div>

                <h4 className="color-white">Irányítószám</h4>
                <b className="color-primary">{p.postalCode}</b>

                <h4 className="color-white">Város</h4>
                <b className="color-primary">{p.settlement}</b>

                <h4 className="color-white">Ár</h4>
                <b className="color-primary">{formatHungarianCurrency(p.price*1000)}</b>

                <div className="p-md">
                    <Link to={"/ingatlan/" + p.realEstateID}>
                        <FontAwesomeIcon className="color-success cursor-pointer font-20"
                            icon="fa-solid fa-arrow-up-right-from-square" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default RealEstate;