import { useContext, useEffect, useState } from "react";
import getType from "../app/getType";
import getAddressTomTom from "../app/getAddressTomTom";
import { MbContext } from "./Nav";
import getData from "../app/getData";
import Autocomplete from "./Autocomplete";

function Address({ formData, setFormData }) {
    const [countries, setCountries] = useState([]);
    const [publicPlaceTypes, setPublicPlaceTypes] = useState([]);
    const mb = useContext(MbContext);

    const handleAddressClick = (address) => {
        const fd = { ...formData };

        if (address.streetName === undefined) {
            mb.setDisplayMb(true);
            mb.setMessages("A cím nem eléggé specifikus!");
            return;
        }

        const streetArr = address?.streetName?.split(" ");
        const publicPlaceType = streetArr?.pop();
        const streetName = streetArr?.join(" ");
        fd.settlement = address?.municipality;
        fd.countyRegion = address?.countrySubdivisionName;
        fd.postalCode = address?.postalCode;
        fd.street = streetName;
        fd.houseNumber = address?.streetNumber;
        const pptIndex = publicPlaceTypes.findIndex((ppt) => ppt.typeName === publicPlaceType);
        const cIndex = countries.findIndex(c => c.countryName === address.country);

        if (pptIndex !== -1)
            fd.publicPlaceType = publicPlaceTypes[pptIndex].typeID;
        if (cIndex !== -1)
            fd.countryID = countries[cIndex].countryID;

        setFormData(fd);
    };

    useEffect(()=> {
        getType("types_public_place", setPublicPlaceTypes);
        getData("countries", setCountries);
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-sm-12 p-md">
                    <h4>Cím (kezdj el gépelni!)</h4>
                    <Autocomplete
                        fetchData={getAddressTomTom}
                        fields={["freeformAddress"]}
                        widthCls={"wp-100"}
                        cb={handleAddressClick}
                    />
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Ország</h4>
                    <select
                        value={formData.countryID ? formData.countryID : 0}
                        onChange={(e) => setFormData({ ...formData, countryID: e.target.value })}
                        name="countryID"
                        className="input-md input-primary wp-100"
                    >
                        <option value={0}>Válassz országot!</option>
                        {countries.map((country, index) => (
                            <option key={index} value={country.countryID}>
                                {country.countryName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Megye/Régió</h4>
                    <input
                        value={formData.countyRegion ? formData.countyRegion : ""}
                        onChange={(e) => setFormData({ ...formData, countyRegion: e.target.value })}
                        type="text"
                        className='input-md input-secondary wp-100'
                        name='countyRegion'
                    />
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Irányítószám</h4>
                    <input
                        value={formData.postalCode ? formData.postalCode : ""}
                        onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                        type="text"
                        className='input-md input-secondary wp-100'
                        name='postalCode'
                    />
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Település</h4>
                    <input
                        value={formData.settlement ? formData.settlement : ""}
                        onChange={(e) => setFormData({ ...formData, settlement: e.target.value })}
                        type="text"
                        className='input-md input-secondary wp-100'
                        name='settlement'
                    />
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Közterület neve</h4>
                    <div className="row">
                        <div className="col-xs-8">
                            <input
                                value={formData.street ? formData.street : ""}
                                onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                                type="text"
                                className='input-md input-secondary wp-100'
                                name='street'
                            />
                        </div>

                        <div className="col-xs-4">
                            <select className='input-md input-secondary wp-100' 
                            value={formData.publicPlaceType ? formData.publicPlaceType : 0}
                                onChange={(e) => setFormData({ ...formData, publicPlaceType: e.target.value })}>
                                <option value={0}>Válassz!</option>
                                {
                                    publicPlaceTypes.map((ppt, i) =>
                                        <option key={i} value={ppt.typeID}>{ppt.typeName}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 p-md">
                    <h4>Házszám</h4>
                    <input
                        value={formData.houseNumber ? formData.houseNumber : 0}
                        onChange={(e) => setFormData({ ...formData, houseNumber: e.target.value })}
                        type="text"
                        className='input-md input-secondary wp-100'
                        name='houseNumber'
                    />
                </div>
            </div>
        </>
    );
}

export default Address;