import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';

const IndividualUser = ({ user, setUsers }) => {
    const mb = useContext(MbContext);

    const deleteUserConfirm = (userID) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő felhasználót: `,
            `${userID}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteUser(userID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteUser = async (userID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/users/${userID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setUsers(prevUsers => prevUsers.filter(user => user.userID !== userID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("IndividualUser.deleteUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    if (!user) {
        return <div>Profil betöltése...</div>;
    }

    const { 
        userID, 
        firstName, 
        lastName, 
        countryName, 
        postalCode, 
        countyRegion, 
        settlement, 
        street, 
        streetType, 
        houseNumber, 
        mobile, 
        tel, 
        contactEmail 
    } = user;

    return (
        <div className="p-md col-lg-3 col-md-2">
            <div className="p-md box-secondary">
                <h4 className="color-white">Felhasználói azonosító</h4>
                <b className="color-primary-lighter">{userID}</b>

                <h4 className="color-white">Teljes név</h4>
                <b className="color-primary-lighter">{firstName} {lastName}</b>

                <h4 className="color-white">Ország</h4>
                <b className="color-primary-lighter">{countryName}</b>

                <h4 className="color-white">Irányítószám</h4>
                <b className="color-primary-lighter">{postalCode}</b>

                <h4 className="color-white">Megye/Régió</h4>
                <b className="color-primary-lighter">{countyRegion}</b>

                <h4 className="color-white">Település</h4>
                <b className="color-primary-lighter">{settlement}</b>

                <h4 className="color-white">Utca/házszám</h4>
                <b className="color-primary-lighter">{street} {streetType} {houseNumber}</b>

                <h4 className="color-white">Mobil</h4>
                <b className="color-primary-lighter">{mobile}</b>

                <h4 className="color-white">Kapcsolattartó Email</h4>
                <b className="color-primary-lighter">{contactEmail}</b>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <FontAwesomeIcon 
                            onClick={() => deleteUserConfirm(userID)}
                            className="color-error cursor-pointer font-20"
                            icon="fa-solid fa-trash" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualUser;