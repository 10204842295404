import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { sBaseUrl } from '../../app/url';
import { useLocation, useNavigate } from 'react-router-dom';
import getType from '../../app/getType';
import RealEstate from './RealEstate';
import queryStringToObj from '../../app/queryStringToObj';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getUrlVar from '../../app/getUrlVar';
import CheckboxList from '../../components/CheckboxList';

function RealEstatesPage() {
    const [properties, setProperties] = useState([]);
    const mb = useContext(MbContext);
    const formRef = useRef();
    const navigate = useNavigate();
    const [adTypes, setAdTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [buildTechnologies, setBuildTechnologies] = useState([]);
    const [heatTypes, setHeatTypes] = useState([]);
    const [parkingTypes, setParkingTypes] = useState([]);
    const [viewTypes, setViewTypes] = useState([]);
    const [furnishedTypes, setFurnishedTypes] = useState([]);
    const [electricityTypes, setElectricityTypes] = useState([]);
    const [gasTypes, setGasTypes] = useState([]);
    const [houseTypes, setHouseTypes] = useState([]);
    const [publicPlaceTypes, setPublicPlaceTypes] = useState([]);
    const location = useLocation();
    const [queryStringData, setQueryStringData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(getUrlVar("page", 1));
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

    // State for CheckboxLists
    const [selectedHeatTypes, setSelectedHeatTypes] = useState([]);
    const [selectedParkingTypes, setSelectedParkingTypes] = useState([]);
    const [selectedViewTypes, setSelectedViewTypes] = useState([]);
    const [selectedFurnishedTypes, setSelectedFurnishedTypes] = useState([]);
    const [selectedElectricityTypes, setSelectedElectricityTypes] = useState([]);
    const [selectedGasTypes, setSelectedGasTypes] = useState([]);
    const [selectedHouseTypes, setSelectedHouseTypes] = useState([]);
    const [selectedBuildTechnologies, setSelectedBuildTechnologies] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);

    /*
        - isAttic (tetőréri)
        - isGardenContact (kertkapcsolatos)
        - isSmartHome (okosotthon)
        - moveImmediately (azonnal költözhető)
        - hasElevator (Van-e lift)
        - thermallyInsulated (Hőszigetelt-e)
        - hasSolarPanel (van-e napelem)
        - floorArea (lakás terület, tól-ig érték olyan, mint mondjuk a szobák száma, onnan másolhatsz
        - siteArea (telek terület, ez is tól-ig érték hasonló, mint a floorArea
    */

    const search = async (e = null) => {
        e.preventDefault();
        const qs = e !== null ? new URLSearchParams(queryStringData).toString() : location.search.substring(1);
        searchRealEstate(qs);
    };

    const searchRealEstate = async (qs) => {
        try {
            navigate("/ingatlanok?" + qs);

            const response = await fetch(sBaseUrl + "/api/search-real-estate?" + qs, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();
            setProperties(json.data);
            setTotalPages(json.totalPages);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const next = async () => {
        if (page < totalPages)
            setPage(p => ++p);
    };

    const prev = async () => {
        if (page > 1)
            setPage(p => --p);
    };

    useEffect(() => {
        getType("types_ad", setAdTypes);
        getType("countries", setCountries);
        getType("types_condition", setConditions);
        getType("types_build_technology", setBuildTechnologies);
        getType("types_heating", setHeatTypes);
        getType("types_parking", setParkingTypes);
        getType("types_view", setViewTypes);
        getType("types_furnished", setFurnishedTypes);
        getType("types_electricity", setElectricityTypes);
        getType("types_gas", setGasTypes);
        getType("types_house", setHouseTypes);
        getType("types_public_place", setPublicPlaceTypes);

        searchRealEstate(location.search.substring(1));
        setQueryStringData(queryStringToObj(location.search));
    }, []);

    useEffect(() => {
        const searchParamsObj = queryStringToObj(location.search);
        searchParamsObj.page = page;
        const sp = new URLSearchParams(searchParamsObj);
        searchRealEstate(sp);
    }, [page]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setQueryStringData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    useEffect(() => {
        setQueryStringData(({
            ...queryStringData,
            heatType: selectedHeatTypes,
            parkingType: selectedParkingTypes,
            viewType: selectedViewTypes,
            furnishedType: selectedFurnishedTypes,
            electricityType: selectedElectricityTypes,
            gasType: selectedGasTypes,
            houseType: selectedHouseTypes,
            buildTechnology: selectedBuildTechnologies,
            condition: selectedConditions
        }));
    }, [
        selectedHeatTypes, selectedParkingTypes, selectedViewTypes,
        selectedFurnishedTypes, selectedElectricityTypes, selectedGasTypes,
        selectedHouseTypes, selectedBuildTechnologies, selectedConditions
    ]);

    useEffect(() => {
        setSelectedHeatTypes(
            getUrlVar("heatType", null) ?
                getUrlVar("heatType")?.split(",").map(ht => parseInt(ht))
                : []
        );

        setSelectedParkingTypes(
            getUrlVar("parkingType", null) ?
                getUrlVar("parkingType")?.split(",").map(pt => parseInt(pt))
                : []
        );

        setSelectedViewTypes(
            getUrlVar("viewType", null) ?
                getUrlVar("viewType")?.split(",").map(vt => parseInt(vt))
                : []
        );

        setSelectedFurnishedTypes(
            getUrlVar("furnishedType", null) ?
                getUrlVar("furnishedType")?.split(",").map(ft => parseInt(ft))
                : []
        );

        setSelectedElectricityTypes(
            getUrlVar("electricityType", null) ?
                getUrlVar("electricityType")?.split(",").map(et => parseInt(et))
                : []
        );

        setSelectedHouseTypes(
            getUrlVar("houseType", null) ?
                getUrlVar("houseType")?.split(",").map(ht => parseInt(ht))
                : []
        );

        setSelectedBuildTechnologies(
            getUrlVar("buildTechnology", null) ?
                getUrlVar("buildTechnology")?.split(",").map(bt => parseInt(bt))
                : []
        );

        setSelectedConditions(
            getUrlVar("condition", null) ?
                getUrlVar("condition")?.split(",").map(ct => parseInt(ct))
                : []
        );
    }, []);

    const addHighlight = (packageType) => {
        if (packageType === 2) {
            return "border-primary-2 shadow";
        } else if (packageType === 4) {
            return "border-success-2 shadow";
        }

        return "";
    };

    return (
        <div className="container-xl">
            <div className="p-xl text-center">
                <h1>Ingatlanok</h1>
                <form ref={formRef} className="p-md">
                    <div className="box-primary p-md row">
                        <div className="col-lg-1 col-md-2 p-md d-flex jc-center f-dir-column">
                            {adTypes.map((t, i) => (
                                <label key={i} className="d-block color-white">
                                    <input
                                        type="radio"
                                        className="mr-xs"
                                        onChange={handleInputChange}
                                        checked={queryStringData?.adType == t.typeID}
                                        name="adType"
                                        value={t.typeID}
                                    />
                                    {t.typeName}
                                </label>
                            ))}
                        </div>

                        <div className="col-lg-2 col-md-5 p-md">
                            <h4 className="color-white">Ország</h4>
                            <select
                                name="countryID"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.countryID || ''}
                            >
                                <option value="">Válassz országot</option>
                                {countries.map((t, i) => (
                                    <option key={i} value={t.countryID}>
                                        {t.countryName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-3 col-md-5 p-md">
                            <h4 className="color-white">Település</h4>
                            <input
                                type="text"
                                placeholder="település"
                                name="settlement"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.settlement || ''}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 p-md">
                            <h4 className="color-white">Ár (ezer forint)</h4>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    placeholder="min"
                                    style={{ borderRight: 'none' }}
                                    name="priceFrom"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.priceFrom || ''}
                                />
                                <input
                                    type="number"
                                    placeholder="max"
                                    name="priceTo"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.priceTo || ''}
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 p-md">
                            <h4 className="color-white">Szobák</h4>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    placeholder="min"
                                    style={{ borderRight: 'none' }}
                                    name="roomFrom"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.roomFrom || ''}
                                />
                                <input
                                    type="number"
                                    placeholder="max"
                                    name="roomTo"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.roomTo || ''}
                                />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-4 p-md">
                            <CheckboxList
                                optionValues={conditions}
                                selectedValues={selectedConditions}
                                setSelectedValues={setSelectedConditions}
                                idFieldName="typeID"
                                textFielsName="typeName"
                                text="Kondíció"
                            />
                        </div>

                        <div className="col-lg-3 p-md">
                            <h4 className="color-white">Alapterület</h4>
                            <div className="d-flex">
                                <input
                                    type="number"
                                    placeholder="min"
                                    style={{ borderRight: 'none' }}
                                    name="floorAreaFrom"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.floorAreaFrom || ''}
                                />
                                <input
                                    type="number"
                                    placeholder="max"
                                    name="floorAreaTo"
                                    className="input-md input-secondary wp-100"
                                    onChange={handleInputChange}
                                    value={queryStringData.floorAreaTo || ''}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 p-md">
                            <CheckboxList
                                optionValues={houseTypes}
                                selectedValues={selectedHouseTypes}
                                setSelectedValues={setSelectedHouseTypes}
                                idFieldName="typeID"
                                textFielsName="typeName"
                                text="Ingatlan típusa"
                            />
                        </div>


                        <div className="col-md-3 p-md">
                            <CheckboxList
                                optionValues={buildTechnologies}
                                selectedValues={selectedBuildTechnologies}
                                setSelectedValues={setSelectedBuildTechnologies}
                                idFieldName="typeID"
                                textFielsName="typeName"
                                text="Építési technológia"
                            />
                        </div>


                        <div className="col-md-3 p-md">
                            <CheckboxList
                                optionValues={furnishedTypes}
                                selectedValues={selectedFurnishedTypes}
                                setSelectedValues={setSelectedFurnishedTypes}
                                idFieldName="typeID"
                                textFielsName="typeName"
                                text="Bútorozottság"
                            />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="button" onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                                className={"input-md cursor-pointer center-input " + (showAdvancedSearch ? "btn-disabled" : "btn-secondary")}>
                                További keresési feltételek
                            </button>
                        </div>

                        {showAdvancedSearch && (
                            <>

                                <div className="col-lg-3 p-md">
                                    <h4 className="color-white">Telek terület</h4>
                                    <div className="d-flex">
                                        <input
                                            type="number"
                                            placeholder="min"
                                            style={{ borderRight: 'none' }}
                                            name="siteAreaFrom"
                                            className="input-md input-secondary wp-100"
                                            onChange={handleInputChange}
                                            value={queryStringData.siteAreaFrom || ''}
                                        />
                                        <input
                                            type="number"
                                            placeholder="max"
                                            name="siteAreaTo"
                                            className="input-md input-secondary wp-100"
                                            onChange={handleInputChange}
                                            value={queryStringData.siteAreaTo || ''}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3 p-md">
                                    <CheckboxList
                                        optionValues={heatTypes}
                                        selectedValues={selectedHeatTypes}
                                        setSelectedValues={setSelectedHeatTypes}
                                        idFieldName="typeID"
                                        textFielsName="typeName"
                                        text="Fűtés típusa"
                                    />
                                </div>

                                <div className="col-md-3 p-md">
                                    <CheckboxList
                                        optionValues={parkingTypes}
                                        selectedValues={selectedParkingTypes}
                                        setSelectedValues={setSelectedParkingTypes}
                                        idFieldName="typeID"
                                        textFielsName="typeName"
                                        text="Parkolás típusa"
                                    />
                                </div>

                                <div className="col-md-3 p-md">
                                    <CheckboxList
                                        optionValues={viewTypes}
                                        selectedValues={selectedViewTypes}
                                        setSelectedValues={setSelectedViewTypes}
                                        idFieldName="typeID"
                                        textFielsName="typeName"
                                        text="Kilátás típusa"
                                    />
                                </div>

                                <div className="col-md-3 p-md">
                                    <CheckboxList
                                        optionValues={electricityTypes}
                                        selectedValues={selectedElectricityTypes}
                                        setSelectedValues={setSelectedElectricityTypes}
                                        idFieldName="typeID"
                                        textFielsName="typeName"
                                        text="Villany típusa"
                                    />
                                </div>

                                <div className="col-md-3 p-md">
                                    <CheckboxList
                                        optionValues={gasTypes}
                                        selectedValues={selectedGasTypes}
                                        setSelectedValues={setSelectedGasTypes}
                                        idFieldName="typeID"
                                        textFielsName="typeName"
                                        text="Gáz típusa"
                                    />
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Tetőtéri</h4>

                                    <select name="isAttic" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Igen">Igen</option>
                                        <option className="Nem">Nem</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Kertkapcsolatos</h4>

                                    <select name="isGardenContact" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Igen">Igen</option>
                                        <option className="Nem">Nem</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Okos otthon</h4>

                                    <select name="isSmartHome" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Igen">Igen</option>
                                        <option className="Nem">Nem</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Azonnal költözhető</h4>

                                    <select name="moveImmediately" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Igen">Igen</option>
                                        <option className="Nem">Nem</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Lift</h4>

                                    <select name="hasElevator" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Van">Van</option>
                                        <option className="Nincs">Nincs</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Hőszigetelt</h4>

                                    <select name="thermallyInsulated" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Igen">Igen</option>
                                        <option className="Nem">Nem</option>
                                    </select>
                                </div>

                                <div className="col-md-3 p-md">
                                    <h4 class="color-white">Napelem</h4>

                                    <select name="hasSolarPanel" className="input-md primary-input wp-80">
                                        <option className="">Mindegy</option>
                                        <option className="Van">Van</option>
                                        <option className="Nincs">Nincs</option>
                                    </select>
                                </div>
                            </>
                        )}

                        <input type="hidden" name="page" value={page} />
                    </div>

                    <button onClick={(e) => search(e)} className="input-md btn-primary cursor-pointer">Keresés</button>
                </form>

                <div>{page}/{totalPages} oldal</div>

                <div className="row">
                    {properties.map((p, i) =>
                        <RealEstate
                            highlightCls={addHighlight(p.packageType)}
                            p={p} key={i}
                        />
                    )}
                </div>

                <div className="d-flex mw-300 jc-space-evenly ai-center margin-auto mt-lg mb-lg noselect">
                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={() => setPage(1)}
                            icon="fa-solid fa-angles-left" />

                        <FontAwesomeIcon
                            onClick={prev}
                            icon="fa-solid fa-angle-left" />
                    </div>

                    <div>{page}/{totalPages} oldal</div>

                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={next}
                            icon="fa-solid fa-angle-right" />

                        <FontAwesomeIcon
                            onClick={() => setPage(totalPages)}
                            icon="fa-solid fa-angles-right" />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default RealEstatesPage;