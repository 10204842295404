function Validator() {
    this.rules = [];
    this.emailRegex = /^[\w\_\-\.\#\$\%\&\*\+\-]+\@[\w\_\-\.]+\.[\w]{2,}$/;
    this.phoneRegex = /^(?:\+36|06)?\s*(20|30|70)\s*\d{3}\s*\d{4}$/;

    this.isString = ()=> {
        this.rules.push({
            type: 'string',
        });
        return this;
    }

    this.isNumber = ()=> {
        this.rules.push({
            type: 'number',
        });
        return this;
    }

    this.isEmail = ()=> {
        this.rules.push({
            type: 'email',
        });
        return this;
    }

    this.isPhoneNumber = ()=> {
        this.rules.push({
            type: 'phone',
        });
        return this;
    }

    this.between = (min, max)=> {
        this.rules.push({
            type: 'between',
            min,
            max
        });
        return this;
    }

    this.min = (min)=> {
        this.rules.push({
            type: 'min',
            min
        });
        return this;
    }

    this.max = (max)=> {
        this.rules.push({
            type: 'max',
            max
        });
        return this;
    }

    this.hasMinLength = (minLength)=> {
        this.rules.push({
            type: 'minLength',
            minLength,
        });
        return this;
    }

    this.hasMaxLength = (maxLength)=> {
        this.rules.push({
            type: 'maxLength',
            maxLength,
        });
        return this;
    }

    this.regex = (regex)=> {
        this.rules.push({
            type: 'regex',
            regex,
        });
        return this;
    }

    this.isRequired = (required)=> {
        this.rules.push({
            type: 'required',
            required
        });
        return this;
    }

    this.exec = (target, errorCls)=> {
        let isValid = true;
        const data = target.value;

        for (const rule of this.rules) {
            if (rule.type === "required" && rule.required
                && (data === undefined
                    || data === null
                    || data.toString().length === 0)) {
                isValid = false;
                break;
            }

            if (data === undefined || data === null) {
                isValid = false;
                break;
            }

            if (rule.type === 'string' && typeof data !== 'string') {
                isValid = false;
                break;
            }

            if (rule.type === 'number' && isNaN(data)) {
                isValid = false;
                break;
            }

            if (rule.type === 'email' && !this.emailRegex.test(data)) {
                isValid = false;
                break;
            }

            if (rule.type === "phone" && !this.phoneRegex.test(data)) {
                isValid = false;
                break;
            }

            if (rule.type === "between" && (data < rule.min || data > rule.max)) {
                isValid = false;
                break;
            }

            if (rule.type === "min" && data < rule.min) {
                isValid = false;
                break;
            }

            if (rule.type === "max" && data > rule.max) {
                isValid = false;
                break;
            }

            if (rule.type === 'minLength' && data.length < rule.minLength) {
                isValid = false;
                break;
            }

            if (rule.type === 'maxLength' && data.length > rule.maxLength) {
                isValid = false;
                break;
            }

            if (rule.type === 'regex') {
                const customRegex = new RegExp(rule.regex);

                if (!customRegex.test(data)) {
                    isValid = false;
                    break;
                }
            }
        }

        if(!isValid)
            target.classList.add(errorCls);
        else 
            target.classList.remove(errorCls);
    }
}

export default Validator;