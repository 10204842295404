// import React, { useState, useEffect, useCallback } from 'react';

// const Autocomplete = ({ fetchData, fields, widthCls, cb, inputRef = null, limit = 5}) => {
//     const [query, setQuery] = useState('');
//     const [results, setResults] = useState([]);
//     const [isSearching, setIsSearching] = useState(false);

//     // Debounce funkció
//     const debounce = (func, delay) => {
//         let timer;
//         return (...args) => {
//             clearTimeout(timer);
//             timer = setTimeout(() => func(...args), delay);
//         };
//     };

//     // API hívás funkció
//     // const fetchResults = async (searchQuery) => {
//     //     if (!searchQuery) return;

//     //     setIsSearching(true);

//     //     try {
//     //         const data = await fetchData(searchQuery, limit);
//     //         setResults(data || []);
//     //     } catch (error) {
//     //         console.error('Error fetching data:', error);
//     //     }
        
//     //     setIsSearching(false);
//     // };

//     const fetchResults = async (searchQuery) => {
//         if (!searchQuery) return;
    
//         setIsSearching(true);
    
//         try {
//             const data = await fetchData(searchQuery, limit);
            
//             // Új kód: Egyedi értékek kiszűrése
//             const uniqueResults = [...new Set(data.map(item => 
//                 fields.map(field => item[field] || '-').join(' ')
//             ))].map(uniqueItem => 
//                 data.find(item => 
//                     fields.map(field => item[field] || '-').join(' ') === uniqueItem
//                 )
//             );
    
//             setResults(uniqueResults || []);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
        
//         setIsSearching(false);
//     };    

//     // Debounce-olt API hívás
//     const debouncedFetchResults = useCallback(debounce(fetchResults, 300), [fetchData]);

//     // Effect a keresési lekérdezés követésére
//     useEffect(() => {
//         debouncedFetchResults(query);
//     }, [query, debouncedFetchResults]);

//     // Kezelő az input mező változásaira
//     const handleInputChange = (event) => {
//         setQuery(event.target.value);
//     };

//     // Kezelő az elem kiválasztására
//     const handleSelect = (event) => {
//         const selectedValue = event.target.value;
//         const selectedItem = results.find(result => 
//             fields.map(field => result[field] || '-').join(' ') === selectedValue
//         );
        
//         if (selectedItem) {
//             cb(selectedItem);
//             setQuery(selectedValue);
//             setResults([]);
//         }
//     };

//     return (
//         <div>
//             <input
//                 list="autocomplete-list"
//                 type="text"
//                 className={"input-md input-primary " + widthCls}
//                 onChange={handleInputChange}
//                 ref={inputRef}
//                 placeholder="Kezd el begépelni, aztán válassz a listából!"
//                 aria-label="Search Input"
//             />
//             {isSearching && <p>Searching...</p>}
//             <select disabled={results.length === 0}
//             onChange={handleSelect} className="input-md wp-100 input-primary" id="autocomplete-list">
//                 <option>Válassz a listából!</option>
//                 {results.map((result, index) => 
//                     <option key={"ac" + index} value={fields.map(field => result[field] || '-').join(' ')}>
//                         {fields.map(field => result[field] || '-').join(' ')}
//                     </option>
//                 )}
//             </select>
//         </div>
//     );
// };

import React, { useState, useEffect, useCallback } from 'react';

const Autocomplete = ({ fetchData, fields, widthCls, cb, inputRef = null, limit = 5, id = 'autocomplete-list' }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // Debounce funkció
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    // API hívás funkció
    const fetchResults = async (searchQuery) => {
        if (!searchQuery) return;
    
        setIsSearching(true);
    
        try {
            const data = await fetchData(searchQuery, limit);
            
            // Új kód: Egyedi értékek kiszűrése
            const uniqueResults = [...new Set(data.map(item => 
                fields.map(field => item[field] || '-').join(' ')
            ))].map(uniqueItem => 
                data.find(item => 
                    fields.map(field => item[field] || '-').join(' ') === uniqueItem
                )
            );
    
            setResults(uniqueResults || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
        setIsSearching(false);
    };    

    // Debounce-olt API hívás
    const debouncedFetchResults = useCallback(debounce(fetchResults, 300), [fetchData]);

    // Effect a keresési lekérdezés követésére
    useEffect(() => {
        debouncedFetchResults(query);
    }, [query, debouncedFetchResults]);

    // Kezelő az input mező változásaira
    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    // Kezelő az elem kiválasztására
    const handleSelect = (event) => {
        const selectedValue = event.target.value;

        const selectedItem = results.find(result => 
            fields.map(field => result[field] || '-').join(' ') === selectedValue
        );

        console.log(selectedItem);
        
        if (selectedItem) {
            cb(selectedItem);
            setQuery(selectedValue);
            setResults([]);
        }
    };

    return (
        <div>
            <input
                list={id}
                type="text"
                className={"input-md input-primary " + widthCls}
                onChange={handleInputChange}
                onKeyUp={handleSelect}
                ref={inputRef}
                placeholder="Kezd el begépelni, aztán válassz a listából!"
                aria-label="Search Input"
            />

            {isSearching && <p>Searching...</p>}
            <datalist id={id}>
                {results.map((result, index) => 
                    <option key={"ac" + index} value={fields.map(field => result[field] || '-').join(' ').trim()} >
                        {fields.map(field => result[field] || '-').join(' ').trim()}
                    </option>
                )}
            </datalist>
        </div>
    );
};

export default Autocomplete;
