import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';
import { Link } from 'react-router-dom';

const Email = ({ email, setEmails }) => {
    const mb = useContext(MbContext);

    const deleteEmailConfirm = (subject) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő emailt: `,
            `${subject}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteEmail(emailID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteEmail = async (emailID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/emails/${emailID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setEmails(prevEmails => prevEmails.filter(email => email.emailID !== emailID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Email.deleteEmail: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    if (!email) {
        return <div>Email betöltése...</div>;
    }

    const { emailID, subject, emailTypeName, updated } = email;

    return (
        <div className="p-md col-lg-3">
            <div className="p-md box-secondary">
                <h4 className="color-white">Email Tárgy</h4>
                <b className="color-primary-lighter">{subject || "-"}</b>

                <h4 className="color-white">Email Típus</h4>
                <b className="color-primary-lighter">{emailTypeName || "-"}</b>

                <h4 className="color-white">Utoljára Frissítve</h4>
                <b className="color-primary-lighter">{updated ? new Date(updated).toLocaleString() : "-"}</b>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <FontAwesomeIcon 
                            onClick={() => deleteEmailConfirm(subject)}
                            className="color-error cursor-pointer font-20"
                            icon="fa-solid fa-trash" />
                    </div>
                    <div>
                        <Link to={`/admin/email-szerkesztese/${emailID}`}>
                            <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                icon="fa-solid fa-arrow-up-right-from-square" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Email;