import {useContext, useEffect, useState} from "react";
import getUrlVar from "../../app/getUrlVar";
import UserHandler from "../../app/UserHandler";
import { MbContext } from "../../components/Nav";
import MessageBox from "../../components/MessageBox";

function ActivateRegPage() {
    const [activationString, setActivationString] = useState(null);
    const mb = useContext(MbContext);

    useEffect(()=> {
        setActivationString(getUrlVar("as"));
    }, []);

    useEffect(()=> {
        if(activationString === null)
            return;
        const uh = new UserHandler();
        
        (async ()=> {
            const response = await uh.activateReg(activationString);
            console.log(response);

            mb.setDisplayMb(true);
            mb.setMessages(response.json);
        })();
    }, [activationString]);

    return(
        <>
            <div className="container-sm text-center">
                <h1>A regisztráció aktiválása</h1>
            </div>
        </>
    );
}

export default ActivateRegPage;