import { Link, useLocation } from "react-router-dom";
import selectMenu from "../app/selectMenu";
import Logout from "./Logout";

function SideMenu({ menuData }) {
    const location = useLocation();

    return(
        <div className="side-menu">
        <ul>
            {
                menuData.map((m, i)=>
                    <li key={i} className={selectMenu(location.pathname, m.url)}>
                        <Link to={m.url}>{m.name}</Link>
                    </li>
                )
            }

            {
                <Logout />
            }
        </ul>
    </div>
    );
}

export default SideMenu;