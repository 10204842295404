import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Pagination({page, setPage, totalPages}) { 
    const next = () => {
        if (page < totalPages) setPage(p => ++p);
    };

    const prev = () => {
        if (page > 1) setPage(p => --p);
    };

    return(
        <div className="d-flex mw-300 jc-space-evenly ai-center margin-auto mt-lg mb-lg noselect">
            <div className="font-24 cursor-pointer color-primary">
                <FontAwesomeIcon className="mr-xl"
                    onClick={() => setPage(1)}
                    icon="fa-solid fa-angles-left" />

                <FontAwesomeIcon
                    onClick={prev}
                    icon="fa-solid fa-angle-left" />
            </div>

            <div>{page}/{totalPages} oldal</div>

            <div className="font-24 cursor-pointer color-primary">
                <FontAwesomeIcon className="mr-xl"
                    onClick={next}
                    icon="fa-solid fa-angle-right" />

                <FontAwesomeIcon
                    onClick={() => setPage(totalPages)}
                    icon="fa-solid fa-angles-right" />
            </div>
        </div>
    );
}

export default Pagination;