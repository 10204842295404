import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { MbContext } from '../../components/Nav';
import SideMenuProfessional from '../../components/SideMenuProfessional';

function ProfessionalServiceOrdersPage() {
    const [serviceOrders, setServiceOrders] = useState([]);
    const mb = useContext(MbContext);

    const deleteServiceOrderConfirm = (orderData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő rendelést: `,
            `Rendelés ID: ${orderData.orderID}`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteServiceOrder(orderData.orderID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteServiceOrder = async (orderID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/service-orders/${orderID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setServiceOrders(serviceOrders.filter(order => order.orderID !== orderID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("ServiceOrdersPage.deleteServiceOrder: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        const fetchServiceOrders = async () => {
            try {
                const response = await fetch(`${sBaseUrl}/api/service-orders-by-professional`, {
                    method: "GET",
                    headers: { "authorization": localStorage.getItem("token") },
                    credentials: "include"
                });

                const json = await response.json();
                
                if (response.ok) {
                    setServiceOrders(json);
                }

                if (json.token && json.token.token !== null) {
                    storeToken(json.token.token);
                }
            } catch (err) {
                console.log("ServiceOrdersPage.fetchServiceOrders: ", err);
                mb.setDisplayMb(true);
                mb.setMessages("Váratlan hiba történt!");
            }
        };

        fetchServiceOrders();
    }, []);

    return (
        <div className="admin-grid">
            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
                setButtons={mb.setButtons}
            />

            <SideMenuProfessional />

            <div className="p-xl text-center">
                <h1>Kiemelések</h1>

                <div className="row">
                    {serviceOrders.map((order, index) => (
                        <div key={index} className="p-md col-lg-4 col-md-6">
                            <div className="p-md box-secondary">
                                <h4 className="color-white">Csomag neve</h4>
                                <b className="color-primary">{order.packageName}</b>

                                <h4 className="color-white">Érvényesség ideje</h4>
                                <b className="color-primary">{order.intervalDays} nap</b>

                                <h4 className="color-white">Ár</h4>
                                <b className="color-primary">{order.price} Ft</b>

                                <h4 className="color-white">Hirdetés címe</h4>
                                <b className="color-primary">{order.title}</b>

                                <div className="flex jc-space-around mt-md">
                                    <div>
                                        <Link to={`/admin/rendeles-modositasa/${order.orderID}`}>
                                            <FontAwesomeIcon
                                                className="color-success cursor-pointer font-20"
                                                icon="fa-solid fa-arrow-up-right-from-square"
                                            />
                                        </Link>
                                    </div>

                                    <div>
                                        {
                                            order.isActive == 1
                                            ? 
                                                <div className="color-white d-flex ai-center">
                                                    <FontAwesomeIcon className="font-20 color-success mr-xs"
                                                    icon="fa-solid fa-square-check" /> Aktivált
                                                </div>
                                            :
                                            <FontAwesomeIcon
                                                onClick={() => deleteServiceOrderConfirm(order)}
                                                className="color-error cursor-pointer font-20"
                                                icon="fa-solid fa-trash"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProfessionalServiceOrdersPage;