import React from 'react';

const ServicePackagePriceForm = ({ priceData, setPriceData, handlePriceSubmit }) => {
    return (
        <form  className="m-lg" onSubmit={handlePriceSubmit}>
            <div className="box-light-grey p-md mw-500 margin-auto">
                <h3>Csomag ára</h3>
                <input
                    type="number"
                    className="input-md input-secondary mw-200"
                    value={priceData.price}
                    onChange={(e) => setPriceData({ ...priceData, price: e.target.value })}
                    required
                />

                <h3>Érvényesség kezdete</h3>
                <input
                    type="date"
                    className="input-md input-secondary mw-200"
                    value={priceData.startDate}
                    onChange={(e) => setPriceData({ ...priceData, startDate: e.target.value })}
                    required
                />

                <button className="btn-sm btn-secondary center-input" type="submit">Felvitel</button>
            </div>
        </form>
    );
};

export default ServicePackagePriceForm;