import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { sBaseUrl } from "../app/url";
import { memo } from "react";
import ImageModal from "./ImageModal";

function Carousel({ images }) {
    const [index, setIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(0);
    const [toIndex, setToIndex] = useState(-1);
    const [direction, setDirection] = useState("");
    const [modalOpened, setModalOpened] = useState(false);
    const [disableBtns, setDisableBtns] = useState(false);
    const nextImage = useRef();
    const currentImg = useRef();

    const forward = (nextIndex = -1) => {
        setDisableBtns(true);
        currentImg.current.classList.add("prev-image-forward");
        nextImage.current.classList.add("current-image-forward");
        nextImage.current.classList.remove("left-100p");
        nextImage.current.classList.remove("right-100p");
        setDirection("forward");

        if (nextIndex !== -1)
            setNextIndex(nextIndex);

        setTimeout(() => {
            if (index < images.length - 1) {
                if (nextIndex === -1)
                    setIndex(i => ++i);
                else
                    setIndex(nextIndex);
            } else {
                setIndex(0);
            }

            nextImage.current.classList.add("left-100p");
            currentImg.current.classList.remove("prev-image-forward");
            nextImage.current.classList.remove("current-image-forward");
            setDisableBtns(false);
            setToIndex(-1);
        }, 1000);
    };

    const backward = (nextIndex = -1) => {
        nextImage.current.classList.remove("right-100p");
        nextImage.current.classList.remove("left-100p");
        currentImg.current.classList.add("prev-image-backward");
        nextImage.current.classList.add("current-image-backward");
        setDirection("backward");
        if (nextIndex !== -1)
            setNextIndex(nextIndex);

        setTimeout(() => {
            if (index > 0) {
                if (nextIndex === -1)
                    setIndex(i => --i);
                else
                    setIndex(nextIndex);
            } else {
                setIndex(images.length - 1);
            }

            nextImage.current.classList.add("right-100p");
            currentImg.current.classList.remove("prev-image-backward");
            nextImage.current.classList.remove("current-image-backward");
            setToIndex(-1);
        }, 1000);
    };

    useEffect(() => {
        if (direction === "forward") {
            if (index < images.length - 1) {
                setNextIndex(index + 1);
            } else {
                setNextIndex(0);
            }
        } else if (direction === "backward") {
            if (index > 0) {
                setNextIndex(index - 1);
            } else {
                setNextIndex(images.length - 1);
            }
        }
    }, [index]);

    useEffect(() => {
        if (toIndex !== -1) {
            setNextIndex(toIndex);
            return;
        }

        if (direction === "forward") {
            if (index < images.length - 1) {
                setNextIndex(index + 1);
            } else {
                setNextIndex(0);
            }
        } else if (direction === "backward") {
            if (index > 0) {
                setNextIndex(index - 1);
            } else {
                setNextIndex(images.length - 1);
            }
        }
    }, [direction]);

    useEffect(() => {
        if (toIndex === -1)
            return;

        if (toIndex < index) {
            backward(toIndex);
        } else if (toIndex > index) {
            forward(toIndex);
        }
    }, [toIndex]);

    return (
        <>
            <ImageModal
                images={images}
                isOpen={modalOpened}
                onClose={()=>setModalOpened(false)}
                startIndex={index}
            />
            <div className="img-gallery cursor-pointer">
                <img 
                    ref={currentImg} 
                    className="position-absolute d-block" 
                    src={sBaseUrl + images[index]?.path} 
                    onClick={()=>setModalOpened(true)}
                />
                <img ref={nextImage} className="position-absolute left-100p d-block" src={sBaseUrl + images[nextIndex]?.path} />

                <div className="img-counter" style={{ width: (22 * images.length) + "px" }}>
                    {
                        images.map((img, i) =>
                            <div key={i} className="point" onClick={() => setToIndex(i)}
                                style={{ background: i === index ? "rgba(0,0,0,0.8)" : "" }}></div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default memo(Carousel);