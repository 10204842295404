import { useEffect, useState } from "react";
import getServicePackages from "../../app/getServicePackages";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";

function ServicePackageInformation() {
    const [servicePackages, setServicePackages] = useState();

    useEffect(() => {
        getServicePackages(setServicePackages);
    }, []);
    
    return (
        <div className="container-lg">
            <div className="row text-center">
                {
                    servicePackages ? servicePackages.map((p, i) =>
                        <div key={i} className="p-md col-lg-4">
                            <div className="p-md box-secondary minh-450">
                                <h4 className="color-white">Csomag neve</h4>
                                <b className="color-primary">{p.packageName}</b>

                                <h4 className="color-white">Érvényesség ideje</h4>
                                <b className="color-primary">{p.intervalDays} nap</b>

                                <h4 className="color-white">Érvényesség ideje</h4>
                                <b className="color-primary">{formatHungarianCurrency(p.packagePrice)}</b>

                                <h4 className="color-white">Leírás</h4>
                                <p>
                                    {p.packageDesc}
                                </p>
                            </div>
                        </div>
                    ) : "Loading..."
                }
            </div>
        </div>
    );
}

export default ServicePackageInformation;