import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import SideMenuUser from '../../components/SideMenuUser';
import { sBaseUrl } from '../../app/url';
import { Link } from 'react-router-dom';
import Property from './Property';

function Properties() {
    const [properties, setProperties] = useState([]);
    const mb = useContext(MbContext);

    const getRealEstatesByUser = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estates-by-user", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });
            const json = await response.json();
            
            setProperties(json);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getRealEstatesByUser();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuUser />

            <div className="p-xl text-center">
                <h1>Ingatlanok</h1>

                <Link to="/ingatlan_tulajdonos/ingatlan_felvitel">
                    <button className="input-md btn-secondary cursor-pointer">
                        Hirdetés felvitele
                    </button>
                </Link>

                <div className="row">
                    {
                        properties.map((p, i) =>
                            <Property
                                key={i}
                                p={p}
                                setProperties={setProperties}
                                properties={properties}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Properties;