import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ImgThumbnail({src, width, height, classes, hasDelete = false, delCb = null, imgCb = null}) {
    const widthClass = width !== 0 ? `mw-${width}`: "";
    const heightClass = height !== 0 ? `mh-${height}`: "";

    return(
        <div className={`position-relative img-holder ${widthClass} ${heightClass} ${classes.join(" ")}`}>
            {
                hasDelete && 
                <FontAwesomeIcon 
                    className="color-error"
                    onClick={delCb ? delCb : ()=>{}} icon="fa-solid fa-trash"
                    style={{position:"absolute", "top":10, right:10}}
                />
            }
            <img onClick={imgCb ? imgCb : ()=>{}} src={src}/>
        </div>
    );
}

export default ImgThumbnail;