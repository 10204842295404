function queryStringToObj(queryString) {
    queryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;

    const params = new URLSearchParams(queryString);
    const result = {};

    for (const [key, value] of params.entries()) {
        result[key] = value;
    }

    return result;
}

export default queryStringToObj;