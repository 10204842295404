import React, { useEffect } from 'react';

const months = [
    'január', 'február', 'március', 'április', 'május', 'június',
    'július', 'augusztus', 'szeptember', 'október', 'november', 'december'
];

const daysInMonth = (month) => {
    const monthIndex = months.indexOf(month);
    return new Date(2024, monthIndex + 1, 0).getDate();
};

const DatePicker = ({ selectedMonth, setSelectedMonth, selectedDay, setSelectedDay }) => {
    useEffect(() => {
        // Reset the selected day if the new month has fewer days than the current selected day
        if (selectedDay > daysInMonth(selectedMonth)) {
            setSelectedDay(1);
        }
    }, [selectedMonth, selectedDay, setSelectedDay]);

    const handleMonthChange = (event) => {
        setSelectedMonth(parseInt(event.target.value));
    };

    const handleDayChange = (event) => {
        setSelectedDay(parseInt(event.target.value, 10));
    };

    return (
        <div className="d-flex jc-space-evenly">
            <label>
                <h4>Hónap</h4>
                <select className="input-md secondary-input" value={selectedMonth} onChange={handleMonthChange}>
                    {months.map((month, index) => (
                        <option key={index} value={index+1}>{month}</option>
                    ))}
                </select>
            </label>
            <label>
                <h4>Nap</h4>
                <select className="input-md secondary-input" value={selectedDay} onChange={handleDayChange}>
                    {Array.from({ length: daysInMonth(selectedMonth) }, (_, i) => i + 1).map(day => (
                        <option key={day} value={day}>{day}</option>
                    ))}
                </select>
            </label>
        </div>
    );
};

export default DatePicker;