import { Link, useLocation } from "react-router-dom";
import Logout from "./Logout";
import selectMenu from "../app/selectMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SideMenuAdmin() {
    const location = useLocation();

    return(
        <div className="side-menu">
            <ul>
                <li>
                    <Link to="/">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-home" /> Főoldal
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/profil")}>
                    <Link to="/admin/profil">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-user" /> Profil
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/csomagok")}>
                    <Link to="/admin/csomagok">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-box" /> Csomagok
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/maganszemely-megrendelesek")}>
                    <Link to="/admin/maganszemely-megrendelesek">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-shopping-cart" /> Megrendelések (magánszemély)
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/ceges-megrendelesek")}>
                    <Link to="/admin/ceges-megrendelesek">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-shopping-cart" /> Megrendelések (céges)
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/ceges-felhasznalok")}>
                    <Link to="/admin/ceges-felhasznalok">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-building" /> Cégek
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/maganszemely-felhasznalok")}>
                    <Link to="/admin/maganszemely-felhasznalok">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-user-tie" /> Magánszemélyek
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/emailek")}>
                    <Link to="/admin/emailek">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-envelope" /> Emailek
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/szakmak")}>
                    <Link to="/admin/szakmak">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-tools" /> Szakmák
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/admin/tartalom")}>
                    <Link to="/admin/tartalom">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-file" /> Tartalom
                    </Link>
                </li>
                <Logout/>
            </ul>
        </div>
    );
}

export default SideMenuAdmin;
