import {useLocation, useParams} from "react-router-dom";
import { sBaseUrl } from "../app/url";
import { useEffect, useRef, useState } from "react";

function PageContentComponent() {
    const location = useLocation();
    const [pageUrl, setPageUrl] = useState(window.location.pathname);
    const contentRef = useRef();

    const getPageContent = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/page-content-by-url" + pageUrl);
            const json = await response.json();
            
            if(response.ok) {
                contentRef.current.innerHTML = json.content;
            } else {
                console.log(json);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(()=> {
        setPageUrl(location.pathname);
    }, [location.pathname]);

    useEffect(()=> {
        getPageContent();
    }, [pageUrl]);

    return(
        <div className="container-xl p-lg" ref={contentRef}>

        </div>
    );
}

export default PageContentComponent;