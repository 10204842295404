import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CheckboxList = ({
    optionValues,
    selectedValues,
    setSelectedValues,
    idFieldName,
    textFielsName,
    text
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (id) => {
        if (selectedValues.includes(id)) {
            setSelectedValues(selectedValues.filter(value => value !== id));
        } else {
            setSelectedValues([...selectedValues, id]);
        }
    };

    return (
        <div className="color-white text-center">
            <div onClick={toggleOpen} className="d-flex ac-center ai-center jc-center cursor-pointer">
                <h4 className="color-white">{text}</h4>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} style={{ marginLeft: '8px' }} />
            </div>
            {isOpen && (
                <ul className="text-left mw-230 margin-auto" style={{ listStyle: 'none', paddingLeft: 0 }}>
                    {optionValues.map((option, i) => (
                        <li key={i}>
                            <label>
                                <input
                                    type="checkbox" className="mr-sm"
                                    checked={selectedValues?.includes(option[idFieldName])}
                                    onChange={() => handleCheckboxChange(option[idFieldName])}
                                />
                                {option[textFielsName]}
                            </label>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CheckboxList;