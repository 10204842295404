import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MbContext } from "../../components/Nav";
import { sBaseUrl } from "../../app/url";
import storeToken from "../../app/storeToken";
import getServicePackages from "../../app/getServicePackages";
import OrderServicePackageComp from "../../components/OrderServicePackageComp";
import checkImg from "../../app/checkImg";

function ProfessionalService({s, setServices, services}) {
    const mb = useContext(MbContext);
    const [servicePackages, setServicePackages] = useState([]);
    const [selectedPackageID, setSelectedPackageID] = useState(0);
    const [orderedPackages, setOrderedPackages] = useState([]);

    const deleteServiceConfirm = (serviceData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő szolgáltatást: `,
            `${serviceData.title}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteService(serviceData.serviceID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteService = async (serviceID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/professionals/" + serviceID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setServices(services.filter(s => s.serviceID !== serviceID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("ProfessionalServicesPage.deleteService: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const orderServicePackageConfirm = (packageID, serviceID) => {
        const packageName = servicePackages.find((sp) => sp.packageID === packageID);
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan megrendeled a következő csomagot: ${packageName.packageName}?`,
        ]);

        mb.setButtons([
            {
                text: "Megrendelés",
                cb: () => orderServicePackage(packageID, serviceID),
                icon: "fa-solid fa-check"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const orderServicePackage = async (packageID, serviceID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-orders", {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ packageID, serviceID })
            });

            const json = await response.json();

            if (response.ok) {
                mb.setDisplayMb(false);
                mb.setMessages(["A csomag megrendelése sikeres volt!"]);
                mb.setDisplayMb(true);
                mb.setButtons([{
                    text: "OK",
                    icon: "fa-solid fa-square-check",
                    cb: () => mb.setDisplayMb(false)
                }]);

                setOrderedPackages([...orderedPackages, { packageID, serviceID }]);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Property.orderServicePackage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getOrdersByProfessionalService = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-orders-by-professional-service/" + s.serviceID, {
                method: "GET",
                headers: {
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();
            setOrderedPackages(json);
        } catch (err) {
            console.log("Property.orderServicePackage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(()=> {
        getServicePackages(setServicePackages, mb);
        getOrdersByProfessionalService();
    }, []);

    return (
        <div className="p-md col-lg-4 col-md-3">
            <div className="p-md box-secondary">
                <h4 className="color-white">Szolgáltatás címe</h4>
                <b className="color-primary">{s.title}</b>

                <div className="img-holder mt-md">
                    <Link to={`/szakember/szolgaltatas_letrehozasa/${s.serviceID}`}>
                        <img src={checkImg(s.mainImg)} />
                    </Link>
                </div>

                <h4 className="color-white">Ár</h4>
                <b className="color-primary">{s.price} Ft</b>

                <div>
                    <h4 className="color-white">Szolgáltatások és kiemelések</h4>
                    <div>
                        <Link target="_blank" className="color-primary" to="/szolgaltatas-csomagok">
                            <span className="mr-sm">Információ a csomagokról</span>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                        </Link>
                    </div>
                    
                    <OrderServicePackageComp
                        data={{ serviceID: s.serviceID }}
                        path={"szakember"}
                        orderedPackages={orderedPackages}
                    />
                </div>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <Link to={`/szakember/szolgaltatas_letrehozasa/${s.serviceID}`}>
                            <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                icon="fa-solid fa-arrow-up-right-from-square" />
                        </Link>
                    </div>

                    <div>
                        <FontAwesomeIcon onClick={() => deleteServiceConfirm(s)}
                            className="color-error cursor-pointer font-20"
                            icon="fa-solid fa-trash" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfessionalService;