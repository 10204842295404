import React, { useState, useEffect, useRef, useContext } from 'react';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';
import SideMenuAdmin from '../../components/SideMenuAdmin';
import Pagination from '../../components/Pagination';

function ProfessionsPage() {
    const [professions, setProfessions] = useState([]);
    const [professionName, setProfessionName] = useState('');
    const [page, setPage] = useState(1);
    const [typeName, setTypeName] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const mb = useContext(MbContext);

    useEffect(() => {
        fetchProfessions();
    }, []);

    const fetchProfessions = async () => {
        try {
            const response = await fetch(`${sBaseUrl}/api/types?typeName=${typeName}&page=${page}`, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setProfessions(json.data);
                setTotalPages(json.totalPages);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("Error fetching professions: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const addProfession = async () => {
        try {
            const response = await fetch(`${sBaseUrl}/api/types`, {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                body: JSON.stringify({ typeName: professionName }),
                credentials: "include"
            });

            const json = await response.json();
            mb.setDisplayMb(true);

            if (response.ok) {
                setProfessions([...professions, { typeName: professionName, typeID: json.insertID }]);
                setProfessionName('');
                mb.setMessages("Sikeres felvitel!");
            } else {
                mb.setMessages(json.message);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("Error adding profession: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const updateProfession = async (typeID, newName) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/types/${typeID}`, {
                method: "PUT",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                body: JSON.stringify({ typeName: newName }),
                credentials: "include"
            });

            const json = await response.json();
            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (response.ok) {
                setProfessions(professions.map(prof => prof.typeID === typeID ? { ...prof, typeName: newName } : prof));  
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("Error updating profession: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const deleteProfessionConfirm = (profession) => {
        mb.setDisplayMb(true);
        mb.setMessages([`Biztosan törölni akarod a következő szakmát: ${profession.typeName}`]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteProfession(profession.typeID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteProfession = async (typeID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/types/${typeID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setProfessions(professions.filter(prof => prof.typeID !== typeID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("Error deleting profession: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(()=> {
        fetchProfessions();
    }, [page]);

    return (
        <div className="admin-grid">
            <SideMenuAdmin />

            <div className="p-xl text-center">
                <h1>Szakmák kezelése</h1>

                <div className="row">
                    <div className="p-md col-lg-6 col-md-6 col-sm-6">
                        <div className="p-md box-secondary">
                            <h3 className="color-white">Felvitel</h3>

                            <label className="color-white">Szakma neve</label>
                            <div className="d-flex">
                                <input
                                    value={professionName}
                                    onChange={(e) => setProfessionName(e.target.value)}
                                    type="text"
                                    className="input-md input-primary wp-100"
                                />
                                <button
                                    onClick={addProfession}
                                    className="input-md btn-primary cursor-pointer ml-sm"
                                >
                                    Felvitel
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="p-md col-lg-6 col-md-6 col-sm-6">
                        <div className="p-md box-secondary">
                            <h3 className="color-white">Keresés</h3>
                            <label className="color-white">Szakma neve</label>
                            
                            <div className="d-flex">
                                <input
                                    onChange={(e)=>setTypeName(e.target.value)}
                                    value={typeName}
                                    type="text"
                                    className="input-md input-primary wp-100"
                                />
                                <button
                                    className="input-md btn-primary cursor-pointer ml-sm"
                                    onClick={()=>fetchProfessions()}
                                >
                                    Keresés
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {professions.map((profession) => (
                        <div key={profession.typeID} className="p-md col-lg-3 col-md-4">
                            <div className="p-md box-secondary">
                                <input
                                    type="text"
                                    value={profession.typeName}
                                    onChange={(e) => setProfessions(
                                        professions.map(prof => prof.typeID === profession.typeID ?
                                            { ...prof, typeName: e.target.value } : prof)
                                    )}
                                    className="input-md input-primary wp-100"
                                />
                                <div className="d-flex jc-center">
                                    <button
                                        onClick={() => updateProfession(profession.typeID, profession.typeName)}
                                        className="input-md btn-primary cursor-pointer mr-sm"
                                    >
                                        Módosítás
                                    </button>
                                    <button
                                        onClick={() => deleteProfessionConfirm(profession)}
                                        className="input-md btn-error cursor-pointer"
                                    >
                                        Törlés
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    );
}

export default ProfessionsPage;