const API_KEY = 'BA14McHEqGcQM2LNAHJxXppZHGhDi2hw';
const BASE_URL = 'https://api.tomtom.com/search/2/search';

async function getAddressTomTom(searchQuery, limit = 5) {
    try {
        const response = await fetch(`${BASE_URL}/${searchQuery}.json?key=${API_KEY}&language=hu-HU&typeahead=true&limit=${limit}`);
   
        let data = null;

        if(response.ok)
            data = await response.json();
        else 
            data = await response.text();
        
        if(data.results) {
            const address = data?.results.map((r)=>r.address);
            return address;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching addresses:', error);
    }

    return [];
}

export default getAddressTomTom;