import React, { useContext, useEffect, useRef, useState } from 'react';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MbContext } from '../../components/Nav';
import SideMenuAdmin from '../../components/SideMenuAdmin';
import getServicePackages from "../../app/getServicePackages";
import createOrderLink from '../../app/createOrderLink';
import Pagination from '../../components/Pagination';

function OrdersByFirmsPage() {
    const [orders, setOrders] = useState([]);
    const [servicePackages, setServicePackages] = useState([]);
    const formRef = useRef(null);
    const mb = useContext(MbContext);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const deleteOrderConfirm = (orderData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő rendelést: `,
            `Rendelés ID: ${orderData.orderID}`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteOrder(orderData.orderID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteOrder = async (orderID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/delete-order-admin/${orderID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setOrders(orders.filter(order => order.orderID !== orderID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("OrdersByFirmsPage.deleteOrder: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const activateOrderConfirm = (orderData) => {
        mb.setDisplayMb(true);
        const text = orderData.isActive == 1 ? "deaktiválni" : "aktiválni";

        mb.setMessages([
            `Biztosan ${text} akarod a következő rendelést: `,
            `Rendelés ID: ${orderData.orderID}`
        ]);

        mb.setButtons([
            {
                text: orderData.isActive == 1 ? "Deaktiválás" : "Aktiválás",
                cb: () => activateDeactivateOrder(orderData.orderID, orderData.isActive),
                icon: "fa-solid fa-check"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const activateDeactivateOrder = async (orderID, isActive) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/service-orders/${orderID}/activate`, {
                method: "PATCH",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                body: JSON.stringify({ isActive: isActive == 1 ? 0 : 1 }),
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setOrders(orders.map(order => order.orderID === orderID ? { ...order, isActive: 1 } : order));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("OrdersByFirmsPage.activateDeactivateOrder: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const searchOrders = async (e) => {
        if(e)
            e.preventDefault();
        try {
            const fd = new FormData(formRef.current);
            fd.append("page", page);
            const params = new URLSearchParams(fd);
            const queryString = params.toString();
            
            const response = await fetch(`${sBaseUrl}/api/service-orders-by-firms?${queryString}`, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setTotalPages(json.totalPages);
                setOrders(json.data);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("OrdersByFirmsPage.searchOrders: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        searchOrders();
    }, [page]);

    useEffect(() => {
        searchOrders(null);
        getServicePackages(setServicePackages, mb);
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin />

            <div className="p-xl text-center">
                <h1>Megrendelések</h1>
                <form ref={formRef} className="p-md">
                    <div className="box-primary p-sm row">
                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Csomag</h4>

                                <select name="packageID"
                                    className="input-md input-primary wp-100">
                                    <option value={0}>Válassz kiemelést!</option>
                                    {
                                        servicePackages.map((sp, i) =>
                                            <option key={i} value={sp.packageID}>
                                                {sp.packageName}
                                            </option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Cégnév</h4>
                                <input name="firmName" type="text" className="input-md input-primary wp-100" />
                            </div>
                        </div>

                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Kapcsolattartó személy</h4>
                                <input name="contactPerson" type="text" className="input-md input-primary wp-100" />
                            </div>
                        </div>

                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Időszak-tól</h4>
                                <input name="dateFrom" type="date" className="input-md input-primary wp-100" />
                            </div>
                        </div>

                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Időszak-ig</h4>
                                <input name="dateTo" type="date" className="input-md input-primary wp-100" />
                            </div>
                        </div>

                        <div className="col-md-4 p-sm">
                            <div className="box-secondary p-md">
                                <h4 className="color-white">Keresés</h4>
                                <button onClick={(e)=>searchOrders(e)}
                                className="input-md btn-primary cursor-pointer">
                                    <span className="mr-xs">Keresés</span>
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="row">
                    {orders.map((order, index) => (
                        <div key={index} className="p-md col-lg-3 col-md-4">
                            <div className="p-md box-secondary">
                                <h3 className="color-primary">Megrendelési azonosító</h3>
                                <span className="color-light-grey">{order.orderID}</span>

                                <h3 className="color-primary">Cégnév</h3>
                                <span className="color-light-grey">{order.firmName} {order.firmTypeName}</span>

                                <h3 className="color-primary">Kontakt személy</h3>
                                <span className="color-light-grey">{order.contactPerson}</span>

                                <h3 className="color-primary">Megrendelő email címe</h3>
                                <span className="color-light-grey">{order.contactEmail}</span>

                                <h3 className="color-primary">Csomag neve</h3>
                                <span className="color-light-grey">{order.packageName}</span>

                                <h3 className="color-primary">Csomag ára</h3>
                                <span className="color-light-grey">{order.price} Ft</span>

                                <h3 className="color-primary">Rendelés dátuma</h3>
                                <span className="color-light-grey">{new Date(order.created).toLocaleDateString()}</span>

                                <h3 className="color-primary">Aktiválás dátuma</h3>
                                <span className="color-light-grey">{order.activationDate ? new Date(order.activationDate).toLocaleDateString() : 'Nincs'}</span>

                                <h3 className="color-primary">Mobil</h3>
                                <span className="color-light-grey">{order.mobile ? order.mobile : 'Nincs'}</span>

                                <h3 className="color-primary">Vezetékes</h3>
                                <span className="color-light-grey">{order.tel ? order.tel : 'Nincs'}</span>

                                <h3 className="color-primary">Szállítási cím</h3>
                                <span className="color-light-grey">
                                    {
                                        order.shippingPostalCode
                                        ? 
                                        `${order.countryCode}, ${order.state ? order.state + "," : ""}
                                        ${order.shippingPostalCode}, ${order.city}, 
                                        ${order.address_line1} ${order.address_line2 ? " ," + order.address_line2 : ""}`
                                        : "-"
                                    }
                                </span>

                                <h3 className="color-primary">Szolgáltatás link</h3>
                                <span className="color-light-grey">
                                    {
                                        (()=> {
                                            const url = createOrderLink(
                                                order.realEstateID, 
                                                order.accommodationID, 
                                                order.serviceID
                                            );

                                            return <a className="color-white" 
                                            target="_blank" href={url}>{url}</a>
                                        })()
                                    }
                                </span>

                                <div className="flex jc-space-around mt-md">
                                    <div>
                                        {
                                            order.isActive == 1
                                                ?
                                                <div className="color-white d-flex ai-center">
                                                    <span className="color-white mr-xs">Deaktivál</span>
                                                    <FontAwesomeIcon
                                                        onClick={() => activateOrderConfirm(order)}
                                                        className="color-warning cursor-pointer font-20"
                                                        icon="fa-solid fa-circle-xmark"
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <span className="color-white mr-xs">Aktivál</span>
                                                    <FontAwesomeIcon
                                                        onClick={() => activateOrderConfirm(order)}
                                                        className="color-success cursor-pointer font-20"
                                                        icon="fa-solid fa-check"
                                                    />
                                                </>
                                        }
                                    </div>

                                    <div>
                                        <FontAwesomeIcon
                                            onClick={() => deleteOrderConfirm(order)}
                                            className="color-error cursor-pointer font-20 ml-md"
                                            icon="fa-solid fa-trash"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    );
}

export default OrdersByFirmsPage;