import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { sBaseUrl } from '../../app/url';
import { useLocation, useNavigate } from 'react-router-dom';
import getType from '../../app/getType';
import queryStringToObj from '../../app/queryStringToObj';
import getUrlVar from '../../app/getUrlVar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Professional from './Professional';

function ProfessionalsPagePublic() {
    const [professionals, setProfessionals] = useState([]);
    const mb = useContext(MbContext);
    const formRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [countries, setCountries] = useState([]);
    const [queryStringData, setQueryStringData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(getUrlVar("page", 1));
    const [serviceTypes, setServiceTypes] = useState([]);

    const search = async (e = null) => {
        e?.preventDefault();

        const fd = new FormData(formRef.current);
        const qs = e !== null ? new URLSearchParams(fd).toString() : location.search.substring(1);
        setQueryStringData(queryStringToObj(qs));
        searchProfessionals(qs);
    };

    const searchProfessionals = async (qs) => {
        try {
            navigate("/szakemberek?" + qs);

            const response = await fetch(sBaseUrl + "/api/search-professionals?" + qs, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();
            setProfessionals(json.data);
            setTotalPages(json.totalPages);
        } catch (err) {
            console.log("Professionals.getProfessionals: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const next = () => {
        if (page < totalPages) setPage(p => ++p);
    };

    const prev = () => {
        if (page > 1) setPage(p => --p);
    };

    useEffect(() => {
        getType("types_service", setServiceTypes);
        searchProfessionals(location.search.substring(1));
        setQueryStringData(queryStringToObj(location.search));
    }, []);

    useEffect(() => {
        const searchParamsObj = queryStringToObj(location.search);
        searchParamsObj.page = page;
        const sp = new URLSearchParams(searchParamsObj);
        searchProfessionals(sp.toString());
        getType("countries", setCountries);
    }, [page]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQueryStringData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const addHighlight = (packageType)=> {
        if(packageType === 2) {
            return "border-primary-2 shadow";
        } else if(packageType === 4) {
            return "border-success-2 shadow";
        }

        return "";
    };

    return (
        <div className="container-xl">
            <div className="p-xl text-center">
                <h1>Szakemberek</h1>
                <form ref={formRef} className="p-md">
                    <div className="box-primary p-md row">
                        <div className="col-md-4 p-md">
                            <h4 className="color-white">Szolgáltatás típusa</h4>
                            <select
                                name="serviceType"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.serviceType || ''}
                            >
                                <option value="">Válassz típust</option>
                                {serviceTypes.map((t, i) => (
                                    <option key={i} value={t.typeID}>
                                        {t.typeName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-5 p-md">
                            <h4 className="color-white">Település</h4>
                            <input
                                type="text"
                                placeholder="település"
                                name="settlement"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.settlement || ''}
                            />
                        </div>

                        <div className="col-md-3 p-md">
                            <h4 className="color-white">Kulcsszó</h4>
                            <input
                                type="text"
                                placeholder="kulcsszó"
                                name="description"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.description || ''}
                            />
                        </div>

                        <input type="hidden" name="page" value={page} />
                    </div>

                    <button onClick={(e) => search(e)} className="input-md btn-primary cursor-pointer">Keresés</button>
                </form>

                <div>{page}/{totalPages} oldal</div>

                <div className="row">
                    {professionals.map((p, i) => (
                        <Professional
                            p={p} key={i}
                            highlightCls={addHighlight(p.packageType)}
                        />
                    ))}
                </div>

                <div className="d-flex mw-300 jc-space-evenly ai-center margin-auto mt-lg mb-lg noselect">
                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={() => setPage(1)}
                            icon="fa-solid fa-angles-left" />

                        <FontAwesomeIcon
                            onClick={prev}
                            icon="fa-solid fa-angle-left" />
                    </div>

                    <div>{page}/{totalPages} oldal</div>

                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={next}
                            icon="fa-solid fa-angle-right" />

                        <FontAwesomeIcon
                            onClick={() => setPage(totalPages)}
                            icon="fa-solid fa-angles-right" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ProfessionalsPagePublic;