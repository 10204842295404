import React, { useContext, useEffect, useRef, useState } from 'react';
import { sBaseUrl } from '../../app/url';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';
import Validator from '../../app/Validator';
import getType from '../../app/getType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideMenuAccommodation from '../../components/SideMenuAccommodation';
import SideMenuProfessional from '../../components/SideMenuProfessional';
import Address from '../../components/Address';

function FirmProfilePage() {
    const formRef = useRef(null);
    const mb = useContext(MbContext);
    const [firmTypes, setFirmTypes] = useState([]);
    const [profileData, setProfileData] = useState({
        firmName: '',
        contactPerson: '',
        countryID:0,
        postalCode: '',
        countyRegion:"",
        settlement: '',
        street: '',
        publicPlaceType:0,
        houseNumber: '',
        floorNumber: '',
        doorNumber: '',
        mobile: '',
        tel: '',
        contactEmail: '',
        logoImg: '',
        firmType: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(sBaseUrl + `/api/firm-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify(profileData)
            });

            const json = await response.json();

            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (error) {
            console.error('Profil frissítése sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getProfileData = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/firm-profile", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (json.token && json.token.token) {
                storeToken(json.token.token);
            }

            if (response.ok) {
                setProfileData(json.message);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }
        } catch (err) {
            console.error('Profil adatok lekérése sikertelen:', err);
        }
    };

    const uploadLogo = async (e)=> {
        const formData = new FormData();
        formData.append("logoImg", e.target.files[0]);

        try {
            const response = await fetch(sBaseUrl + "/api/upload-logo", {
                method:"POST",
                headers: {
                    'authorization': localStorage.getItem("token")
                },
                credentials:"include",
                body:formData
            });

            const json = await response.json();

            if(response.ok) {
                setProfileData({...profileData, logoImg:json.fileName});
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (error) {
            console.error('A fájlok feltöltése (update-nél) sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const deleteLogo = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/delete-logo", {
                method:"DELETE",
                headers:{"authorization":localStorage.getItem("token")},
                credentials:"include"
            });

            const json = await response.json();

            if(response.ok) {
                setProfileData({...profileData, logoImg:null});
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }
        } catch (error) {
            console.error('A fájlok feltöltése (update-nél) sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    useEffect(() => {
        getProfileData();
        getType("types_firm", setFirmTypes);
    }, []);

    return (
        <div className="admin-grid">
            {
                mb.sessionInfo?.userType === "szállásadó"
                ?
                <SideMenuAccommodation/>
                : 
                <SideMenuProfessional/>
            }

            <div className="p-xl text-center">

                <h2>Profil frissítése</h2>

                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">
                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Cég típusa</h4>

                                        <select value={profileData.firmType || 0}
                                        onChange={(e) => setProfileData({ ...profileData, firmType: e.target.value })}
                                        name="firmType" className="input-md input-primary wp-100">
                                            {
                                                firmTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>

                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Cég neve *</h4>
                                        <input onKeyUp={(e) => new Validator().isString()
                                            .hasMinLength(3).hasMaxLength(100).exec(e.target, "input-error")}
                                            value={profileData.firmName || ''}
                                            onChange={(e) => setProfileData({ ...profileData, firmName: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='firmName'
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Vezetékes telefon</h4>
                                        <input
                                            value={profileData.tel || ''}
                                            onChange={(e) => setProfileData({ ...profileData, tel: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='tel'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Mobiltelefon *</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isPhoneNumber().exec(e.target, "input-error")}
                                            value={profileData.mobile || ''}
                                            onChange={(e) => setProfileData({ ...profileData, mobile: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='mobile'
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    
                                    <div className="col-sm-6 p-md">
                                        <h4>Kapcsolattartó személy</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isString()
                                                .hasMinLength(3).hasMaxLength(100).exec(e.target, "input-error")}
                                            type="text"
                                            value={profileData.contactPerson || ''}
                                            onChange={(e) => setProfileData({ ...profileData, contactPerson: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='contactPerson'
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Kapcsolati email *</h4>
                                        <input
                                            onKeyUp={(e) => new Validator().isEmail().exec(e.target, "input-error")}
                                            value={profileData.contactEmail || ''}
                                            onChange={(e) => setProfileData({ ...profileData, contactEmail: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='contactEmail'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">

                                <Address
                                    formData={profileData}
                                    setFormData={setProfileData}
                                />

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Emelet</h4>
                                        <input
                                            value={profileData.floorNumber || ''}
                                            onChange={(e) => setProfileData({ ...profileData, floorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='floorNumber'
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Ajtó</h4>
                                        <input
                                            value={profileData.doorNumber || ''}
                                            onChange={(e) => setProfileData({ ...profileData, doorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='doorNumber'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className='btn-sm btn-secondary' type="submit">Profil frissítése</button>
                </form>

                {/* <h3>Logo kép feltöltése</h3>
                <div className="p-lg">
                    <div>
                        <div className="box-light-grey margin-auto position-relative" style={{width:"250px", height:"250px"}}>
                            <div className="img-holder">
                                {
                                    (profileData.logoImg != null && profileData.logoImg !== "")
                                    &&
                                    <>
                                        <img src={`${sBaseUrl}${profileData.logoImg}`}/>

                                        <FontAwesomeIcon onClick={deleteLogo}
                                        style={{position:"absolute", top:5, right:5}}
                                        className="color-error cursor-pointer" icon="fa fa-trash"/>
                                    </>
                                }
                            </div>

                            
                        </div>
                        <input type="file" onChange={uploadLogo}
                        className="input-md btn-primary mt-md" multiple accept="image/*"/>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default FirmProfilePage;