import { sBaseUrl } from "./url";

const getData = async (endpoint, setter, mb) => {
    try {
        const response = await fetch(sBaseUrl + "/api/" +  endpoint, {
            method: "GET",
            headers: { "authorization": localStorage.getItem("token") },
            credentials: "include"
        });

        const json = await response.json();

        if (response.ok) {
            setter(json);
        } else {
            mb.setDisplayMb(true);
            mb.setMessages(json.message);
        }
    } catch (err) {
        console.error('Adatok lekérése sikertelen:', err);
    }
};

export default getData;