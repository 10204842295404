import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { sBaseUrl } from '../../app/url';
import { useLocation, useNavigate } from 'react-router-dom';
import FirmUser from './FirmUser';
import queryStringToObj from '../../app/queryStringToObj';
import getUrlVar from '../../app/getUrlVar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import storeToken from '../../app/storeToken';
import SideMenuAdmin from '../../components/SideMenuAdmin';

function FirmUsersPage() {
    const [users, setUsers] = useState([]);
    const mb = useContext(MbContext);
    const formRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [queryStringData, setQueryStringData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(getUrlVar("page", 1));

    const search = async (e = null) => {
        e?.preventDefault();

        const fd = new FormData(formRef.current);
        const qs = e !== null ? new URLSearchParams(fd).toString() : location.search.substring(1);
        setQueryStringData(queryStringToObj(qs));
        searchUsers(qs);
    };

    const searchUsers = async (qs) => {
        try {
            navigate("/admin/ceges-felhasznalok?" + qs);

            const response = await fetch(sBaseUrl + "/api/search-firm-users?" + qs, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();
            setUsers(json.message.data);
            setTotalPages(json.message.totalPages);

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("FirmUsersPage.searchUsers: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const next = () => {
        if (page < totalPages) setPage(p => ++p);
    };

    const prev = () => {
        if (page > 1) setPage(p => --p);
    };

    useEffect(() => {
        searchUsers(location.search.substring(1));
        setQueryStringData(queryStringToObj(location.search));
    }, []);

    useEffect(() => {
        const searchParamsObj = queryStringToObj(location.search);
        searchParamsObj.page = page;
        const sp = new URLSearchParams(searchParamsObj);
        searchUsers(sp.toString());
    }, [page]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setQueryStringData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className="admin-grid">
            <SideMenuAdmin/>

            <div className="p-xl text-center">
                <h1>Felhasználók</h1>
                <form ref={formRef} className="p-md">
                    <div className="box-primary p-md row">
                        <div className="col-lg-2 p-md">
                            <h4 className="color-white">Azonosító</h4>
                            <input
                                type="text"
                                placeholder="userID"
                                name="userID"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.userID || ''}
                            />
                        </div>

                        <div className="col-lg-2 p-md">
                            <h4 className="color-white">Aktivált/inaktív</h4>

                            <select name="activated" onChange={handleInputChange}
                            value={queryStringData.activated || ''}
                            className="input-md input-secondary wp-100">
                                <option value="">mindenki</option>
                                <option value={1}>aktivált</option>
                                <option value={0}>inaktív</option>
                            </select>
                        </div>

                        <div className="col-lg-4 p-md">
                            <h4 className="color-white">Cég neve</h4>
                            <input
                                type="text"
                                placeholder="cég neve"
                                name="firmName"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.firmName || ''}
                            />
                        </div>

                        <div className="col-lg-4 p-md">
                            <h4 className="color-white">Kontakt személy</h4>
                            <input
                                type="text"
                                placeholder="kontakt személy"
                                name="contactPerson"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.contactPerson || ''}
                            />
                        </div>

                        <input type="hidden" name="page" value={page} />
                    </div>

                    <button onClick={(e) => search(e)} className="input-md btn-primary cursor-pointer">Keresés</button>
                </form>

                <div>{page}/{totalPages} oldal</div>

                <div className="row">
                    {users.map((user, i) => (
                        <FirmUser key={i} user={user} setUsers={setUsers} />
                    ))}
                </div>

                <div className="d-flex mw-300 jc-space-evenly ai-center margin-auto mt-lg mb-lg noselect">
                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={() => setPage(1)}
                            icon="fa-solid fa-angles-left" />

                        <FontAwesomeIcon
                            onClick={prev}
                            icon="fa-solid fa-angle-left" />
                    </div>

                    <div>{page}/{totalPages} oldal</div>

                    <div className="font-24 cursor-pointer color-primary">
                        <FontAwesomeIcon className="mr-xl"
                            onClick={next}
                            icon="fa-solid fa-angle-right" />

                        <FontAwesomeIcon
                            onClick={() => setPage(totalPages)}
                            icon="fa-solid fa-angles-right" />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default FirmUsersPage;