import React, { useState, useEffect, useContext } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';
import SideMenuProfessional from '../../components/SideMenuProfessional';
import getType from '../../app/getType';
import { useNavigate, useParams } from 'react-router-dom';
import getData from '../../app/getData';
import ImgThumbnail from '../../components/ImgThumbnail';
import ImageThumbnailMaker from '../../components/ImageThumbnailMaker';
import Address from '../../components/Address';
import RegionSelector from '../../components/RegionSelector';

function ProfessionalPage() {
    const { serviceID } = useParams();
    const navigate = useNavigate();
    const mb = useContext(MbContext);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [publicPlaceTypes, setPublicPlaceTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [dbImages, setDbImages] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        serviceType: '',
        webpage: '',
        phone: "",
        contactEmail: "",
        price: '',
        description: '',
        mondayOpen: false,
        mondayFrom: '',
        mondayTo: '',
        tuesdayOpen: false,
        tuesdayFrom: '',
        tuesdayTo: '',
        wednesdayOpen: false,
        wednesdayFrom: '',
        wednesdayTo: '',
        thursdayOpen: false,
        thursdayFrom: '',
        thursdayTo: '',
        fridayOpen: false,
        fridayFrom: '',
        fridayTo: '',
        saturdayOpen: false,
        saturdayFrom: '',
        saturdayTo: '',
        sundayOpen: false,
        sundayFrom: '',
        sundayTo: ''
    });

    useEffect(() => {
        getType("types_service", setServiceTypes);
    }, []);

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const uploadImages = async (serviceID) => {
        const formData = new FormData();

        for (const image of images) {
            formData.append("images", image);
        }

        formData.append("mainImageIndex", mainImageIndex);

        const response = await fetch(sBaseUrl + "/api/upload-service-images/" + serviceID, {
            method: "POST",
            headers: {
                'authorization': localStorage.getItem("token")
            },
            body: formData
        });

        if (response.ok) {
            navigate("/szakember/szolgaltatas_letrehozasa/" + serviceID);
            window.location.reload();
        }
    };

    const gatherUploadImages = async (e) => {
        const formData = new FormData();

        for (const image of e.target.files)
            formData.append("images", image);

        formData.append("mainImageIndex", mainImageIndex);

        try {
            const response = await fetch(sBaseUrl + "/api/upload-service-images/" + serviceID, {
                method: "POST",
                headers: {
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: formData
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if (response.ok) {
                setDbImages([...dbImages, ...json.imagePaths]);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json);
            }
        } catch (error) {
            console.error('A fájlok feltöltése (update-nél) sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = sBaseUrl + "/api/professionals";

            if (serviceID)
                url += "/" + serviceID;

            const response = await fetch(url, {
                method: serviceID ? "PUT" : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('token')
                },
                credentials: 'include',
                body: JSON.stringify(formData)
            });

            const json = await response.json();

            if (serviceID !== undefined || !response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if ((serviceID === undefined || serviceID === null) && response.ok) {
                uploadImages(json.insertID);
            }
        } catch (err) {
            console.error("Error creating professional:", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getServiceData = async () => {
        if (serviceID === undefined || serviceID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + "/api/professionals/" + serviceID, {
                method: "GET",
                headers: { authorization: localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setFormData(json);
            }
        } catch (err) {
            console.error("getServiceData", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getServiceImages = async () => {
        if (serviceID === undefined || serviceID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + "/api/professional-images/" + serviceID);

            const json = await response.json();
            const index = json.findIndex(img => img.isMainImg == 1);
            setMainImageIndex(index);
            setDbImages(json);
        } catch (err) {
            console.error("getServiceImages", err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const updateMainImage = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/update-main-image-professionals/" + imgID, {
                method: "PATCH",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ serviceID })
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok) {
                const oldMainImgIndex = dbImages.findIndex(img => img.isMainImg === 1);
                const newMainImgIndex = dbImages.findIndex(img => img.imgID === imgID);
                const images = [...dbImages];
                images[oldMainImgIndex].isMainImg = 0;
                images[newMainImgIndex].isMainImg = 1;
                setDbImages(images);
            }
        } catch (err) {
            console.error('Szolgáltatás fő képének beállítása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const deleteImg = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/professional-images/" + imgID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok)
                setDbImages(dbImages.filter(img => img.imgID !== imgID));
        } catch (err) {
            console.error('A kép törlése sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    useEffect(() => {
        getServiceData();
        getType("types_public_place", setPublicPlaceTypes);
        getData("countries", setCountries, mb);
        getServiceImages();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuProfessional />

            <div className="p-xl text-center">
                <h2>Szolgáltatás létrehozása</h2>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 p-md">
                            <div className="box-light-grey">
                                <div className="row">
                                    {
                                        serviceID && 
                                        <>
                                            <div className="col-sm-6 p-md">
                                                <h4>Régió/vármegye felvitele</h4>
                                                <RegionSelector
                                                    serviceID={serviceID}
                                                    fieldName="countrySubdivisionName"
                                                    regionType={1}
                                                    autocompleteID={"autocomplete-" + 1}
                                                />
                                            </div>

                                            <div className="col-sm-6 p-md">
                                                <h4>Település felvitele</h4>
                                                <RegionSelector
                                                    serviceID={serviceID}
                                                    fieldName="municipality"
                                                    regionType={2}
                                                    autocompleteID={"autocomplete-" + 2}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="col-sm-6 p-md">
                                        <h4>Szolgáltatás megnevezése</h4>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Szolgáltatás típusa</h4>
                                        <select
                                            name="serviceType"
                                            value={formData.serviceType}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        >
                                            <option value="">Válasszon</option>
                                            {serviceTypes.map(type => (
                                                <option key={type.typeID} value={type.typeID}>
                                                    {type.typeName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <Address
                                    formData={formData}
                                    setFormData={setFormData}
                                />

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Email cím</h4>
                                        <input
                                            type="email"
                                            name="contactEmail"
                                            value={formData.contactEmail}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Telefonszám</h4>
                                        <input
                                            type="text"
                                            name="phone"
                                            placeholder="0670/+3670 xxx xxxx"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Weboldal</h4>
                                        <input
                                            type="url"
                                            name="webpage"
                                            value={formData.webpage}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Ár</h4>
                                        <input
                                            type="number"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleChange}
                                            className="input-md input-primary wp-100"
                                        />
                                    </div>
                                </div>

                                <h4>Leírás</h4>
                                <div className="p-md">
                                    <textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="input-md input-primary wp-100 minwp-100 maxwp-100 minh-150"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 p-md">
                            <div className="box-light-grey">
                                <h4>Nyitvatartási idő</h4>
                                {[
                                    { eng: 'monday', hun: 'Hétfő' },
                                    { eng: 'tuesday', hun: 'Kedd' },
                                    { eng: 'wednesday', hun: 'Szerda' },
                                    { eng: 'thursday', hun: 'Csütörtök' },
                                    { eng: 'friday', hun: 'Péntek' },
                                    { eng: 'saturday', hun: 'Szombat' },
                                    { eng: 'sunday', hun: 'Vasárnap' }
                                ].map(day => (
                                    <div key={day.eng} className="row">
                                        <div className="col-sm-2 p-md d-flex ai-center">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={`${day.eng}Open`}
                                                    checked={formData[`${day.eng}Open`]}
                                                    onChange={handleChange}
                                                    className="mr-sm"
                                                />
                                                {day.hun}
                                            </label>
                                        </div>
                                        <div className="col-sm-5 p-md">
                                            <label>Tól</label>
                                            <input disabled={!formData[`${day.eng}Open`]}
                                                type="time"
                                                name={`${day.eng}From`}
                                                value={formData[`${day.eng}From`]}
                                                onChange={handleChange}
                                                className="input-md input-primary wp-100"
                                            />
                                        </div>
                                        <div className="col-sm-5 p-md">
                                            <label>Ig</label>
                                            <input disabled={!formData[`${day.eng}Open`]}
                                                type="time"
                                                name={`${day.eng}To`}
                                                value={formData[`${day.eng}To`]}
                                                onChange={handleChange}
                                                className="input-md input-primary wp-100"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                    {
                        serviceID &&
                        <div className="p-lg">
                            <div className="box-secondary p-md flex jc-start f-wrap-wrap">
                                {
                                    dbImages.map((img, i) =>
                                        <ImgThumbnail
                                            imgCb={() => updateMainImage(img.imgID)}
                                            key={i} src={`${sBaseUrl}${img.path}`}
                                            width={200} height={200} hasDelete={true}
                                            delCb={() => deleteImg(img.imgID)}
                                            classes={[
                                                "border-primary-2",
                                                "cursor-pointer",
                                                "m-sm",
                                                img.isMainImg ? "border-warning-2" : ""
                                            ]} />
                                    )
                                }
                            </div>
                        </div>
                    }

                    <div className="p-lg">
                        {
                            serviceID === undefined ?
                                <ImageThumbnailMaker images={images} setImages={setImages}
                                    mainImageIndex={mainImageIndex} setMainImageIndex={setMainImageIndex} />
                                :
                                <input
                                    onChange={gatherUploadImages}
                                    type="file"
                                    multiple
                                    accept="image/*" />
                        }
                    </div>

                    <button type="submit" className="btn-sm btn-secondary">Mentés</button>
                </form>
            </div>
        </div>
    );
}

export default ProfessionalPage;