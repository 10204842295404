import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { MbContext } from '../../components/Nav';
import SideMenuAccommodation from '../../components/SideMenuAccommodation';
import ServiceOrder from '../../components/ServiceOrder';

function HostServiceOrdersPage() {
    const [serviceOrders, setServiceOrders] = useState([]);
    const mb = useContext(MbContext);

    const deleteServiceOrderConfirm = (orderData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő rendelést: `,
            `Rendelés ID: ${orderData.orderID}`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteServiceOrder(orderData.orderID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteServiceOrder = async (orderID) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/service-orders/${orderID}`, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setServiceOrders(serviceOrders.filter(order => order.orderID !== orderID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("HostServiceOrdersPage.deleteServiceOrder: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        const fetchServiceOrders = async () => {
            try {
                const response = await fetch(`${sBaseUrl}/api/service-orders-by-host`, {
                    method: "GET",
                    headers: { "authorization": localStorage.getItem("token") },
                    credentials: "include"
                });

                const json = await response.json();

                if (response.ok) {
                    setServiceOrders(json);
                }

                if (json.token && json.token.token !== null) {
                    storeToken(json.token.token);
                }
            } catch (err) {
                console.log("HostServiceOrdersPage.fetchServiceOrders: ", err);
                mb.setDisplayMb(true);
                mb.setMessages("Váratlan hiba történt!");
            }
        };

        fetchServiceOrders();
    }, []);

    return (
        <div className="admin-grid">
            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
                setButtons={mb.setButtons}
            />

            <SideMenuAccommodation />

            <div className="p-xl text-center">
                <h1>Szolgáltatások rendelései</h1>

                <div className="row">
                    {serviceOrders.map((order, i) => (
                        <ServiceOrder
                            key={i}
                            order={order}
                            title={order.accommodationName}
                            titleName="Szállás neve"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HostServiceOrdersPage;