import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { cBaseUrl, sBaseUrl } from "../app/url";

function CookieConsent() {
    const [hided, setHided] = useState(localStorage.getItem("cookieStatus") !== null);

    const consentCookie = (isConsented)=> {
        localStorage.setItem("cookieStatus", isConsented);

        setHided(true);
    };

    return (
        <>
            <div onClick={()=>setHided(false)}
            className={"cookie-consent-up-arrow " + (!hided && "d-none")}>
                <FontAwesomeIcon className="font-16"
                    icon="fa-solid fa-angle-up"
                />
                Cookie beállítások
            </div>

            <div className={"box-primary position-fixed bottom-0 color-white p-sm text-center bottom-0 wp-100 " + (hided && "d-none")}>
                <div>
                    Ahhoz, hogy használd az oldal bejelentkezési funkcióját el kell fogadnod az 
                    <a target="_blank" className="color-white ml-sm" href={sBaseUrl + "/files/pdfs/adatkezelesi_nyilatkozat.pdf"}>
                        Adatkezelési nyilatkozatot
                    </a>.
                </div>
                
                <div className="mw-300 d-flex jc-space-evenly margin-auto">
                    <button onClick={()=>consentCookie(1)}
                    className="input-md btn-success cursor-pointer">
                        Elfogadom
                    </button>
                    <button onClick={()=>consentCookie(0)}
                    className="input-md btn-warning cursor-pointer">
                        Nem fogadom el
                    </button>
                </div>
            </div>
        </>
    );
}

export default CookieConsent;