import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { sBaseUrl } from '../app/url';
import { MbContext } from './Nav';
import MessageBox from './MessageBox';

const TinyMCEEditor = ({ content, setContent }) => {
    const mb = useContext(MbContext);

    const handleImageUpload = async (blobInfo, succes, failure) => {
        const formData = new FormData();
        formData.append('image', blobInfo.blob(), blobInfo.filename());

        try {
            const response = await fetch(`${sBaseUrl}/api/upload-content-image`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'authorization': `${localStorage.getItem('token')}`
                },
                body: formData
            });

            const json = await response.json();

            if (!response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
                return;
            }

            if (json && json.imageUrl) {
                succes(sBaseUrl + "/" + json.imageUrl);
                return sBaseUrl + "/" + json.imageUrl;
            }
        } catch (err) {
            console.log('Image upload failed:', err);
        }
    };

    const handleFileUpload = async (blobInfo, success, failure) => {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());

        try {
            const response = await fetch(`${sBaseUrl}/api/upload-content-file`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'authorization': `${localStorage.getItem('token')}`
                },
                body: formData
            });

            const json = await response.json();

            if (!response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
                return;
            }

            if (json && json.fileUrl) {
                success(sBaseUrl + "/" + json.fileUrl);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (err) {
            console.error('File upload failed:', err);
            failure('File upload failed');
        }
    };

    const file_picker_callback = (callback, value, meta) => {
        if (meta.filetype === 'image') {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            //input.setAttribute('accept', '.png,.jpg,.jpeg,.gif,.webp');

            input.onchange = function () {
                const file = input.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const id = 'blobid' + (new Date()).getTime();
                        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        handleImageUpload(blobInfo, callback, (err) => {
                            console.error('Image upload failed:', err);
                            //failure('Image upload failed');
                        });
                    };
                    reader.readAsDataURL(file);
                }
            };

            input.click();
        } else if (meta.filetype === 'file') {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', '.pdf,.doc,.docx');

            input.onchange = function () {
                const file = input.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const id = 'blobid' + (new Date()).getTime();
                        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.split(',')[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        handleFileUpload(blobInfo, callback, (err) => {
                            console.error('File upload failed:', err);
                            //failure('File upload failed');
                        });
                    };
                    reader.readAsDataURL(file);
                }
            };

            input.click();
        }
    };

    return (
        <div>
            <Editor
                apiKey="qbwroqum9ltyw9i8n56vstgn3i5vfgf6ackss4rwsyyd5xgk" 
                value={content}
                init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                        'image', "link",
                    ],
                    toolbar:
                        'undo redo | styles | bold italic underline | alignleft aligncenter alignright alignjustify | indent outdent numlist bullist | link image media',
                    images_upload_handler: handleImageUpload,
                    file_picker_callback: file_picker_callback,
                    automatic_uploads: true,
                    image_advtab: true,
                }}
                onEditorChange={(newContent) => setContent(newContent)}
            />
        </div>
    );
};

export default TinyMCEEditor;