import { useContext, useEffect, useState } from "react";
import SideMenuAdmin from "../../components/SideMenuAdmin";
import TinyMCEEditor from "../../components/TinyMCEEditor";
import { useParams } from "react-router-dom";
import { MbContext } from "../../components/Nav";
import { sBaseUrl } from "../../app/url";
import storeToken from "../../app/storeToken";
import MessageBox from "../../components/MessageBox";

function EditEmail() {
    const [content, setContent] = useState("");
    const [subject, setSubject] = useState("");
    const [intervalDays, setIntervalDays] = useState(null);
    const [emailType, setEmailType] = useState(0);
    const { emailID } = useParams();
    const mb = useContext(MbContext);

    const updateEmail = async () => {
        try {
            const response = await fetch(`${sBaseUrl}/api/emails/${emailID}`, {
                method: "PUT",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    content: content,
                    subject: subject
                })
            });

            const json = await response.json();
            console.log(json);
            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("IndividualUser.deleteUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getEmailData = async () => {
        try {
            const response = await fetch(`${sBaseUrl}/api/emails/${emailID}`, {
                method: "GET",
                headers: {
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setContent(json.content ? json.content : "");
                setSubject(json.subject ? json.subject : "");
                setIntervalDays(json.intervalDays ? json.intervalDays : 0);
                setEmailType(json.emailType ? json.emailType : 0);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("IndividualUser.deleteUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getEmailData();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin />

            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
                setButtons={mb.setButtons}
            />

            <div className="p-lg text-center">
                <div className="mw-800 margin-auto box-light-grey p-md">
                    <h3>Tárgy</h3>
                    <input type="text" value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="input-md input-primary wp-100" />

                    {
                        emailType === 3
                        && 
                        <>
                            <h3>Kiküldés, amikor n nap van még a lejáratig</h3>
                            <input type="number" value={intervalDays}
                            onChange={(e) => setIntervalDays(parseInt(e.target.value))}
                            className="input-md input-primary wp-100" />
                        </>
                    }

                    <h3>Tartalom</h3>
                    <TinyMCEEditor
                        content={content}
                        setContent={setContent}
                    />

                    <button onClick={updateEmail}
                        className="input-md btn-primary cursor-pointer">
                        Mentés
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditEmail;