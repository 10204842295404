import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import { Link } from 'react-router-dom';
import { MbContext } from '../../components/Nav';
import SideMenuProfessional from '../../components/SideMenuProfessional';
import ProfessionalService from './ProfessionalService';

function ProfessionalServicesPage() {
    const [services, setServices] = useState([]);
    const mb = useContext(MbContext);

    const getServicesByUser = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/professional-services", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });
            const json = await response.json();
            setServices(json);
        } catch (err) {
            console.log("ProfessionalServicesPage.getServicesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getServicesByUser();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuProfessional />

            <div className="p-xl text-center">
                <h1>Szakmai Szolgáltatások</h1>

                <Link to="/szakember/szolgaltatas_letrehozasa">
                    <button className="input-md btn btn-primary cursor-pointer">Szolgáltatás létrehozása</button>
                </Link>

                <div className="row">
                    {
                        services.map((s, i) => (
                            <ProfessionalService 
                                s={s} key={i}
                                setServices={setServices} 
                                services={services}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ProfessionalServicesPage;