// import { useParams } from "react-router-dom";
// import { sBaseUrl } from "../../app/url";
// import { useEffect, useState } from "react";
// import Carousel from "../../components/Carousel"; // Ha van képgaléria, használható
// import getRegionsByService from "../../app/getRegionsByService";

// function ProfessionalPagePublic() {
//     const { serviceID } = useParams();
//     const [professional, setProfessional] = useState(null);
//     const [images, setImages] = useState([]);
//     const [countyRegions, setCountyRegions] = useState([]);
//     const [settlementRegions, setSettlementRegions] = useState([]);

//     const getProfessional = async () => {
//         try {
//             const response = await fetch(sBaseUrl + "/api/professionals-public/" + serviceID);
//             const json = await response.json();
//             console.log(json);
//             setProfessional(json);
//         } catch (err) {
//             console.log("Error fetching professional data: ", err);
//             // Ide jöhet egy állapotkezelő vagy értesítés, ha hiba történik
//         }
//     };

//     // Ha szükséges, a képeket is betöltheted hasonló módon
//     const getProfessionalImages = async () => {
//         try {
//             const response = await fetch(sBaseUrl + "/api/professional-images/" + serviceID);
//             const json = await response.json();
//             setImages(json);
//         } catch (err) {
//             console.log("Error fetching professional images: ", err);
//             // Ide jöhet egy állapotkezelő vagy értesítés, ha hiba történik
//         }
//     };

//     useEffect(() => {
//         getProfessional();
//         getProfessionalImages();
//         getRegionsByService(serviceID, 1, setCountyRegions);
//         getRegionsByService(serviceID, 2, setSettlementRegions);
//     }, []);

//     const displayValue = (value) => (value != null && value !== "" ? value : "-");

//     return (
//         <div className="container-xl text-center">
//             <div className="row">
//                 <div className="p-md col-md-6">
//                     <div className="box-light-grey p-md">
//                         <div className="col-lg-12">
//                             <h3>{displayValue(professional?.title)}</h3>
//                         </div>
//                         {images.length > 0 && <Carousel images={images} />} {/* Ha van képgaléria */}

//                         <div className="p-md col-lg-12">
//                             <h3>Kapcsolat</h3>
//                             <div className="row">
//                                 <div className="col-lg-6 pl-md pr-md">
//                                     <div>
//                                         <h4>Név</h4>
//                                         <input type="text" className="input-md input-primary wp-100" />
//                                     </div>
//                                 </div>

//                                 <div className="col-lg-6 pl-md pr-md">
//                                     <div>
//                                         <h4>Telefonszám</h4>
//                                         <input type="text" className="input-md input-primary wp-100" />
//                                     </div>
//                                 </div>


//                                 <div className="col-lg-12 pl-md pr-md">
//                                     <h4>Üzenet</h4>
//                                     <textarea className="input-md input-primary minwp-100 maxwp-100 minh-100"></textarea>

//                                     <button className="input-md btn-primary cursor-pointer">
//                                         Küldés
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div>

//                 <div className="p-md col-md-6 text-center">
//                     <div className="box-light-grey p-md row">
//                         <div className="text-center row">
//                             <div className="p-md col-lg-6">
//                                 <h4>Régiók, vágmegyék</h4>
                                
//                                 <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
//                                     {countyRegions.map((region, index) => (
//                                         <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
//                                             {region.regionName}
//                                         </div>
//                                     ))}

// {
//                                     countyRegions.length === 0 && 
//                                         <div className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex jc-center ac-center ai-center color-white p-xs">
//                                             nincs megjelölve régió
//                                         </div>
//                                     }
//                                 </div>
//                             </div>

//                             <div className="p-md col-lg-6">
//                                 <h4>Települések</h4>

//                                 <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
//                                     {settlementRegions.map((region, index) => (
//                                         <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
//                                             {region.regionName}
//                                         </div>
//                                     ))}

//                                     {
//                                         settlementRegions.length === 0 && 
//                                         <div className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex jc-center ac-center ai-center color-white p-xs">
//                                             nincs megjelölve település
//                                         </div>
//                                     }
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Szolgáltatás típusa</div>
//                                     <label>{displayValue(professional?.typeName)}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">E-mail</div>
//                                     <label>{displayValue(professional?.contactEmail)}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Weboldal</div>
//                                     <label>{
//                                         <a target="_blank" href={displayValue(professional?.webpage)}>
//                                             {displayValue(professional?.webpage)}
//                                         </a>}
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Ár</div>
//                                     <label>{`${displayValue(professional?.price)} Ft`}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Ország</div>
//                                     <label>{displayValue(professional?.countryName)}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Megye/körzet</div>
//                                     <label>{displayValue(professional?.countyRegion)}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Település</div>
//                                     <label>{displayValue(professional?.settlement)}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-6">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Utca</div>
//                                     <label>{`${displayValue(professional?.street)} ${displayValue(professional?.publicPlaceType)}`}</label>
//                                 </div>
//                             </div>

//                             <div className="p-md rounded-5 col-lg-12">
//                                 <div className="box-white p-xs rounded-5">
//                                     <div className="font-12">Leírás</div>
//                                     <p>{displayValue(professional?.description)}</p>
//                                 </div>
//                             </div>


//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ProfessionalPagePublic;


import { useParams } from "react-router-dom";
import { sBaseUrl } from "../../app/url";
import { useEffect, useState } from "react";
import Carousel from "../../components/Carousel"; // Ha van képgaléria, használható
import getRegionsByService from "../../app/getRegionsByService";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";

function ProfessionalPagePublic() {
    const { serviceID } = useParams();
    const [professional, setProfessional] = useState(null);
    const [images, setImages] = useState([]);
    const [countyRegions, setCountyRegions] = useState([]);
    const [settlementRegions, setSettlementRegions] = useState([]);

    const getProfessional = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/professionals-public/" + serviceID);
            const json = await response.json();
            setProfessional(json);
        } catch (err) {
            console.log("Error fetching professional data: ", err);
        }
    };

    const getProfessionalImages = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/professional-images/" + serviceID);
            const json = await response.json();
            setImages(json);
        } catch (err) {
            console.log("Error fetching professional images: ", err);
        }
    };

    useEffect(() => {
        getProfessional();
        getProfessionalImages();
        getRegionsByService(serviceID, 1, setCountyRegions);
        getRegionsByService(serviceID, 2, setSettlementRegions);
    }, [serviceID]);

    const displayValue = (value) => (value != null && value !== "" ? value : "-");

    const formatTime = (time) => time ? time.slice(0, 5) : "-";

    const renderOpeningHours = () => {
        if (!professional) return null;
        const daysOfWeek = {
            monday: "Hétfő",
            tuesday: "Kedd",
            wednesday: "Szerda",
            thursday: "Csütörtök",
            friday: "Péntek",
            saturday: "Szombat",
            sunday: "Vasárnap"
        };
    
        return (
            <table className="opening-hours-table">
                <thead>
                    <tr>
                        <th>Nap</th>
                        <th>Nyitás</th>
                        <th>Zárás</th>
                    </tr>
                </thead>
                <tbody>
                    {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day) => {
                        const open = professional[`${day}Open`] === 1;
                        const from = formatTime(professional[`${day}From`]);
                        const to = formatTime(professional[`${day}To`]);
    
                        return (
                            <tr key={day}>
                                <td>{`${daysOfWeek[day]} - ${open ? "nyitva" : "zárva"}`}</td>
                                <td>{open ? from : "-"}</td>
                                <td>{open ? to : "-"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };    

    return (
        <div className="container-xl text-center">
            <div className="row">
                <div className="p-md col-md-6">
                    <div className="box-light-grey p-md">
                        <div className="col-lg-12">
                            <h3>{displayValue(professional?.title)}</h3>
                        </div>
                        {images.length > 0 && <Carousel images={images} />}

                        <div className="p-md col-lg-12">
                            <h3>Kapcsolat</h3>
                            <div className="row">
                                <div className="col-lg-6 pl-md pr-md">
                                    <div>
                                        <h4>Név</h4>
                                        <input type="text" className="input-md input-primary wp-100" />
                                    </div>
                                </div>

                                <div className="col-lg-6 pl-md pr-md">
                                    <div>
                                        <h4>Telefonszám</h4>
                                        <input type="text" className="input-md input-primary wp-100" />
                                    </div>
                                </div>

                                <div className="col-lg-12 pl-md pr-md">
                                    <h4>Üzenet</h4>
                                    <textarea className="input-md input-primary minwp-100 maxwp-100 minh-100"></textarea>
                                    <button className="input-md btn-primary cursor-pointer">Küldés</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-md col-md-6 text-center">
                    <div className="box-light-grey p-md row">
                        <div className="text-center row">
                            <div className="p-md col-lg-6">
                                <h4>Régiók, vármegyék</h4>
                                <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
                                    {countyRegions.map((region, index) => (
                                        <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
                                            {region.regionName}
                                        </div>
                                    ))}
                                    {countyRegions.length === 0 && 
                                        <div className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex jc-center ac-center ai-center color-white p-xs">
                                            nincs megjelölve régió
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="p-md col-lg-6">
                                <h4>Települések</h4>
                                <div className="d-flex mt-3 jc-center f-wrap-wrap box-white p-xs rounded-5">
                                    {settlementRegions.map((region, index) => (
                                        <div key={index} className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex ac-center ai-center color-white p-xs">
                                            {region.regionName}
                                        </div>
                                    ))}
                                    {settlementRegions.length === 0 && 
                                        <div className="btn-sm btn-secondary font-14 rounded-20 mr-sm d-flex jc-center ac-center ai-center color-white p-xs">
                                            nincs megjelölve település
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Szolgáltatás típusa</div>
                                    <label>{displayValue(professional?.typeName)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">E-mail</div>
                                    <label>{displayValue(professional?.contactEmail)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Weboldal</div>
                                    <label>
                                        <a target="_blank" href={displayValue(professional?.webpage)}>
                                            {displayValue(professional?.webpage)}
                                        </a>
                                    </label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Ár</div>
                                    <label>{displayValue(formatHungarianCurrency(professional?.price))}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Ország</div>
                                    <label>{displayValue(professional?.countryName)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Megye/körzet</div>
                                    <label>{displayValue(professional?.countyRegion)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Település</div>
                                    <label>{displayValue(professional?.settlement)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Utca</div>
                                    <label>{`${displayValue(professional?.street)} ${displayValue(professional?.publicPlaceType)}`}</label>
                                </div>
                            </div>
                           
                            <div className="p-md rounded-5 col-lg-12">
                                <div className="box-white p-sm rounded-5">
                                    {renderOpeningHours()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfessionalPagePublic;
