import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import SideMenuUser from '../../components/SideMenuUser';
import getFormData from '../../app/getFormData';
import Validator from '../../app/Validator';
import { sBaseUrl } from '../../app/url';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';
import getType from '../../app/getType';
import ImageThumbnailMaker from '../../components/ImageThumbnailMaker';
import { useNavigate, useParams } from 'react-router-dom';
import ImgThumbnail from '../../components/ImgThumbnail';
import Address from '../../components/Address';
import getData from '../../app/getData';
import ItemSelector from '../../components/ItemSelector';

function RealEstatePage({ isUpdate }) {
    const formRef = useRef(null);
    const mb = useContext(MbContext);
    const [adTypes, setAdTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [buildTechnologies, setBuildTechnologies] = useState([]);
    const [heatTypes, setHeatTypes] = useState([]);
    const [specialLocations, setSpecialLocations] = useState([]);
    const [parkingTypes, setParkingTypes] = useState([]);
    const [viewTypes, setViewTypes] = useState([]);
    const [furneshedTypes, setFurnishedTypes] = useState([]);
    const [electricityTypes, setElectricityTypes] = useState([]);
    const [gasTypes, setGasTypes] = useState([]);
    const [houseTypes, setHouseTypes] = useState([]);
    const [publicPlaceTypes, setPublicPlaceTypes] = useState([]);
    const [selectedSpecialLocations, setSelectedSpecialLocations] = useState([]);
    const [images, setImages] = useState([]);
    const [dbImages, setDbImages] = useState([]);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [priceTitle, setPriceTitle] = useState("ár (ezer forint)");
    const { realEstateID } = useParams();
    const navigate = useNavigate();

    const [realEstateData, setRealEstateData] = useState({
        countryID: 0,
        adType: 0,
        postalCode: '',
        settlement: '',
        street: '',
        publicPlaceType: '',
        houseNumber: '',
        floorNumber: '',
        doorNumber: '',
        price: 0,
        maintenanceFee: 0,
        utilities: 0,
        floorArea: 0,
        siteArea: 0,
        roomNumber: 0,
        isAttic: "",
        isSmartHome: "",
        isGardenContact: "",
        moveImmediately: false,
        hasElevator: false,
        thermallyInsulated: false,
        hasSolarPanel: false,
        solarPanelOutput: 0,
        parkingType: "",
        viewType: "",
        furnishedType: false,
        electricityType: "",
        amper: 0,
        gasType: "",
        houseType: "",
        buildTechnology: "",
        conditionID: 0,
        heatType: "",
        specialLocations: [],
        description: '',
        exchangeDesc: ""
    });

    const uploadImages = async (realEstateID) => {
        const formData = new FormData();

        for (const image of images) {
            formData.append("images", image);
        }

        formData.append("mainImageIndex", mainImageIndex);

        const response = await fetch(sBaseUrl + "/api/upload-property-images/" + realEstateID, {
            method: "POST",
            headers: {
                'authorization': localStorage.getItem("token")
            },
            body: formData
        });

        if (response.ok) {
            navigate("/ingatlan_tulajdonos/ingatlan/" + realEstateID);
            window.location.reload();
        }
    };

    const gatherUploadImages = async (e) => {
        const formData = new FormData();

        for (const image of e.target.files)
            formData.append("images", image);

        formData.append("mainImageIndex", mainImageIndex);

        try {
            const response = await fetch(sBaseUrl + "/api/upload-property-images/" + realEstateID, {
                method: "POST",
                headers: {
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: formData
            });


            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            setDbImages([...dbImages, ...json.imagePaths]);
        } catch (error) {
            console.error('A fájlok feltöltése (update-nél) sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = realEstateData;

            const url = realEstateID ?
                `${sBaseUrl}/api/real-estate/${realEstateID}` :
                `${sBaseUrl}/api/real-estate`;

            if (realEstateID === undefined || realEstateID === null) {
                formData.specialLocations = selectedSpecialLocations;
            }

            const response = await fetch(url, {
                method: realEstateID ? "PUT" : "POST",
                headers: {
                    "content-type": "application/json",
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify(formData)
            });

            const json = await response.json();

            mb.setDisplayMb(true);
            mb.setMessages(json.message);

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if (response.ok && realEstateID === undefined) {
                uploadImages(json.insertID);
            }
        } catch (error) {
            console.error('Ingatlan hirdetés feladása sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getRealEstateByUser = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate/" + realEstateID);

            const json = await response.json();

            setRealEstateData(json);
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getRealEstateImages = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate-images/" + realEstateID);

            const json = await response.json();

            const index = json.findIndex(img => img.isMainImg == 1);
            setMainImageIndex(index);
            setDbImages(json);
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const deleteImg = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate-images/" + imgID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok)
                setDbImages(dbImages.filter(img => img.imgID !== imgID));
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const updateMainImage = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/update-main-image-real-estate/" + imgID, {
                method: "PATCH",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ realEstateID: realEstateID })
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok) {
                const oldMainImgIndex = dbImages.findIndex(img => img.isMainImg === 1);
                const newMainImgIndex = dbImages.findIndex(img => img.imgID === imgID);
                const images = [...dbImages];
                images[oldMainImgIndex].isMainImg = 0;
                images[newMainImgIndex].isMainImg = 1;
                setDbImages(images);
            }
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const addDeleteRelation = async (id, endpoint) => {
        if (realEstateID === undefined || realEstateID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + `/api/${endpoint}/${realEstateID}/${id}`, {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                }
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    useEffect(() => {
        getType("types_ad", setAdTypes);
        getType("countries", setCountries);
        getType("types_condition", setConditions);
        getType("types_build_technology", setBuildTechnologies);
        getType("types_heating", setHeatTypes);
        getType("special_locations", setSpecialLocations);
        getType("types_parking", setParkingTypes);
        getType("types_view", setViewTypes);
        getType("types_furnished", setFurnishedTypes);
        getType("types_electricity", setElectricityTypes);
        getType("types_gas", setGasTypes);
        getType("types_house", setHouseTypes);
        getType("types_public_place", setPublicPlaceTypes);

        if (realEstateID) {
            getRealEstateByUser();
            getRealEstateImages();

            getData("special-locations-by-real-estate/" + realEstateID,
                (json) => setSelectedSpecialLocations(json.map(item => item.locationID))
            );
        }
    }, []);

    useEffect(() => {
        switch (realEstateData.adType) {
            case 1:
                setPriceTitle("ár");
                break;
            case 2:
                setPriceTitle("bérleti díj");
                break;
            case 3:
                setPriceTitle("érték");
                break;
            default:
                setPriceTitle("ár");
                break;
        }
    }, [realEstateData.adType]);

    return (
        <div className="admin-grid">
            <SideMenuUser />
            <div className="p-xl text-center">
                <h2>Ingatlan hirdetés feladása</h2>

                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">
                                <div className="row">

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">A hirdetés típusa *</h5>
                                        <select value={realEstateData.adType ? realEstateData.adType : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, adType: parseInt(e.target.value) })}
                                            name="adType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                adTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">A hirdető típusa *</h5>
                                        <select value={realEstateData.advertiserType ? realEstateData.advertiserType : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, advertiserType: e.target.value })}
                                            name="advertiserType" className="input-md input-primary wp-100">
                                            <option value="">válassz!</option>
                                            <option value="magánszemély">magánszemély</option>
                                            <option value="ingatlanközvetítő">ingatlanközvetítő</option>
                                            <option value="cég">cég</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Lakás/ház típusa*</h5>
                                        <select value={realEstateData.houseType ? realEstateData.houseType : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, houseType: e.target.value })}
                                            name="houseType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                houseTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Az ingatlan állapota *</h5>
                                        <select value={realEstateData.conditionID ? realEstateData.conditionID : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, conditionID: e.target.value })}
                                            name="conditionID" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                conditions.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <Address
                                    formData={realEstateData}
                                    setFormData={setRealEstateData}
                                />

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Emelet</h5>
                                        <input
                                            value={realEstateData.floorNumber ? realEstateData.floorNumber : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, floorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='floorNumber'
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Ajtó</h5>
                                        <input
                                            value={realEstateData.doorNumber ? realEstateData.doorNumber : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, doorNumber: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='doorNumber'
                                        />
                                    </div>
                                </div>



                                <div className="box-white p-xs">
                                    <b>Az alábbi adatokat ezer forintra kerekítve add meg!</b>

                                    <div className="row">
                                        <div className="col-sm-4 p-md">
                                            <h5 className="color-black font-12">{priceTitle}</h5>
                                            <input
                                                value={realEstateData.price ? realEstateData.price : 0}
                                                onChange={(e) => setRealEstateData({ ...realEstateData, price: e.target.value })}
                                                type="number"
                                                className='input-md input-secondary wp-100'
                                                name='price'
                                            />
                                        </div>

                                        <div className="col-sm-4 p-md">
                                            <h5 className="color-black font-12">Fenntartási költség</h5>
                                            <input
                                                value={realEstateData.maintenanceFee ? realEstateData.maintenanceFee : 0}
                                                onChange={(e) => setRealEstateData({ ...realEstateData, maintenanceFee: e.target.value })}
                                                type="number"
                                                className='input-md input-secondary wp-100'
                                                name='maintenanceFee'
                                            />
                                        </div>

                                        <div className="col-sm-4 p-md">
                                            <h5 className="color-black font-12">Közmű díjak havonta</h5>
                                            <input
                                                value={realEstateData.utilities ? realEstateData.utilities : 0}
                                                onChange={(e) => setRealEstateData({ ...realEstateData, utilities: e.target.value })}
                                                type="number"
                                                className='input-md input-secondary wp-100'
                                                name='utilities'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Telek területe m<sup>2</sup></h5>
                                        <input
                                            value={realEstateData.siteArea ? realEstateData.siteArea : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, siteArea: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='siteArea'
                                        />
                                    </div>
                                    
                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Alapterület m<sup>2</sup></h5>
                                        <input
                                            value={realEstateData.floorArea ? realEstateData.floorArea : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, floorArea: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='floorArea'
                                        />
                                    </div>

                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Szobák</h5>
                                        <input
                                            value={realEstateData.roomNumber ? realEstateData.roomNumber : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, roomNumber: parseInt(e.target.value) })}
                                            type="number"
                                            step={1}
                                            className='input-md input-secondary wp-100'
                                            name='roomNumber'
                                        />
                                    </div>

                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Félszobák</h5>
                                        <input
                                            value={realEstateData.halfRoomNumber ? realEstateData.halfRoomNumber : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, halfRoomNumber: parseInt(e.target.value) })}
                                            type="number"
                                            step={1}
                                            className='input-md input-secondary wp-100'
                                            name='halfRoomNumber'
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 p-md">
                                        <h5 className="color-black font-12">Csereingatlan leírása</h5>
                                        <textarea
                                            style={{ minWidth: "100%", maxWidth: "100%", minHeight: "150px" }}
                                            value={realEstateData.exchangeDesc ? realEstateData.exchangeDesc : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, exchangeDesc: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='exchangeDesc'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Építési technológia *</h5>
                                        <select value={realEstateData.buildTechnology ? realEstateData.buildTechnology : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, buildTechnology: e.target.value })}
                                            name="buildTechnology" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                buildTechnologies.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Fűtés típusa *</h5>
                                        <select value={realEstateData.heatType ? realEstateData.heatType : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, heatType: e.target.value })}
                                            name="heatType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                heatTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-12 p-md">
                                    <h4>Elhelyezkedési jellemzők</h4>
                                    <ItemSelector
                                        items={specialLocations}
                                        ids={selectedSpecialLocations}
                                        idsSetter={setSelectedSpecialLocations}
                                        idName="locationID"
                                        itemName="locationName"
                                        cb={(id) => addDeleteRelation(id, "add-delete-special-locations-real-estate")}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Kertkapcsolat</h5>
                                        <select
                                            value={realEstateData.isGardenContact ? realEstateData.isGardenContact : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, isGardenContact: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='isGardenContact'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Igen">Igen</option>
                                            <option value="Nem">Nem</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Költözés azonnal</h5>
                                        <select
                                            value={realEstateData.moveImmediately ? realEstateData.moveImmediately : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, moveImmediately: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='moveImmediately'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Igen">Igen</option>
                                            <option value="Nem">Nem</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Tetőtér</h5>
                                        <select
                                            value={realEstateData.isAttic ? realEstateData.isAttic : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, isAttic: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='isAttic'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Igen">Igen</option>
                                            <option value="Nem">Nem</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-3 p-md">
                                        <h5 className="color-black font-12">Erkély</h5>
                                        <select
                                            value={realEstateData.hasBalcony ? realEstateData.hasBalcony : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, hasBalcony: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='hasBalcony'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Van">Van</option>
                                            <option value="Nincs">Nincs</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Lift</h5>
                                        <select
                                            value={realEstateData.hasElevator ? realEstateData.hasElevator : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, hasElevator: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='hasElevator'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Van">Van</option>
                                            <option value="Nincs">Nincs</option>

                                        </select>
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Hőszigetelt</h5>
                                        <select
                                            value={realEstateData.thermallyInsulated ? realEstateData.thermallyInsulated : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, thermallyInsulated: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='thermallyInsulated'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Igen">Igen</option>
                                            <option value="Nem">Nem</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Okos otthon</h5>
                                        <select
                                            value={realEstateData.isSmartHome ? realEstateData.isSmartHome : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, isSmartHome: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='isSmartHome'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Igen">Igen</option>
                                            <option value="Nem">Nem</option>
                                        </select>
                                    </div>


                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Napelem</h5>
                                        <select
                                            value={realEstateData.hasSolarPanel ? realEstateData.hasSolarPanel : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, hasSolarPanel: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='hasSolarPanel'
                                        >
                                            <option value="">válassz!</option>
                                            <option value="Van">Van</option>
                                            <option value="Nincs">Nincs</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h5 className="color-black font-12">Napelem teljesítménye (kw)</h5>
                                        <input type="number" className="input-md input-primary wp-100"
                                            disabled={realEstateData.hasSolarPanel === "" || realEstateData.hasSolarPanel === "Nincs"}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, solarPanelOutput: e.target.value })}
                                            value={realEstateData.solarPanelOutput ? realEstateData.solarPanelOutput : 0} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Parkolás típusa *</h5>
                                        <select value={realEstateData.parkingType ? realEstateData.parkingType : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, parkingType: e.target.value })}
                                            name="parkingType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                parkingTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Kilátás típusa</h5>
                                        <select value={realEstateData.viewType ? realEstateData.viewType : realEstateData.viewType}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, viewType: e.target.value })}
                                            name="viewType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                viewTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Bútorozás típusa</h5>
                                        <select value={realEstateData.furnishedType ? realEstateData.furnishedType : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, furnishedType: e.target.value })}
                                            name="furnishedType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                furneshedTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Áram típusa</h5>
                                        <select value={realEstateData.electricityType ? realEstateData.electricityType : ""}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, electricityType: e.target.value })}
                                            name="electricityType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                electricityTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Áramerősség</h5>
                                        <input
                                            value={realEstateData.amper ? realEstateData.amper : 0}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, amper: parseInt(e.target.value) })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='amper'
                                        />
                                    </div>

                                    <div className="col-sm-4 p-md">
                                        <h5 className="color-black font-12">Gáz típusa</h5>
                                        <select value={realEstateData.gasType}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, gasType: e.target.value })}
                                            name="gasType" className="input-md input-primary wp-100">
                                            <option value={0}>válassz!</option>
                                            {
                                                gasTypes.map((t, i) =>
                                                    <option key={i} value={t.typeID}>{t.typeName}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 p-md">
                                        <h5 className="color-black font-12">Leírás</h5>
                                        <textarea style={{ minWidth: "100%", maxWidth: "100%", minHeight: "150px" }}
                                            value={realEstateData.description}
                                            onChange={(e) => setRealEstateData({ ...realEstateData, description: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='description'
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    {
                        realEstateID !== undefined &&
                        <div className="p-lg">
                            <div className="box-secondary p-md flex jc-start f-wrap-wrap">
                                {
                                    dbImages.map((img, i) =>
                                        <ImgThumbnail
                                            imgCb={() => updateMainImage(img.imgID)}
                                            key={i} src={`${sBaseUrl}${img.path}`}
                                            width={200} height={200} hasDelete={true}
                                            delCb={() => deleteImg(img.imgID)}
                                            classes={[
                                                "border-primary-2",
                                                "cursor-pointer",
                                                "m-sm",
                                                img.isMainImg ? "border-warning-2" : ""
                                            ]} />
                                    )
                                }
                            </div>
                        </div>
                    }

                    <div className="p-lg">
                        {
                            realEstateID === undefined ? <ImageThumbnailMaker images={images} setImages={setImages}
                                mainImageIndex={mainImageIndex} setMainImageIndex={setMainImageIndex} />
                                :
                                <input
                                    onChange={gatherUploadImages}
                                    type="file"
                                    multiple
                                    accept="image/*" />
                        }
                    </div>

                    <button className='btn-sm btn-secondary' type="submit">
                        {realEstateID ? "Ingatlan módosítása" : "Ingatlan mentése"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default RealEstatePage;