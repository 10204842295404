import storeToken from "./storeToken";
import { sBaseUrl } from "./url";

async function checkPagePermissoin() {
    try {
        const response = await fetch(sBaseUrl + "/api/check-page-permission", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token")
            },
            credentials: "include"
        });

        const json = await response.json();
        
        if(json.token) {
            storeToken(json.token);
        }

        if(!response.ok) {
            localStorage.clear();
            return false;
        }
    } catch (err) {
        return false;
    }

    return true;
}

export default checkPagePermissoin;