import React from 'react';

const ServicePackageForm = ({ formData, setFormData, handleSubmit, packageTypes, packageID }) => {
    return (
        <form className="m-lg" onSubmit={handleSubmit}>
            <div className="box-light-grey p-lg mw-500 margin-auto">
                <h4>Csomag név</h4>
                <input
                    value={formData.packageName}
                    onChange={(e) => setFormData({ ...formData, packageName: e.target.value })}
                    type="text"
                    className='input-md input-secondary wp-100'
                    name='packageName'
                />

                <h3>Csomag típusa</h3>
                <select
                    value={formData.packageType}
                    onChange={(e) => setFormData({ ...formData, packageType: parseInt(e.target.value) })}
                    className="input-md input-secondary wp-100"
                >
                    <option value={0}>Válassz csomag típust!</option>
                    {packageTypes.map((p, i) => (
                        <option key={i} value={p.typeID}>{p.typeName}</option>
                    ))}
                </select>

                <h4>Csomag leírás</h4>
                <textarea
                    value={formData.packageDesc}
                    onChange={(e) => setFormData({ ...formData, packageDesc: e.target.value })}
                    className='input-md input-secondary minwp-100 maxwp-100 minh-100'
                    name='packageDesc'
                />

                <h4>A szolgáltatás időtartama (nap)</h4>
                <input
                    value={formData.intervalDays}
                    onChange={(e) => setFormData({ ...formData, intervalDays: e.target.value })}
                    type="number"
                    className='input-md input-secondary mw-200'
                    name='intervalDays'
                />
            </div>

            <button className='btn-sm btn-secondary' type="submit">
                {packageID ? "Mentés" : "Létrehozás"}
            </button>
        </form>
    );
};

export default ServicePackageForm;