import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { MbContext } from '../../components/Nav';
import SideMenuAdmin from '../../components/SideMenuAdmin';
import getServicePackages from '../../app/getServicePackages';

function ServicePackagesPage() {
    const [servicePackages, setServicePackages] = useState([]);
    const mb = useContext(MbContext);

    const deleteServicePackageConfirm = (packageData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő csomagot: `,
            `${packageData.packageName}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteServicePackage(packageData.packageID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteServicePackage = async (packageID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-packages/" + packageID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setServicePackages(servicePackages.filter(p => p.packageID !== packageID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("ServicePackages.deleteServicePackage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getServicePackages(setServicePackages, mb);
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin/>

            <div className="p-xl text-center">
                <h1>Szolgáltatás Csomagok</h1>

                <Link to="/admin/csomag-letrehozasa">
                    <button className="input-md btn-secondary cursor-pointer">Csomag létrehozása</button>
                </Link>

                <div className="row">
                    {
                        servicePackages.map((p, i) =>
                            <div key={i} className="p-md col-lg-4">
                                <div className="p-md box-secondary">
                                    <h3 className="color-primary">Csomag neve</h3>
                                    <span className="color-light-grey">{p.packageName}</span>

                                    <h3 className="color-primary">Érvényesség ideje</h3>
                                    <span className="color-light-grey">{p.intervalDays} nap</span>

                                    <div className="flex jc-space-around mt-md">
                                        <div>
                                            <Link to={`/admin/csomag-letrehozasa/${p.packageID}`}>
                                                <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                                    icon="fa-solid fa-arrow-up-right-from-square" />
                                            </Link>
                                        </div>

                                        <div>
                                            <FontAwesomeIcon onClick={() => deleteServicePackageConfirm(p)}
                                                className="color-error cursor-pointer font-20"
                                                icon="fa-solid fa-trash" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ServicePackagesPage;