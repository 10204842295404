import React, { useState } from 'react';
import { sBaseUrl } from '../../app/url';
import MessageBox from '../../components/MessageBox';
import { useContext } from 'react';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';

function UpdateRegEmail() {
    const [pass, setPass] = useState('');
    const [email, setEmail] = useState('');
    const mb = useContext(MbContext);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(sBaseUrl + '/api/update-reg-email', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": localStorage.getItem("token")
                },
                credentials: 'include',
                body: JSON.stringify({
                    pass,
                    email
                })
            });

            const data = await response.json();

            if(data.token && data.token.token) 
                storeToken(data.token.token);

            if (response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages("Az email sikeresen frissítve lett!");
                setPass("");
                setEmail("");
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(data);
            }
        } catch (error) {
            console.log('Error updating email:', error);
            mb.setDisplayMb(true);
            mb.setMessages("A szerver nem elérhető. Kérjük, próbálja meg később.");
        }
    };

    return (
        <div className="box-light-grey text-center p-md">
            <h3>Regisztrációs email felülírása</h3>

            <MessageBox
                messages={mb.messages}
                display={mb.displayMb}
                setDisplay={mb.setDisplayMb}
                buttons={mb.buttons}
                setButtons={mb.setButtons}
            />

            <h4>Új email cím</h4>
            <input
                type="email"
                className="input-sm input-primary wp-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <h4>Jelszó</h4>
            <input
                type="password"
                className="input-sm input-primary wp-100"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
            />

            <button onClick={handleSubmit} className="input-sm btn-secondary cursor-pointer">
                Email felülírása
            </button>
        </div>
    );
}

export default UpdateRegEmail;