import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function MessageBox({messages, display, setDisplay, buttons, setButtons}) {
    const [position, setPosition] = useState({
        x:window.innerWidth/2,
        y:window.innerHeight/2
    });
    const [grabbed, setGrabbed] = useState(false);

    // const move = (e)=> {
    //    if(e.nativeEvent.offsetX <= 0
    //     || e.nativeEvent.offsetX >= 300
    //     || e.nativeEvent.offsetY <= 0
    //     || e.nativeEvent.offsetY >= 28) {
    //         setGrabbed(false);
    //     }

    //     if(!grabbed)
    //         return;

    //     setPosition(p=>(
    //         {
    //             ...p,
    //             x:p.x + e.nativeEvent.movementX,
    //             y:p.y + e.nativeEvent.movementY
    //         }
    //     ));
    // };

    window.onmouseup = ()=> {
        setGrabbed(false);
    };

    window.onmousemove = (e)=> {
        if (!grabbed)
            return;

        setPosition(p=>(
            {
                ...p, 
                x:p.x + e.movementX,
                y:p.y + e.movementY
            }
        ));
    };

    window.onresize = ()=> {
        setPosition({
            x:window.innerWidth/2,
            y:window.innerHeight/2
        });
    };

    useEffect(()=> {
        if(!display && setButtons !== undefined && setButtons !== null) {
            setButtons([{
                text: "OK",
                icon: "fa-solid fa-square-check",
                cb: () => setDisplay(false)
            }]);
        }
    }, [display]);

    return(
        <div className="message-box" 
        style={
            {
                display:display ? "block" : "none",
                left:position.x - 150,
                top:position.y - 150
            }
        }>
            <div className="message-box-header"
            onMouseDown={()=>setGrabbed(true)}
            onMouseUp={()=>setGrabbed(false)}>
                <FontAwesomeIcon onClick={()=>setDisplay(false)}
                icon="fa-solid fa-circle-xmark"/>
            </div>
            <div className="message-box-body">
                <div>
                    {
                        typeof messages !== "string" ?
                        messages.map((m, i)=> 
                            <h5 className="color-primary" key={i}>{m}</h5>
                        ) : messages
                    }
                </div>
            </div>
            <div className="message-box-buttons">
                {
                    buttons.map((b, i)=> 
                        <button className="input-md btn-primary cursor-pointer" onClick={b.cb} key={i}>
                            {b.text}
                            <FontAwesomeIcon 
                            className="ml-sm"
                            icon={b.icon}/>
                        </button>
                    )
                }
            </div>
        </div>
    );
}

export default MessageBox;