import { sBaseUrl } from "./url";

async function getType(table, setData) {
    try {
        const response = await fetch(`${sBaseUrl}/api/types/${table}`);
        const json = await response.json();

        if(response.ok)
            setData(json);
        else
            setData([]);
    } catch(err) {
        setData([]);
    }
}

export default getType;