import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { sBaseUrl } from '../../app/url';
import { useLocation, useNavigate } from 'react-router-dom';
import getType from '../../app/getType';
import Accommodation from "./Accommodation";
import queryStringToObj from '../../app/queryStringToObj';
import getUrlVar from '../../app/getUrlVar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination';
import CheckboxList from '../../components/CheckboxList';
import getData from '../../app/getData';

function AccommodationsPagePublic() {
    const [accommodations, setAccommodations] = useState([]);
    const mb = useContext(MbContext);
    const formRef = useRef();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [accommodationTypes, setAccommodationTypes] = useState([]);
    const [accommodationMealPlans, setAccommodationMealPlans] = useState([]);
    const [specialLocations, setSpecialLocations] = useState([]);
    const [roomFacilities, setRoomFacilities] = useState([]);
    const [bathroomFacilities, setBathroomFacilities] = useState([]);
    const [childFriendlyFacilities, setChildFriendlyFacilities] = useState([]);
    const [kitchenFacilities, setKitchenFacilities] = useState([]);
    const [petAllowed, setPetAllowed] = useState([]);
    const location = useLocation();
    const [queryStringData, setQueryStringData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(getUrlVar("page", 1));
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

    // State for CheckboxLists
    const [selectedAccommodationTypes, setSelectedAccommodationTypes] = useState([]);
    const [selectedMealPlans, setSelectedMealPlans] = useState([]);
    const [selectedRoomFacilities, setSelectedRoomFacilities] = useState([]);
    const [selectedBathroomFacilities, setSelectedBathroomFacilities] = useState([]);
    const [selectedChildFriendlyFacilities, setSelectedChildFriendlyFacilities] = useState([]);
    const [selectedSpecialLocations, setSelectedSpecialLocations] = useState([]);
    const [selectedKitchenFacilities, setSelectedKitchenFacilities] = useState([]);

    const search = async (e = null) => {
        e?.preventDefault();

        const qs = e !== null ? new URLSearchParams(queryStringData).toString() : location.search.substring(1);
        setQueryStringData(queryStringToObj(qs));
        searchAccommodations(qs);
    };

    const searchAccommodations = async (qs) => {
        try {
            navigate("/szallasok?" + qs);

            const response = await fetch(sBaseUrl + "/api/search-accommodations?" + qs, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();
            setAccommodations(json.data);
            setTotalPages(json.totalPages);
        } catch (err) {
            console.log("Accommodations.getAccommodations: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getType("countries", setCountries);
        searchAccommodations(location.search.substring(1));
        setQueryStringData(queryStringToObj(location.search));
    }, []);

    useEffect(() => {
        const searchParamsObj = queryStringToObj(location.search);
        searchParamsObj.page = page;
        const sp = new URLSearchParams(searchParamsObj);
        searchAccommodations(sp.toString());
    }, [page]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setQueryStringData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const getAccommodationTypes = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-types");
            const json = await response.json();
            setAccommodationTypes(json);
        } catch (err) {
            console.log("Accommodations.getAccommodations: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getAccommodationMealPlans = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-meal-plans");
            const json = await response.json();
            setAccommodationMealPlans(json);
        } catch (err) {
            console.log("Accommodations.getAccommodations: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const addHighlight = (packageType) => {
        if (packageType === 2) {
            return "border-primary-2 shadow";
        } else if (packageType === 4) {
            return "border-success-2 shadow";
        }
        return "";
    };

    useEffect(() => {
        getAccommodationTypes();
        getAccommodationMealPlans();
        getData("accommodation-room-facilities", setRoomFacilities, mb);
        getData("accommodation-bathroom-facilities", setBathroomFacilities, mb);
        getData("accommodation-child-friendly-facilities", setChildFriendlyFacilities, mb);
        getData("accommodation-special-locations", setSpecialLocations, mb);
        getData("accommodation-kitchen-facilities", setKitchenFacilities, mb);

        setSelectedAccommodationTypes(
            getUrlVar("accommodationType", null) ? 
            getUrlVar("accommodationType")?.split(",").map(at => parseInt(at))
            : []
        );
        
        setSelectedMealPlans(
            getUrlVar("mealPlan", null) ? 
            getUrlVar("mealPlan")?.split(",").map(mp => parseInt(mp))
            : []
        );

        setSelectedRoomFacilities(
            getUrlVar("roomFacilities", null) ? 
            getUrlVar("roomFacilities")?.split(",").map(mp => parseInt(mp))
            : []
        );

        setSelectedBathroomFacilities(
            getUrlVar("bathroomFacilities", null) ? 
            getUrlVar("bathroomFacilities")?.split(",").map(mp => parseInt(mp))
            : []
        );

        setSelectedChildFriendlyFacilities(
            getUrlVar("childFriendlyFacilities", null) ? 
            getUrlVar("childFriendlyFacilities")?.split(",").map(mp => parseInt(mp))
            : []
        );

        setSelectedSpecialLocations(
            getUrlVar("specialLocations", null) ? 
            getUrlVar("specialLocations")?.split(",").map(mp => parseInt(mp))
            : []
        );

        setSelectedKitchenFacilities(
            getUrlVar("kitchenFacilities", null) ? 
            getUrlVar("kitchenFacilities")?.split(",").map(mp => parseInt(mp))
            : []
        );
    }, []);

    useEffect(() => {
        setQueryStringData((prev) => ({
            ...prev,
            accommodationType: selectedAccommodationTypes,
            mealPlan: selectedMealPlans,
            roomFacilities:selectedRoomFacilities,
            bathroomFacilities:selectedBathroomFacilities,
            childFriendlyFacilities:selectedChildFriendlyFacilities,
            specialLocations:selectedSpecialLocations,
            kitchenFacilities:selectedKitchenFacilities
        }));
    }, [
        selectedAccommodationTypes, selectedMealPlans, 
        selectedRoomFacilities, selectedBathroomFacilities,
        selectedChildFriendlyFacilities, selectedSpecialLocations,
        selectedKitchenFacilities
    ]);

    return (
        <div className="container-xl">
            <div className="p-xl text-center">
                <h1>Szállások</h1>
                <form ref={formRef} className="p-md">
                    <div className="box-primary p-md row">
                        <div className="col-lg-2 col-md-6 p-md">
                            <h4 className="color-white">Ország</h4>
                            <select
                                name="country"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.country || ''}
                            >
                                <option value="">Válassz országot</option>
                                {countries.map((t, i) => (
                                    <option key={i} value={t.countryID}>
                                        {t.countryName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-3 col-md-6 p-md">
                            <h4 className="color-white">Régió</h4>
                            <input
                                type="text"
                                placeholder="régió"
                                name="countryRegion"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.countryRegion || ''}
                            />
                        </div>

                        <div className="col-lg-3 col-md-4 p-md">
                            <h4 className="color-white">Település</h4>
                            <input
                                type="text"
                                placeholder="település"
                                name="settlement"
                                className="input-md input-secondary wp-100"
                                onChange={handleInputChange}
                                value={queryStringData.settlement || ''}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 p-md">
                            <CheckboxList
                                optionValues={accommodationTypes}
                                selectedValues={selectedAccommodationTypes}
                                setSelectedValues={setSelectedAccommodationTypes}
                                idFieldName="typeID"
                                textFielsName="typeName"
                                text="Szállás típusa"
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 p-md">
                            <CheckboxList
                                optionValues={accommodationMealPlans}
                                selectedValues={selectedMealPlans}
                                setSelectedValues={setSelectedMealPlans}
                                idFieldName="planID"
                                textFielsName="planName"
                                text="Ellátás"
                            />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="button" onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                                className={"input-md cursor-pointer center-input " + (showAdvancedSearch ? "btn-disabled" : "btn-secondary")}>
                                További keresési feltételek
                            </button>
                        </div>

                        {showAdvancedSearch && (
                            <>
                                <div className="col-lg-4 p-md">
                                    <h4 className="color-white">Nyitvatartás (tól-ig)</h4>
                                    <div className="d-flex">
                                        <input
                                            type="date"
                                            placeholder="nyitva-tól"
                                            style={{ borderRight: 'none' }}
                                            name="openFrom"
                                            className="input-md input-secondary wp-100"
                                            onChange={handleInputChange}
                                            value={queryStringData.openFrom || ''}
                                        />
                                        <input
                                            type="date"
                                            placeholder="nyitva-ig"
                                            name="openTo"
                                            className="input-md input-secondary wp-100"
                                            onChange={handleInputChange}
                                            value={queryStringData.openTo || ''}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 p-md">
                                    <h4 className="color-white">Ár/fő/éj (ezer ft.)</h4>
                                    <div className="d-flex ai-center">
                                        <input
                                            type="number"
                                            placeholder="tól"
                                            name="priceFrom"
                                            className="input-md input-secondary wp-50"
                                            style={{ borderRight: 'none' }}
                                            onChange={handleInputChange}
                                            value={queryStringData.priceFrom || ''}
                                        />
                                        <input
                                            type="number"
                                            placeholder="ig"
                                            name="priceTo"
                                            className="input-md input-secondary wp-50"
                                            onChange={handleInputChange}
                                            value={queryStringData.priceTo || ''}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-6 p-md">
                                    <h4 className="color-white">Minimum éj</h4>
                                    <input
                                        type="number"
                                        placeholder="minimum éj"
                                        name="minimumStay"
                                        className="input-md input-secondary wp-100"
                                        onChange={handleInputChange}
                                        value={queryStringData.minimumStay || ''}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-6 p-md">
                                    <h4 className="color-white">Minimum fő</h4>
                                    <input
                                        type="number"
                                        placeholder="minimum fő"
                                        name="minimumPerson"
                                        className="input-md input-secondary wp-100"
                                        onChange={handleInputChange}
                                        value={queryStringData.minimumPerson || ''}
                                    />
                                </div>

                                 <div className="col-lg-4 p-md">
                                    <CheckboxList
                                        optionValues={roomFacilities}
                                        selectedValues={selectedRoomFacilities}
                                        setSelectedValues={setSelectedRoomFacilities}
                                        idFieldName="facilityID"
                                        textFielsName="facilityName"
                                        text="Szoba jellemzők"
                                    />
                                </div>

                                <div className="col-lg-4 p-md">
                                    <CheckboxList
                                        optionValues={bathroomFacilities}
                                        selectedValues={selectedBathroomFacilities}
                                        setSelectedValues={setSelectedBathroomFacilities}
                                        idFieldName="facilityID"
                                        textFielsName="facilityName"
                                        text="Fürdőszoba jellemzők"
                                    />
                                </div>

                                <div className="col-lg-4 p-md">
                                    <CheckboxList
                                        optionValues={specialLocations}
                                        selectedValues={selectedSpecialLocations}
                                        setSelectedValues={setSelectedSpecialLocations}
                                        idFieldName="locationID"
                                        textFielsName="locationName"
                                        text="Speciális elhelyezkedés"
                                    />
                                </div>

                                <div className="col-lg-4 p-md">
                                    <CheckboxList
                                        optionValues={childFriendlyFacilities}
                                        selectedValues={selectedChildFriendlyFacilities}
                                        setSelectedValues={setSelectedChildFriendlyFacilities}
                                        idFieldName="facilityID"
                                        textFielsName="facilityName"
                                        text="Gyerekbarát jellemzők"
                                    />
                                </div>

                                <div className="col-lg-4 p-md">
                                    <CheckboxList
                                        optionValues={kitchenFacilities}
                                        selectedValues={selectedKitchenFacilities}
                                        setSelectedValues={setSelectedKitchenFacilities}
                                        idFieldName="facilityID"
                                        textFielsName="facilityName"
                                        text="Konyha jellemzők"
                                    />
                                </div>

                                <div className="col-lg-4 p-md">
                                    <h4 className="color-white">Kisállat engedélyezett</h4>

                                    <label className="color-white">
                                        <input type="checkbox" 
                                        name="petAllowed" 
                                        onChange={handleInputChange} /> Igen
                                    </label>
                                </div>
                            </>
                        )}

                        <input type="hidden" name="page" value={page} />
                    </div>

                    <button onClick={(e) => search(e)} className="input-md btn-primary cursor-pointer">Keresés</button>
                </form>

                <div>{page}/{totalPages} oldal</div>

                <div className="row">
                    {accommodations.map((a, i) => (
                        <Accommodation
                            key={i} a={a}
                            highlightCls={addHighlight(a.packageType)}
                        />
                    ))}
                </div>

                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />

            </div>
        </div>
    );
}

export default AccommodationsPagePublic;
