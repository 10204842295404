import getUrlVar from "./getUrlVar";
import { sBaseUrl } from "./url";

class UserHandler {
    constructor() {
        this.activationString = getUrlVar("ac");
        this.errorMsg = "A szolgáltatás jelenleg nem üzemel. Kérlek próbáld meg később!";
    }

    async getUserTypes() {
        try {
            const response = await fetch(`${sBaseUrl}/api/user_types`, {
                method: 'GET'
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch(err) {
            console.log(err);
            return this.errorMs;
        }
    }

    async login(email, pass) {
        try {
            const response = await fetch(`${sBaseUrl}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, pass }),
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            console.log(error);
            return this.errorMs;
        }
    }

    async register(userData) {
        try {
            const response = await fetch(`${sBaseUrl}/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(userData),
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            return this.errorMs;
        }
    }

    async refreshToken() {
        try {
            const response = await fetch(`${sBaseUrl}/api/refresh-token`, {
                method: 'POST',
                credentials: 'include',
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            return this.errorMs;
        }
    }

    async logout() {
        try {
            const response = await fetch(`${sBaseUrl}/api/logout`, {
                method: 'DELETE',
                credentials: 'include',
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            return this.errorMs;
        }
    }

    async checkPagePermission() {
        try {
            const response = await fetch(`${sBaseUrl}/api/check-page-permission`, {
                method: 'GET',
                credentials: 'include',
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            return this.errorMs;
        }
    }

    async activateReg(activationString) {
        try {
            const response = await fetch(`${sBaseUrl}/api/activate-registration/`, {
                method: 'POST',
                headers:{"content-type":"application/json"},
                body:JSON.stringify({activationString}),
                credentials: 'include'
            });

            return {
                ok:response.ok,
                status:response.status,
                json:await response.json()
            }
        } catch (error) {
            return this.errorMs;
        }
    }

    async handleResponse(response) {
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Request failed');
        }

        return response.json();
    }

    handleError(error) {
        console.error('API call failed:', error);
        return { error: error.message || 'API call failed' };
    }
}

export default UserHandler;