import React, { useContext, useEffect, useRef, useState } from 'react';
import { sBaseUrl } from '../../app/url';
import { MbContext } from '../../components/Nav';
import storeToken from '../../app/storeToken';
import SideMenuAccommodation from '../../components/SideMenuAccommodation';
import { useNavigate, useParams } from 'react-router-dom';
import ImageThumbnailMaker from '../../components/ImageThumbnailMaker';
import ImgThumbnail from '../../components/ImgThumbnail';
import DatePicker from '../../components/DatePicker';
import ItemSelector from '../../components/ItemSelector';
import Address from '../../components/Address';
import getData from '../../app/getData';

function AccommodationPage() {
    const formRef = useRef(null);
    const mb = useContext(MbContext);
    const navigate = useNavigate();
    const [mealPlans, setMealPlans] = useState([]);
    const [accommodationTypes, setAccommodationTypes] = useState([]);
    const { accommodationID } = useParams();
    const [images, setImages] = useState([]);
    const [dbImages, setDbImages] = useState([]);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [openMonthFrom, setOpenMonthFrom] = useState(1);
    const [openDayFrom, setOpenDayFrom] = useState(1);
    const [openMonthTo, setOpenMonthTo] = useState(1);
    const [openDayTo, setOpenDayTo] = useState(1);
    const [specialLocations, setSpecialLocations] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [roomFacilities, setRoomFacilities] = useState([]);
    const [bathroomFacilities, setBathroomFacilities] = useState([]);
    const [kitchenFacilities, setKitchenFacilities] = useState([]);
    const [childFriendly, setChildFriendly] = useState([]);
    const [spokenLanguages, setSpokenLanguages] = useState([]);
    const [formData, setFormData] = useState({
        accommodationName: '',
        countryID: 0,
        countyRegion: '',
        postalCode: '',
        settlement: '',
        street: '',
        publicPlaceType: 0,
        houseNumber: "",
        floorArea: 0,
        roomCount: 0,
        capacity: 0,
        extraBed: 0,
        separateKitchen: 0,
        refrigerator: 0,
        separateBathroom: 0,
        separateWC: 0,
        pricePerPerson: 0,
        minimumPerson: 0,
        minimumStay: 0,
        petAllowed: 0,
        petCostPerNight: 0,
        mealPlanID: mealPlans.length > 0 ? mealPlans[0] : 0,
        accommodationTypeID: accommodationTypes.length > 0 ? accommodationTypes[0] : 0
    });
    const [bfIds, setBfIds] = useState([]);
    const [kfIds, setKfIds] = useState([]);
    const [fIds, setFIds] = useState([]);
    const [cfIds, setCfIds] = useState([]);
    const [slIds, setSlIds] = useState([]);
    const [splIds, setSplIds] = useState([]);
    const [rfIds, setRfIds] = useState([]);

    const uploadImages = async (accomodationID) => {
        const formData = new FormData();

        for (const image of images) {
            formData.append("images", image);
        }

        formData.append("mainImageIndex", mainImageIndex);

        const response = await fetch(sBaseUrl + "/api/upload-accommodation-images/" + accomodationID, {
            method: "POST",
            headers: {
                'authorization': localStorage.getItem("token")
            },
            body: formData
        });

        if (response.ok) {
            navigate("/szallasado/szallas_letrehozasa/" + accomodationID);
            window.location.reload();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = sBaseUrl + `/api/accommodations`;
            let method = 'POST';
            const fd = formData;

            if (accommodationID !== undefined && accommodationID !== null) {
                url += `/${accommodationID}`;
                method = 'PUT';
            } else {
                fd.bathroomFacilities = bfIds;
                fd.kitchenFacilities = kfIds;
                fd.facilities = fIds;
                fd.spokenLanguages = slIds;
                fd.roomFacilities = rfIds;
                fd.specialLocations = splIds;
                fd.childFriendlyFacilities = cfIds;
            }

            fd.openDayFrom = openDayFrom;
            fd.openMonthFrom = openMonthFrom;
            fd.openDayTo = openDayTo;
            fd.openMonthTo = openMonthTo;

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: JSON.stringify(fd)
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            if (!response.ok || (response.ok && accommodationID !== undefined)) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }

            if (response.ok && accommodationID === undefined) {
                uploadImages(json.insertID);
            }
        } catch (err) {
            console.error('Szállás hozzáadása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const gatherUploadImages = async (e) => {
        const formData = new FormData();

        for (const image of e.target.files)
            formData.append("images", image);

        formData.append("mainImageIndex", mainImageIndex);

        try {
            const response = await fetch(sBaseUrl + "/api/upload-accommodation-images/" + accommodationID, {
                method: "POST",
                headers: {
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include",
                body: formData
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }

            setDbImages([...dbImages, ...json.imagePaths]);
        } catch (error) {
            console.error('A fájlok feltöltése (update-nél) sikertelen:', error);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const getAccommodation = async () => {
        if (accommodationID === undefined || accommodationID === null)
            return;

        try {
            const url = sBaseUrl + `/api/accommodations/${accommodationID}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();

            setFormData(json);
            setOpenMonthFrom(json.openMonthFrom);
            setOpenDayFrom(json.openDayFrom);
            setOpenMonthTo(json.openMonthTo);
            setOpenDayTo(json.openDayTo);
        } catch (err) {
            console.error('Szállás lekérése sikertelen:', err);
            throw new Error("Nem sikerült lekérni a szállást!");
        }
    };

    const getAccommodationImages = async () => {
        if (accommodationID === undefined || accommodationID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-images/" + accommodationID);

            const json = await response.json();
            const index = json.findIndex(img => img.isMainImg == 1);
            setMainImageIndex(index);
            setDbImages(json);
        } catch (err) {
            console.error("getAccommodationImages", err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const updateMainImage = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/accomodation-main-image/" + imgID, {
                method: "PATCH",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ accommodationID })
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok) {
                const oldMainImgIndex = dbImages.findIndex(img => img.isMainImg === 1);
                const newMainImgIndex = dbImages.findIndex(img => img.imgID === imgID);
                const images = [...dbImages];
                images[oldMainImgIndex].isMainImg = 0;
                images[newMainImgIndex].isMainImg = 1;
                setDbImages(images);
            }
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const deleteImg = async (imgID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-images/" + imgID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (json.token)
                storeToken(json.token);

            if (response.ok)
                setDbImages(dbImages.filter(img => img.imgID !== imgID));
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const deleteAddRelation = async (facilityID, table) => {
        if (accommodationID === undefined || accommodationID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + `/api/add-delete-facility-relation/${accommodationID}/${facilityID}`, {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                body: JSON.stringify({ table })
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    const addDeleteRelation = async (id, endpoint) => {
        if (accommodationID === undefined || accommodationID === null)
            return;

        try {
            const response = await fetch(sBaseUrl + `/api/${endpoint}/${accommodationID}/${id}`, {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                }
            });

            const json = await response.json();

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.error('Ingatlan hirdetés feladása sikertelen:', err);
            mb.setDisplayMb(true);
            mb.setMessages("Nem várt hiba történt!");
        }
    };

    useEffect(() => {
        getData("meal-plans", setMealPlans);
        getData("accommodation-types", setAccommodationTypes);
        getData("accommodation-bathroom-facilities", setBathroomFacilities);
        getData("accommodation-child-friendly-facilities", setChildFriendly);
        getData("accommodation-facilities", setFacilities);
        getData("accommodation-kitchen-facilities", setKitchenFacilities);
        getData("accommodation-room-facilities", setRoomFacilities);
        getData("accommodation-special-locations", setSpecialLocations);
        getData("accommodation-spoken-languages", setSpokenLanguages);
        (async () => {
            await getAccommodation();
            await getAccommodationImages();

            if (accommodationID) {
                await getData("bathroom-facilities-by-accommodation/" + accommodationID,
                    (json) => setBfIds(json.map(item => item.facilityID))
                );

                await getData("kitchen-facilities-by-accommodation/" + accommodationID,
                    (json) => setKfIds(json.map(item => item.facilityID))
                );

                await getData("facilities-by-accommodation/" + accommodationID,
                    (json) => setFIds(json.map(item => item.facilityID))
                );

                await getData("child-friendly-facilities-by-accommodation/" + accommodationID,
                    (json) => setCfIds(json.map(item => item.facilityID))
                );

                await getData("special-locations-by-accommodation/" + accommodationID,
                    (json) => setSlIds(json.map(item => item.locationID))
                );

                await getData("spoken-languages-by-accommodation/" + accommodationID,
                    (json) => setSplIds(json.map(item => item.languageID))
                );

                await getData("room-facilities-by-accommodation/" + accommodationID,
                    (json) => setRfIds(json.map(item => item.facilityID))
                );
            }
        })()
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAccommodation />

            <div className="p-xl text-center">
                <h2>Szállás hozzáadása</h2>
                <form ref={formRef}>
                    <div className="row">
                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">
                                <div className="row">
                                    <div className="col-sm-12 p-md">
                                        <h4>Szállás név</h4>
                                        <input
                                            value={formData.accommodationName}
                                            onChange={(e) => setFormData({ ...formData, accommodationName: e.target.value })}
                                            type="text"
                                            className='input-md input-secondary wp-100'
                                            name='accommodationName'
                                        />
                                    </div>
                                </div>

                                <Address
                                    formData={formData}
                                    setFormData={setFormData}
                                />

                                <div className="row">

                                    <div className="col-sm-12">
                                        <h3>Nyitvatartási időszak</h3>

                                        <div className="row">
                                            <div className="col-sm-6 p-md">
                                                <DatePicker
                                                    selectedMonth={openMonthFrom}
                                                    setSelectedMonth={setOpenMonthFrom}
                                                    selectedDay={openDayFrom}
                                                    setSelectedDay={setOpenDayFrom}
                                                />
                                            </div>
                                            <div className="col-sm-6 p-md">
                                                <DatePicker
                                                    selectedMonth={openMonthTo}
                                                    setSelectedMonth={setOpenMonthTo}
                                                    selectedDay={openDayTo}
                                                    setSelectedDay={setOpenDayTo}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Alapterület (m²)</h4>
                                        <input
                                            value={formData.floorArea}
                                            onChange={(e) => setFormData({ ...formData, floorArea: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='floorArea'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Szobák száma</h4>
                                        <input
                                            value={formData.roomCount}
                                            onChange={(e) => setFormData({ ...formData, roomCount: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='roomCount'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Kapacitás (fő)</h4>
                                        <input
                                            value={formData.capacity}
                                            onChange={(e) => setFormData({ ...formData, capacity: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='capacity'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Ár személyenként (Ft)</h4>
                                        <input
                                            value={formData.pricePerPerson}
                                            onChange={(e) => setFormData({ ...formData, pricePerPerson: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='pricePerPerson'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Minimum tartózkodás (éj)</h4>
                                        <input
                                            value={formData.minimumStay}
                                            onChange={(e) => setFormData({ ...formData, minimumStay: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='minimumStay'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Minimum fő</h4>
                                        <input
                                            value={formData.minimumPerson}
                                            onChange={(e) => setFormData({ ...formData, minimumPerson: e.target.value })}
                                            type="number"
                                            className='input-md input-secondary wp-100'
                                            name='minimumPerson'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Étkezési terv</h4>
                                        <select
                                            value={formData.mealPlanID}
                                            onChange={(e) => setFormData({ ...formData, mealPlanID: e.target.value })}
                                            name="mealPlanID"
                                            className="input-md input-primary wp-100"
                                        >
                                            <option value={0}>Válassz étkezési tervet!</option>
                                            {mealPlans.map((mealPlan, index) => (
                                                <option key={index} value={mealPlan.planID}>
                                                    {mealPlan.planName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Szállás típus</h4>
                                        <select
                                            value={formData.accommodationTypeID}
                                            onChange={(e) => setFormData({ ...formData, accommodationTypeID: e.target.value })}
                                            name="accommodationTypeID"
                                            className="input-md input-primary wp-100"
                                        >
                                            <option value={0}>Válassz szállás típust!</option>
                                            {accommodationTypes.map((type, index) => (
                                                <option key={index} value={type.typeID}>
                                                    {type.typeName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 p-md">
                                        <h4>Extra ágy</h4>
                                        <input
                                            type="checkbox"
                                            checked={formData.extraBed}
                                            onChange={(e) => setFormData({ ...formData, extraBed: e.target.checked ? 1 : 0 })}
                                            className='input-md input-secondary wp-100'
                                            name='extraBed'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Külön konyha</h4>
                                        <input
                                            type="checkbox"
                                            checked={formData.separateKitchen}
                                            onChange={(e) => setFormData({ ...formData, separateKitchen: e.target.checked ? 1 : 0 })}
                                            className='input-md input-secondary wp-100'
                                            name='separateKitchen'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Hűtőszekrény</h4>
                                        <input
                                            type="checkbox"
                                            checked={formData.refrigerator}
                                            onChange={(e) => setFormData({ ...formData, refrigerator: e.target.checked ? 1 : 0 })}
                                            className='input-md input-secondary wp-100'
                                            name='refrigerator'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Külön fürdőszoba</h4>
                                        <input
                                            type="checkbox"
                                            checked={formData.separateBathroom}
                                            onChange={(e) => setFormData({ ...formData, separateBathroom: e.target.checked ? 1 : 0 })}
                                            className='input-md input-secondary wp-100'
                                            name='separateBathroom'
                                        />
                                    </div>
                                    <div className="col-sm-6 p-md">
                                        <h4>Külön WC</h4>
                                        <input
                                            type="checkbox"
                                            checked={formData.separateWC}
                                            onChange={(e) => setFormData({ ...formData, separateWC: e.target.checked ? 1 : 0 })}
                                            className='input-md input-secondary wp-100'
                                            name='separateWC'
                                        />
                                    </div>

                                    <div className="col-sm-6 p-md">
                                        <h4>Háziállat engedélyezett</h4>
                                        <div className="row ai-center">
                                            <div className="col-xs-2">
                                                <input
                                                    type="checkbox"
                                                    checked={formData.petAllowed}
                                                    onChange={(e) => setFormData({ ...formData, petAllowed: e.target.checked ? 1 : 0 })}
                                                    className='input-md input-secondary wp-100'
                                                    name='petAllowed'
                                                />
                                            </div>

                                            <div className="col-xs-10">
                                                <input type="number" disabled={formData.petAllowed === 0}
                                                    onChange={(e) => setFormData({ ...formData, petCostPerNight: parseInt(e.target.value) })}
                                                    value={formData.petCostPerNight}
                                                    className='input-md input-secondary wp-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 p-lg">
                            <div className="box-light-grey p-lg">
                                <div className="row">
                                    <div className="col-sm-12 p-md">
                                        <h4>Leírás</h4>

                                        <textarea style={{ minWidth: "100%", maxWidth: "100%", minHeight: "150px" }}
                                            value={formData.description}
                                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                            className='input-md input-secondary wp-100'
                                            name='description'
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Elhelyezkedési jellemzők</h4>
                                        <ItemSelector
                                            items={specialLocations}
                                            ids={slIds}
                                            idsSetter={setSlIds}
                                            idName="locationID"
                                            itemName="locationName"
                                            cb={(id) => addDeleteRelation(id, "add-delete-special-locations")}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Szállás jellemzők</h4>
                                        <ItemSelector
                                            items={facilities}
                                            ids={fIds}
                                            idsSetter={setFIds}
                                            idName="facilityID"
                                            itemName="facilityName"
                                            cb={(id) => deleteAddRelation(id, "accommodation_facility_relations")}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Szoba jellemzők</h4>
                                        <ItemSelector
                                            items={roomFacilities}
                                            ids={rfIds}
                                            idsSetter={setRfIds}
                                            idName="facilityID"
                                            itemName="facilityName"
                                            cb={(id) => deleteAddRelation(id, "accommodation_room_facility_relations")}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Fürdőszoba jellemzők</h4>
                                        <ItemSelector
                                            items={bathroomFacilities}
                                            ids={bfIds}
                                            idsSetter={setBfIds}
                                            idName="facilityID"
                                            itemName="facilityName"
                                            cb={(id) => deleteAddRelation(id, "accommodation_bathroom_facility_relations")}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Konyha jellemzők</h4>
                                        <ItemSelector
                                            items={kitchenFacilities}
                                            ids={kfIds}
                                            idsSetter={setKfIds}
                                            idName="facilityID"
                                            itemName="facilityName"
                                            cb={(id) => deleteAddRelation(id, "accommodation_kitchen_facility_relations")}
                                        />
                                    </div>
                                    <div className="col-sm-12 p-md">
                                        <h4>Gyerekbarát jellemzők</h4>
                                        <ItemSelector
                                            items={childFriendly}
                                            ids={cfIds}
                                            idsSetter={setCfIds}
                                            idName="facilityID"
                                            itemName="facilityName"
                                            cb={(id) => deleteAddRelation(id, "accommodation_child_friendly_facility_relations")}
                                        />
                                    </div>

                                    <div className="col-sm-12 p-md">
                                        <h4>Beszélt nyelvek</h4>
                                        <ItemSelector
                                            items={spokenLanguages}
                                            ids={splIds}
                                            idsSetter={setSplIds}
                                            idName="languageID"
                                            itemName="languageName"
                                            cb={(id) => addDeleteRelation(id, "add-delete-spoken-languages")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        accommodationID !== undefined &&
                        <div className="p-lg">
                            <div className="box-secondary p-md flex jc-start f-wrap-wrap">
                                {
                                    dbImages.map((img, i) =>
                                        <ImgThumbnail
                                            imgCb={() => updateMainImage(img.imgID)}
                                            key={i} src={`${sBaseUrl}${img.path}`}
                                            width={200} height={200} hasDelete={true}
                                            delCb={() => deleteImg(img.imgID)}
                                            classes={[
                                                "border-primary-2",
                                                "cursor-pointer",
                                                "m-sm",
                                                img.isMainImg ? "border-warning-2" : ""
                                            ]} />
                                    )
                                }
                            </div>
                        </div>
                    }

                    <div className="p-lg">
                        {
                            accommodationID === undefined ?
                                <ImageThumbnailMaker images={images} setImages={setImages}
                                    mainImageIndex={mainImageIndex} setMainImageIndex={setMainImageIndex} />
                                :
                                <input
                                    onChange={gatherUploadImages}
                                    type="file"
                                    multiple
                                    accept="image/*" />
                        }
                    </div>

                    <button onClick={handleSubmit} className='btn-sm btn-secondary'>
                        {accommodationID ? "Adatok felülírása" : "Szállás hozzáadása"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AccommodationPage;