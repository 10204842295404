import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function PasswordInput({ value, onChange, text }) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="position-relative">
            <h3>{text}</h3>
            <input
                type={showPassword ? 'text' : 'password'}
                className='input-md input-secondary wp-90'
                value={value}
                onChange={e => onChange(e.target.value)}
            />
            <FontAwesomeIcon
                className="color-primary cursor-pointer pass-eye"
                icon={showPassword ? faEyeSlash : faEye}
                onMouseDown={togglePasswordVisibility}
                onMouseUp={togglePasswordVisibility}
            />
        </div>
    );
}

export default PasswordInput;