import { useContext, useEffect, useState } from "react";
import getServicePackages from "../app/getServicePackages";
import { useNavigate } from "react-router-dom";
import { MbContext } from "./Nav";
import storeToken from "../app/storeToken";
import { sBaseUrl } from "../app/url";

function OrderServicePackageComp({ data, path, orderedPackages }) {
    const [servicePackages, setServicePackages] = useState([]);
    const [selectedPackageID, setSelectedPackageID] = useState(0);
    const navigate = useNavigate();
    const mb = useContext(MbContext);

    const orderServicePackage = async () => {
        try {
            data.packageID = selectedPackageID;
            console.log(data);
            
            const response = await fetch(sBaseUrl + "/api/service-orders", {
                method: "POST",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(data)
            });

            const json = await response.json();

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);

            if (response.ok) {
                navigate(`/${path}/paypal/${selectedPackageID}/${json.insertID}`);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }
        } catch (err) {
            console.log("orderServicePackage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const orderServicePackageConfirm = () => {
        const packageName = servicePackages.find((sp) => sp.packageID === selectedPackageID);
        if(packageName == undefined)
            return;

        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan megrendeled a következő csomagot: ${packageName.packageName}?`,
        ]);

        mb.setButtons([
            {
                text: "Megrendelés",
                cb: () => orderServicePackage(),
                icon: "fa-solid fa-check"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    useEffect(() => {
        getServicePackages(setServicePackages, mb);
    }, []);

    return (
        <div>
            <select onChange={(e) => setSelectedPackageID(parseInt(e.target.value))}
                className="input-md input-primary">
                <option value={0}>Válassz kiemelést!</option>
                {
                    servicePackages.map((sp, i) => {
                        const isDisabled = orderedPackages.some(op => op.packageID === sp.packageID);

                        return (
                            <option key={i} value={sp.packageID} disabled={isDisabled}>
                                {sp.packageName}
                            </option>
                        );
                    })
                }
            </select>

            <button onClick={orderServicePackageConfirm}
                className={"input-md center-input cursor-pointer "
                    + (selectedPackageID === 0 ? "btn-disabled" : "btn-primary")}
                disabled={selectedPackageID === 0}>
                Megrendelés
            </button>
        </div>
    );
}

export default OrderServicePackageComp;