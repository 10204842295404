import { Link } from "react-router-dom";
import selectMenu from "../app/selectMenu";
import { sBaseUrl } from "../app/url";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PublicNav({pathName, sessionInfo}) {
    const [pagesOnTheMenu, setPagesOnTheMenu] = useState([]);

    const getMenu = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/pages-on-the-menu");
            const json = await response.json();

            if(response.ok) {
                setPagesOnTheMenu(json);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(()=> {
        getMenu();
    }, []);

    return(
        <>
            <li className={selectMenu("/", pathName)}>
                <Link to="/">
                    <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-home" /> Főoldal
                </Link>
            </li>
            <li className={selectMenu("/ingatlanok", pathName)}>
                <Link to="/ingatlanok">
                    <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-building" /> Ingatlanok
                </Link>
            </li>
            <li className={selectMenu("/szallasok", pathName)}>
                <Link to="/szallasok">
                    <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-hotel" /> Szállások
                </Link>
            </li>
            <li className={selectMenu("/szakemberek", pathName)}>
                <Link to="/szakemberek">
                    <FontAwesomeIcon icon="fa-solid fa-toolbox" className="color-primary-lighter"/> Szakemberek
                </Link>
            </li>
            <li className={selectMenu("/bejelentkezes", pathName) + (sessionInfo ? " d-none" : "")}>
                <Link to="/bejelentkezes">
                    <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" className="color-primary-lighter"/> Bejelentkezés
                </Link>
            </li>
            <li className={selectMenu("/regisztracio", pathName) + (sessionInfo ? " d-none" : "")}>
                <Link to="/regisztracio">
                    <FontAwesomeIcon icon="fa-solid fa-user-plus" className="color-primary-lighter"/> Regisztráció
                </Link>
            </li>

            {
                pagesOnTheMenu.map((p,i)=> 
                    <li key={i} className={selectMenu("/" + p.pageUrl, pathName)}>
                        <Link to={"/" + p.pageUrl}>{p.pageName}</Link>
                    </li>
                )
            }
            {
                sessionInfo &&
                <li className={selectMenu(`${sessionInfo.path}/profil`, pathName) + (sessionInfo === null ? " d-none" : "")}>
                    <Link to={`${sessionInfo.path}/profil`}>
                        <FontAwesomeIcon icon="fa-solid fa-user" /> Profil
                    </Link>
                </li>
            }
        </>
    );
}

export default PublicNav;