import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import { sBaseUrl } from '../../app/url';
import Email from './Email';
import storeToken from '../../app/storeToken';
import SideMenuAdmin from '../../components/SideMenuAdmin';

function EmailsPage() {
    const [emails, setEmails] = useState([]);
    const mb = useContext(MbContext);

    const fetchEmails = async (page) => {
        try {
            const response = await fetch(`${sBaseUrl}/api/emails?page=${page}`, {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if(response.ok) {
                setEmails(json);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.log("EmailsPage.fetchEmails: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(()=> {
        fetchEmails();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin/>

            <div className="p-xl text-center">
                <h1>Emailek</h1>

                <div className="row">
                    {emails.map((email, i) => (
                        <Email 
                            key={i} 
                            email={email} 
                            setEmails={setEmails} 
                        />
                    ))}
                </div>

            </div>
        </div>
    );
}

export default EmailsPage;