import { sBaseUrl } from "./url";

const getServicePackages = async (setServicePackages, mb) => {
    try {
        const response = await fetch(sBaseUrl + "/api/service-packages", {
            method: "GET",
            headers: { "authorization": localStorage.getItem("token") },
            credentials: "include"
        });
        const json = await response.json();
        setServicePackages(json);
    } catch (err) {
        console.log("ServicePackages.getServicePackages: ", err);
        setServicePackages([]);
        mb.setDisplayMb(true);
        mb.setMessages("Váratlan hiba történt!");
    }
};

export default getServicePackages;