import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { MbContext } from "../../components/Nav";
import { useContext, useEffect, useState } from "react";
import { sBaseUrl } from "../../app/url";
import storeToken from "../../app/storeToken";
import OrderServicePackageComp from "../../components/OrderServicePackageComp";
import checkImg from "../../app/checkImg";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";

function Property({ p, setProperties, properties, settings = true }) {
    const mb = useContext(MbContext);
    const [orderedPackages, setOrderedPackages] = useState([]);
    const [url, setUrl] = useState("");

    const deleteRealEstateConfirm = (realEstateData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan le akarod törölni a következő ingatlant: `,
            `${realEstateData.postalCode}, ${realEstateData.settlement}, ${realEstateData.street} ${realEstateData.publicPlaceType}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteRealEstate(realEstateData.realEstateID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    }

    const activateDeactivateConfirm = (realEstateData) => {
        mb.setDisplayMb(true);
        const isActive = realEstateData.isActive === 1 ? 0 : 1;
        const msg = isActive === 0 ? "deaktiválni" : "aktiválni";

        mb.setMessages([
            `Biztosan ${msg} akarod a következő ingatlant: `,
            `${realEstateData.postalCode}, ${realEstateData.settlement}, ${realEstateData.street} ${realEstateData.publicPlaceType}?`
        ]);

        mb.setButtons([
            {
                text: isActive === 0 ? "Deaktiválás" : "Aktiválás",
                cb: () => activateDeactivate(realEstateData.realEstateID, isActive),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    }

    const deleteRealEstate = async (realEstateID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate/" + realEstateID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setProperties(properties.filter(p => p.realEstateID != realEstateID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const activateDeactivate = async (realEstateID, isActive) => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estates/is-active/" + realEstateID, {
                method: "PATCH",
                headers: {
                    "authorization": localStorage.getItem("token"),
                    "content-type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ isActive })
            });

            const json = await response.json();

            if (response.ok) {
                const index = properties.findIndex(p => p.realEstateID === realEstateID);
                const ps = [...properties];
                ps[index].isActive = isActive;
                setProperties(ps);
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getOrdersByRealEstate = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-orders-by-real-estate/" + p.realEstateID, {
                method: "GET",
                headers: {
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();
            setOrderedPackages(json);
        } catch (err) {
            console.log("Property.orderServicePackage: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        (async () => {
            await getOrdersByRealEstate();

            setUrl(settings ? "/ingatlan_tulajdonos/ingatlan/" : "/ingatlan/");
        })();
    }, []);

    return (
        <div className='p-md col-lg-4'>
            <div className={"p-md " + (p.isActive === 1 ? "box-secondary" : "box-mid-grey")}>
                <h4 className="color-white">Hirdetés típusa</h4>
                <b className="color-primary">{p.adType}</b>

                <div className="img-holder mt-md">
                    <Link to={url + p.realEstateID}>
                        <img src={checkImg(p.mainImg)} />
                    </Link>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <h4 className="color-white">Irányítószám</h4>
                        <b className="color-primary">{p.postalCode}</b>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <h4 className="color-white">Város</h4>
                        <b className="color-primary">{p.settlement}</b>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <h4 className="color-white">Közterület</h4>
                        <b className="color-primary">{p.street} {p.publicPlaceType} {p.houseNumber}</b>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <h4 className="color-white">Ár/bérleti díj</h4>
                        <b className="color-primary">{formatHungarianCurrency(p.price)}</b>
                    </div>
                </div>

                {
                    settings
                        ?
                        <>
                            <div className="flex jc-space-around mt-md">
                                <div>
                                    <Link to={"/ingatlan_tulajdonos/ingatlan/" + p.realEstateID}>
                                        <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                            icon="fa-solid fa-arrow-up-right-from-square" />
                                    </Link>
                                </div>

                                <div>
                                    <FontAwesomeIcon onClick={
                                        () => activateDeactivateConfirm(p)
                                    }
                                        className={"cursor-pointer font-20 " + (p.isActive === 1 ? "color-warning" : "color-white")}
                                        icon={"fa-solid " + (p.isActive === 1 ? "fa-box-archive" : "fa-power-off")} />
                                </div>

                                <div>
                                    <FontAwesomeIcon onClick={() => deleteRealEstateConfirm(p)}
                                        className="color-error cursor-pointer font-20"
                                        icon="fa-solid fa-trash" />
                                </div>
                            </div>

                            <div>
                                <h4 className="color-white">Szolgáltatások és kiemelések</h4>
                                <div>
                                    <Link target="_blank" className="color-primary" to="/szolgaltatas-csomagok">
                                        <span className="mr-sm">Információ a csomagokról</span>
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                                    </Link>
                                </div>

                                <OrderServicePackageComp
                                    data={{realEstateID:p.realEstateID}}
                                    path={"ingatlan_tulajdonos"}
                                    orderedPackages={orderedPackages}
                                />
                            </div>
                        </>

                        :

                        <div className="p-md">
                            <Link to={url + p.realEstateID}>
                                <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                    icon="fa-solid fa-arrow-up-right-from-square" />
                            </Link>
                        </div>
                }


            </div>
        </div>
    );
}

export default Property;