import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ServiceOrder({order, title, titleName, deleteServiceOrderConfirm}) {
    console.log(order);

    return (
        <div className="p-md col-lg-4 col-md-6">
            <div className="p-md box-secondary">
                <h4 className="color-white">Csomag neve</h4>
                <b className="color-primary">{order.packageName}</b>

                <h4 className="color-white">Érvényesség ideje</h4>
                <b className="color-primary">{order.intervalDays} nap</b>

                <h4 className="color-white">Ár</h4>
                <b className="color-primary">{order.price} Ft</b>

                <h4 className="color-white">{titleName}</h4>
                <b className="color-primary">{title}</b>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <Link to={`/admin/rendeles-modositasa/${order.orderID}`}>
                            <FontAwesomeIcon
                                className="color-success cursor-pointer font-20"
                                icon="fa-solid fa-arrow-up-right-from-square"
                            />
                        </Link>
                    </div>

                    <div>
                        {
                            order.isActive == 1
                                ?
                                <div className="color-white d-flex ai-center">
                                    <FontAwesomeIcon className="font-20 color-success mr-xs"
                                        icon="fa-solid fa-square-check" /> Aktivált
                                </div>
                                :
                                <FontAwesomeIcon
                                    onClick={() => deleteServiceOrderConfirm(order)}
                                    className="color-error cursor-pointer font-20"
                                    icon="fa-solid fa-trash"
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceOrder;