import { sBaseUrl } from "./url";

const getRegionsByService = async (serviceID, regionType, setRegions)=> {
    try {
        const response = await fetch(sBaseUrl + `/api/service-regions/${serviceID}/${regionType}`, {
            method: "GET",
            headers: { authorization: localStorage.getItem("token") },
            credentials: "include"
        });

        const json = await response.json();
        setRegions(json);
    } catch(err) {
        console.error('Régió hozzáadása sikertelen:', err);
    }
};

export default getRegionsByService;