import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import checkImg from "../../app/checkImg";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";

function Accommodation({a, highlightCls}) {
    return (
        <div className='p-md col-lg-3 col-md-4'>
            <div className={"p-md box-secondary " + highlightCls}>
                <h4 className="color-white">Szállás típusa</h4>
                <b className="color-primary">{a.accommodationType}</b>

                <div className="img-holder mt-md">
                    <Link to={"/szallas/" + a.accommodationID}>
                        <img src={checkImg(a.mainImg)} alt={a.name} />
                    </Link>
                </div>

                <h4 className="color-white">Ország</h4>
                <b className="color-primary">{a.countryName}</b>

                <h4 className="color-white">Régió</h4>
                <b className="color-primary">{a.countyRegion !== null ? a.countyRegion : "-"}</b>

                <h4 className="color-white">Település</h4>
                <b className="color-primary">{a.settlement}</b>

                <h4 className="color-white">Ár</h4>
                <b className="color-primary">{formatHungarianCurrency(a.pricePerPerson)}/fő/éj</b>

                <div className="p-md">
                    <Link to={"/szallas/" + a.accommodationID}>
                        <FontAwesomeIcon className="color-success cursor-pointer font-20"
                            icon="fa-solid fa-arrow-up-right-from-square" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Accommodation;