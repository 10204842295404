import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import { Link } from 'react-router-dom';
import { MbContext } from '../../components/Nav';
import SideMenuAccommodation from '../../components/SideMenuAccommodation';
import Accommodation from './Accommodation';

function Accommodations() {
    const [accommodations, setAccommodations] = useState([]);
    const mb = useContext(MbContext);

    const getAccommodationsByUser = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodations-by-user", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });
            const json = await response.json();
            
            if(response.ok) {
                setAccommodations(json);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json);
            }
        } catch (err) {
            console.log("Accommodations.getAccommodationsByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getAccommodationsByUser();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAccommodation/>

            <div className="p-xl text-center">
                <h1>Szállások</h1>

                <Link to="/szallasado/szallas_letrehozasa">
                    <button className="input-md btn-secondary cursor-pointer">Szállás létrehozása</button>
                </Link>

                <div className="row">
                    {
                        accommodations.map((accommodation, i) =>
                            <Accommodation
                                key={i}
                                accommodation={accommodation}
                                accommodations={accommodations}
                                setAccommodations={setAccommodations}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Accommodations;
