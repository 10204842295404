import { useContext, useEffect, useState } from "react";
import SideMenuAccommodation from "../../components/SideMenuAccommodation";
import SideMenuProfessional from "../../components/SideMenuProfessional";
import { MbContext } from "../../components/Nav";
import { sBaseUrl } from "../../app/url";
import { useNavigate, useParams } from "react-router-dom";
import PayPalButton from "../../components/PayPalButton";
import storeToken from "../../app/storeToken";
import SideMenuUser from "../../components/SideMenuUser";

function PayPalPayPage() {
    const sessionInfo = JSON.parse(localStorage.sessionInfo);
    const [sideMenu, setSideMenu] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const {packageID, orderID} = useParams();
    const navigate = useNavigate();
    const mb = useContext(MbContext);

    useEffect(() => {
        console.log()
        switch (sessionInfo.path) {
            case "szallasado":
                setSideMenu(<SideMenuAccommodation/>);
                break;
            case "szakember":
                setSideMenu(<SideMenuProfessional/>);
                break;
            case "ingatlan_tulajdonos":
                setSideMenu(<SideMenuUser/>);
                break;
        }

        mb.setDisplayMb(false);
    }, []);

    const getPackageData = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/service-packages-current-price/" + packageID);
            const json = await response.json();

            if(response.ok) 
                setPackageData(json);
        } catch (err) {
            console.log("PayPalPage.getPackageData: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const activation = async (data)=> {
        try {
            const response = await fetch(sBaseUrl + `/api/service-orders/${orderID}/${data.orderID}/paypal-activation`, {
                method:"PATCH",
                headers:{"authorization": localStorage.getItem("token")}
            });
            const json = await response.json();

            if (json.token && json.token.token !== null) 
                storeToken(json.token.token);

            if(response.ok) 
                navigate(`/${sessionInfo.path}/kiemelesek`);
            else {
                mb.setDisplayMb(true);
                mb.setMessages(json);
            }
        } catch (err) {
            console.log("PayPalPage.activation: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const error = async (err)=> {
        console.log(err);
    };

    useEffect(()=> {
        getPackageData();
    }, []);

    return (
        <div className="admin-grid">
            {sideMenu}

            <div className="text-center">
                <h1>Paypal fizetés</h1>

                <div className="mw-500 box-light-grey p-md margin-auto">
                    <h3>Csomag neve</h3>
                    <h4>{packageData?.packageName}</h4>

                    <h3>Csomag ára</h3>
                    <h4>{packageData?.currentPrice} Ft</h4>

                    <h3>Leírás</h3>
                    <div className="box-white p-md mb-md">
                        {packageData?.packageDesc}
                    </div>

                    {
                        packageData && 
                            <PayPalButton 
                            onApprove={activation}
                            onError={error}
                            price={packageData.currentPrice}
                            currency={"HUF"}
                            isDigitalProduct={packageData.packageType !== 6}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default PayPalPayPage;