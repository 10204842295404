import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';
import getServicePackages from '../../app/getServicePackages';
import orderServicePackage from '../../app/orderServicePackage';
import OrderServicePackageComp from '../../components/OrderServicePackageComp';

const Accommodation = ({ accommodation, accommodations, setAccommodations }) => {
    const mb = useContext(MbContext);
    const [servicePackages, setServicePackages] = useState([]);
    const [orderedPackages, setOrderedPackages] = useState([]);

    const deleteAccommodationConfirm = (accommodationData) => {
        mb.setDisplayMb(true);
        mb.setMessages([
            `Biztosan törölni akarod a következő szállást: `,
            `${accommodationData.name}?`
        ]);

        mb.setButtons([
            {
                text: "Törlés",
                cb: () => deleteAccommodation(accommodationData.accommodationID),
                icon: "fa-solid fa-trash"
            },
            {
                text: "Mégse",
                cb: () => mb.setDisplayMb(false),
                icon: "fa-solid fa-circle-xmark"
            },
        ]);
    };

    const deleteAccommodation = async (accommodationID) => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodations/" + accommodationID, {
                method: "DELETE",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setAccommodations(accommodations.filter(a => a.accommodationID !== accommodationID));
                mb.setDisplayMb(false);
            }

            if (json.token && json.token.token !== null)
                storeToken(json.token.token);
        } catch (err) {
            console.log("Accommodations.deleteAccommodation: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getOrdersByAccommodation = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/service-orders-by-accommodation/" + accommodation.accommodationID, {
                method: "GET",
                headers: {
                    "authorization": localStorage.getItem("token")
                },
                credentials: "include"
            });

            const json = await response.json();
            setOrderedPackages(json);
        } catch (err) {
            console.log("Accommodation.getOrdersByAccommodation: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        (async () => {
            await getOrdersByAccommodation();
            await getServicePackages(setServicePackages, mb);
        })();
    }, []);

    return (
        <div className="p-md col-lg-4">
            <div className="p-md box-secondary">
                <h4 className="color-white">Szállás neve</h4>
                <b className="color-primary">{accommodation.accommodationName}</b>

                <div className="img-holder">
                    <Link to={`/szallasado/szallas_letrehozasa/${accommodation.accommodationID}`}>
                        <img className="mt-md" src={`${sBaseUrl}${accommodation.path}`} />
                    </Link>
                </div>

                <div className="row">
                    <div className="col-xs-6">
                        <h4 className="color-white">Város</h4>
                        <b className="color-primary">{accommodation.settlement}</b>
                    </div>

                    <div className="col-xs-6">
                        <h4 className="color-white">Ár</h4>
                        <b className="color-primary">{accommodation.pricePerPerson} Ft.</b>
                    </div>
                </div>

                <div>
                    <h4 className="color-white">Szolgáltatások és kiemelések</h4>
                    <div>
                        <Link target="_blank" className="color-primary" to="/szolgaltatas-csomagok">
                            <span className="mr-sm">Információ a csomagokról</span>
                            <FontAwesomeIcon icon="fa-solid fa-circle-info" />
                        </Link>
                    </div>

                    <OrderServicePackageComp
                        data={{ accommodationID: accommodation.accommodationID }}
                        path={"szallasado"}
                        orderedPackages={orderedPackages}
                    />
                </div>

                <div className="flex jc-space-around mt-md">
                    <div>
                        <Link to={`/szallasado/szallas_letrehozasa/${accommodation.accommodationID}`}>
                            <FontAwesomeIcon className="color-success cursor-pointer font-20"
                                icon="fa-solid fa-arrow-up-right-from-square" />
                        </Link>
                    </div>

                    <div>
                        <FontAwesomeIcon onClick={() => deleteAccommodationConfirm(accommodation)}
                            className="color-error cursor-pointer font-20"
                            icon="fa-solid fa-trash" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accommodation;