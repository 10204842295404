import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import PublicNav from "./PublicNav";
import checkPagePermissoin from "../app/checkPagePermissoin";
import MessageBox from "./MessageBox";
import Loading from "./Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CookieConsent from "./CookieConsent";

export const MbContext = createContext();

function Nav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [scene, setScene] = useState("public");
    const [messages, setMessages] = useState([]);
    const [displayMb, setDisplayMb] = useState(false);
    const [buttons, setButtons] = useState([{
        text: "OK",
        icon: "fa-solid fa-square-check",
        cb: () => setDisplayMb(false)
    }]);
    const [hideLoading, setHideLoading] = useState(true);
    const [pagePermission, setPagePermission] = useState(true);
    const [sessionInfo, setSessionInfo] = useState({});
    const [hideMenu, setHideMenu] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const urlArray = location.pathname.substring(1).split("/");

        if (!["ingatlan_tulajdonos", "szallasado", "admin", "szakember"].includes(urlArray[0]))
            setScene("public");
        else
            setScene(urlArray[0]);
    }, [location.pathname]);

    useEffect(() => {
        (async () => {
            if (scene !== "public")
                setPagePermission(await checkPagePermissoin());
        })();
    }, [scene]);

    useEffect(() => {
        if (!pagePermission && scene !== "public") {
            navigate("/?sessionExpired=true");
            localStorage.clear();
        }
    }, [pagePermission, scene]);

    useEffect(() => {
        setSessionInfo(JSON.parse(localStorage.getItem("sessionInfo")));

        const originalFetch = window.fetch;

        window.fetch = async function (...args) {
            setHideLoading(false);

            try {
                const response = await originalFetch(...args);
                setHideLoading(true);
                return response;
            } catch (error) {
                setHideLoading(true);
                throw error;
            }
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=> {
        if(windowWidth <= 660) {
            setHideMenu(true);
        } else {
            setHideMenu(false);
        }
    }, [windowWidth]);

    return (
        <>
            <MessageBox
                messages={messages}
                display={displayMb}
                setDisplay={setDisplayMb}
                buttons={buttons}
                setButtons={setButtons}
            />

            <Loading
                hide={hideLoading}
            />

            <div onClick={()=>setHideMenu(!hideMenu)} className="mobile-menu">
                <FontAwesomeIcon icon="fa-solid fa-bars" />
            </div>
            <div id="logo"></div>
            
            <nav className={hideMenu && window.innerWidth <= 660 ? "d-none" : ""}>


                <ul className="flex jc-center" onClick={window.innerWidth <= 660 ? ()=>setHideMenu(true) : ()=>{}}>
                    {
                        scene === "public"
                        &&
                        <PublicNav pathName={location.pathname} sessionInfo={sessionInfo} />
                    }
                </ul>
            </nav>

            <MbContext.Provider value={{
                messages,
                setMessages,
                displayMb,
                setDisplayMb,
                buttons,
                setButtons,
                sessionInfo,
                setHideLoading
            }}>
                {
                    <Outlet />
                }
            </MbContext.Provider>

            <CookieConsent/>
        </>
    );
}

export default Nav;