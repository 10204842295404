import { Link, useLocation } from "react-router-dom";
import selectMenu from "../app/selectMenu";
import Logout from "./Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SideMenuUser() {
    const location = useLocation();

    return(
        <div className="side-menu">
            <ul>
                <li>
                    <Link to="/">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-home" /> Főoldal
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/ingatlan_tulajdonos/profil")}>
                    <Link to="/ingatlan_tulajdonos/profil">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-user" /> Profile
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/ingatlan_tulajdonos/ingatlanok")}>
                    <Link to="/ingatlan_tulajdonos/ingatlanok">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-building" /> Ingatlanok
                    </Link>
                </li>

                <li className={selectMenu(location.pathname, "/ingatlan_tulajdonos/kiemelesek")}>
                    <Link to="/ingatlan_tulajdonos/kiemelesek">
                        <FontAwesomeIcon icon="fa-solid fa-star" className="color-primary-lighter" /> Kiemelések
                    </Link>
                </li>
                
                {
                    <Logout/>
                }
            </ul>
        </div>
    );
}

export default SideMenuUser;