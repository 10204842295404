import { useParams } from "react-router-dom";
import { sBaseUrl } from "../../app/url";
import { MbContext } from "../../components/Nav";
import { useContext, useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import ContactForm from "../common/ContactForm";
import formatHungarianNumber from "../../app/formatHungarianNumber";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";

function RealEstatePage() {
    const { realEstateID } = useParams();
    const mb = useContext(MbContext);
    const [realEstate, setRealEstate] = useState(null);
    const [images, setImages] = useState([]);

    const getRealEstate = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate-public/" + realEstateID);
            const json = await response.json();
            console.log(json);
            setRealEstate(json);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    const getRealEstateImages = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/real-estate-images/" + realEstateID);
            const json = await response.json();
            setImages(json);
        } catch (err) {
            console.log("Properties.getRealEstatesByUser: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getRealEstate();
        getRealEstateImages();
    }, []);

    const displayValue = (value) => (value != null && value !== "" ? value : "-");

    return (
        <div className="container-xl">
            <div className="row">
                <div className="p-md col-md-6">
                    <div className="box-light-grey p-md">
                        <Carousel images={images} />

                        <div className="text-center row">
                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Hirdető</div>
                                    <label>{`${displayValue(realEstate?.lastName)} ${displayValue(realEstate?.firstName)}`}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Telefonszám</div>
                                    <label>{displayValue(realEstate?.mobile)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Vezetékes</div>
                                    <label>{displayValue(realEstate?.tel)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Email</div>
                                    <label>{displayValue(realEstate?.contactEmail)}</label>
                                </div>
                            </div>

                            <div className="p-md col-lg-12">
                                <h3>Kapcsolat</h3>

                                <ContactForm
                                    userID={realEstate?.userID}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-md col-md-6 text-center">
                    <div className="box-light-grey p-md row">
                        <div className="p-md rounded-5 col-lg-4">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Hirdetés típusa</div>
                                <label>{displayValue(realEstate?.adTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-4">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Ország</div>
                                <label>{displayValue(realEstate?.countryName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-4">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Település</div>
                                <label>{displayValue(realEstate?.settlement)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Ár</div>
                                <label>{`${displayValue(formatHungarianNumber(realEstate?.price*1000))} Ft`}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Utca</div>
                                <label>{`${displayValue(realEstate?.street)} ${displayValue(realEstate?.publicPlaceTypeName)}`}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Lakás területe</div>
                                <label>{`${displayValue(realEstate?.floorArea)}`} m<sup>2</sup></label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Telek területe</div>
                                <label>{`${displayValue(realEstate?.siteArea)}`} m<sup>2</sup></label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Szobák</div>
                                <label>{displayValue(realEstate?.roomNumber)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Félszobák</div>
                                <label>{displayValue(realEstate?.halfRoomNumber)}</label>
                            </div>
                        </div>


                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Rezsi</div>
                                <label>{displayValue(formatHungarianCurrency(realEstate?.utilities*1000))}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Fenntartási költség</div>
                                <label>{displayValue(formatHungarianCurrency(realEstate?.maintenanceFee*1000))}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Leírás</div>
                                <p>{displayValue(realEstate?.description)}</p>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Típus</div>
                                <label>{displayValue(realEstate?.houseTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Állapot</div>
                                <label>{displayValue(realEstate?.conditionName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Építési technológia</div>
                                <label>{displayValue(realEstate?.buildTechnologyName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Bútorozott</div>
                                <label>{displayValue(realEstate?.furnishedTypeName)}</label>
                            </div>
                        </div>
                        
                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Fűtés</div>
                                <label>{displayValue(realEstate?.heatTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Gáz</div>
                                <label>{displayValue(realEstate?.gasTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Elektromosság</div>
                                <label>{displayValue(realEstate?.electricityTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Áramerősség</div>
                                <label>{displayValue(realEstate?.amper)} A</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Napelem</div>
                                <label>{displayValue(realEstate?.hasSolarPanel)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Napelem teljesítmény</div>
                                <label>{displayValue(realEstate?.solarPanelOutput)} kW</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Kilátás</div>
                                <label>{displayValue(realEstate?.viewTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Tetőtéri</div>
                                <label>{displayValue(realEstate?.isAttic)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Kertkapcsolat</div>
                                <label>{displayValue(realEstate?.isGardenContact)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Azonnal költözhető</div>
                                <label>{displayValue(realEstate?.moveImmediately)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Parkolás típusa</div>
                                <label>{displayValue(realEstate?.parkingTypeName)}</label>
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-6">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-12">Hőszigetelt</div>
                                <label>{displayValue(realEstate?.thermallyInsulated)}</label>
                            </div>
                        </div>

                        {
                            realEstate?.adType == 3 || realEstate?.exchangeDesc !== null || realEstate?.exchangeDesc.length > 0
                            &&
                            <div className="p-md rounded-5 col-lg-12">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Csereingatlan leírása</div>
                                    <p>{displayValue(realEstate?.exchangeDesc)}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RealEstatePage;
