import { useEffect, useRef, useState } from "react";
import { cBaseUrl, sBaseUrl } from "../../app/url";
import formatHungarianNumber from "../../app/formatHungarianNumber";

function HomePage() {
    const [realEstates, setRealEstates] = useState([]);
    const [accommodations, setAccommodations] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [content, setContent] = useState([]);
    const contentRef = useRef();

    const getHighlightedRealEstates = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/highlighted-real-estates");
            const json = await response.json();
            setRealEstates(json);
        } catch (err) {
            console.log(err);
        }
    };

    const getHighlightedAccommodations = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/highlighted-accommodations");
            const json = await response.json();
            setAccommodations(json);
        } catch (err) {
            console.log(err);
        }
    };

    const getHighlightedServices = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/highlighted-services");
            const json = await response.json();
            setServices(json);
        } catch (err) {
            console.log(err);
        }
    };

    const getHomePage = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/home-page");

            const json = await response.json();
            contentRef.current.innerHTML = json.content ? json.content : "";
            setContent(json);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getHighlightedRealEstates();
        getHomePage();
        getHighlightedAccommodations();
        getHighlightedServices();
    }, []);

    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const sections = shuffleArray([
        { type: "realEstates", items: realEstates },
        { type: "accommodations", items: accommodations },
        { type: "services", items: services },
    ]);

    return (
        <div className="row">
            <div className="col-lg-3"></div>

            <div className="p-md col-lg-6">
                <div className="text-center box-light-grey p-lg">
                    <div ref={contentRef}></div>
                </div>
            </div>

            <div className="col-lg-3 row">
                {sections.map((section, index) => (
                    <div key={index} className={"color-white p-xs text-center col-lg-12 col-md-4 col-sm-6 col-xs-12 " + (section.items.length === 0 ? "d-none" : "")}>
                        {(
                            section.items.map((item, i) => (
                                <div key={i} className="box-primary">
                                    {section.type === "realEstates" && (
                                        <div className="row ai-center font-12">
                                            <div className="col-xs-3 highlighted-img d-flex">
                                                <a target="_blank" rel="noopener noreferrer" href={cBaseUrl + "/ingatlan/" + item.realEstateID}>
                                                    <img src={sBaseUrl + item.mainImg} alt={item.adType} />
                                                </a>
                                            </div>
                                            <div className="col-xs-9">
                                                <b className="color-white">{item.adType} ingatlan</b><br />
                                                <span className="color-white">{item.countryName}, {item.postalCode} {item.settlement}</span><br />
                                                <span className="color-white">
                                                    {item.buildTechnology}
                                                    |{item.floorArea} m <sup>2</sup>|
                                                    {formatHungarianNumber(item.price)} e. Ft
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {section.type === "accommodations" && (
                                        <div className="row ai-center font-12">
                                            <div className="col-xs-3 highlighted-img d-flex">
                                                <a target="_blank" rel="noopener noreferrer" href={cBaseUrl + "/szallas/" + item.accommodationID}>
                                                    <img src={sBaseUrl + item.mainImg} alt={item.adType} />
                                                </a>
                                            </div>
                                            <div className="col-xs-9">
                                                <b className="color-white">{item.accommodationName} ({item.accommodationType})</b>
                                                <br />
                                                <span className="color-white">{item.countryName}, {item.countyRegion}, {item.pricePerPerson} Ft.</span>
                                            </div>
                                        </div>
                                    )}
                                    {section.type === "services" && (
                                        <div className="row ai-center font-12">
                                            <div className="col-xs-3 highlighted-img d-flex">
                                                <a target="_blank" rel="noopener noreferrer" href={cBaseUrl + "/szolgaltatas/" + item.serviceID}>
                                                    <img src={sBaseUrl + item.mainImg} alt={item.adType} />
                                                </a>
                                            </div>
                                            <div className="col-xs-9">
                                                <b className="color-white">{item.title} ({item.serviceType})</b>
                                                <br />
                                                <span className="color-white">{item.countryName}, {item.countyRegion}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomePage;
