import { Link, useLocation } from "react-router-dom";
import Logout from "./Logout";
import selectMenu from "../app/selectMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SideMenuAccommodation() {
    const location = useLocation();

    return(
        <div className="side-menu">
            <ul>
                <li>
                    <Link to="/">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-home" /> Főoldal
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szallasado/profil")}>
                    <Link to="/szallasado/profil">
                        <FontAwesomeIcon icon="fa-solid fa-user" className="color-primary-lighter" /> Profil
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szallasado/szallasaim")}>
                    <Link to="/szallasado/szallasaim">
                        <FontAwesomeIcon icon="fa-solid fa-hotel" className="color-primary-lighter" /> Szállásaim
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szallasado/kiemelesek")}>
                    <Link to="/szallasado/kiemelesek">
                        <FontAwesomeIcon icon="fa-solid fa-star" className="color-primary-lighter" /> Kiemelesek
                    </Link>
                </li>
                <Logout/>
            </ul>
        </div>
    );
}

export default SideMenuAccommodation;