import React, { useState, useEffect, useContext } from 'react';
import MessageBox from '../../components/MessageBox';
import { sBaseUrl } from '../../app/url';
import storeToken from '../../app/storeToken';
import { MbContext } from '../../components/Nav';
import { useNavigate, useParams } from 'react-router-dom';
import TinyMCEEditor from '../../components/TinyMCEEditor';
import SideMenuAdmin from '../../components/SideMenuAdmin';

function PageContentPage() {
    const { pageID } = useParams();
    const navigate = useNavigate();
    const mb = useContext(MbContext);
    const [pageName, setPageName] = useState("");
    const [content, setContent] = useState("");
    const [isHomePage, setIsHomePage] = useState(0);
    const [published, setPublished] = useState(0);
    const [onTheMenu, setOnTheMenu] = useState(0);

    // Submit the form data
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = `${sBaseUrl}/api/page-contents`;

            if (pageID) {
                url += `/${pageID}`;
            }

            const response = await fetch(url, {
                method: pageID ? "PUT" : "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem('token')
                },
                credentials: 'include',
                body: JSON.stringify({
                    pageName, content, isHomePage,
                    onTheMenu, published
                })
            });

            const json = await response.json();

            if (response.ok) {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);

                if (!pageID) {
                    navigate(`/admin/oldal-szerkesztese/${json.insertID}`);
                }
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }

            if (json.token && json.token.token !== null) {
                storeToken(json.token.token);
            }
        } catch (err) {
            console.error("Error handling page content:", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    // Fetch page data if editing an existing page
    const getPageData = async () => {
        if (!pageID) return;

        try {
            const response = await fetch(`${sBaseUrl}/api/page-contents/${pageID}`, {
                method: "GET",
                headers: { authorization: localStorage.getItem("token") },
                credentials: "include"
            });

            const json = await response.json();

            if (response.ok) {
                setPageName(json.pageName);
                setContent(json.content);
                setIsHomePage(json.isHomePage);
                setPublished(json.published);
                setOnTheMenu(json.onTheMenu);
            } else {
                mb.setDisplayMb(true);
                mb.setMessages(json.message);
            }
        } catch (err) {
            console.error("Error fetching page data:", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        if (pageID) {
            getPageData();
        }
    }, [pageID]);

    return (
        <div className="admin-grid">
            <SideMenuAdmin />

            <div className="p-xl text-center">
                <h2>{pageID ? 'Oldal szerkesztése' : 'Új oldal létrehozása'}</h2>

                <form className="mw-800 margin-auto box-light-grey p-md" onSubmit={handleSubmit}>
                    <h4>Oldal neve</h4>
                    <input
                        type="text"
                        name="pageName"
                        value={pageName}
                        onChange={(e)=>setPageName(e.target.value)}
                        className="input-md input-primary wp-100"
                    />

                    <div className="row">
                        <div className="col-lg-4 col-md-4 p-md">
                            <h4>Főoldal</h4>
                            <label>
                                <input type="checkbox"
                                    checked={isHomePage === 1}
                                    onChange={e=>setIsHomePage(e.target.checked ? 1 : 0)}
                                />
                            </label>
                        </div>

                        <div className="col-lg-4 col-md-4 p-md">
                            <h4>Publikálva</h4>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={published === 1}
                                    onChange={(e) => setPublished(e.target.checked ? 1 : 0)}
                                />
                            </label>
                        </div>

                        <div className="col-lg-4 col-md-4 p-md">
                            <h4>A menüszalagon</h4>
                            <label>
                                <input disabled={isHomePage === 1}
                                    type="checkbox"
                                    checked={onTheMenu === 1}
                                    onChange={(e) => setOnTheMenu(e.target.checked ? 1 : 0)}
                                />
                            </label>
                        </div>
                    </div>

                    <h4>Tartalom</h4>

                    <TinyMCEEditor
                        content={content}
                        setContent={setContent}
                    />

                    <button type="submit" className="btn-sm btn-secondary">Mentés</button>
                </form>
            </div>
        </div>
    );
}

export default PageContentPage;