import { useParams } from "react-router-dom";
import { sBaseUrl } from "../../app/url";
import { MbContext } from "../../components/Nav";
import { useContext, useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import getData from "../../app/getData";
import getMonthName from "../../app/getMonthName";
import ContactForm from "../common/ContactForm";
import formatHungarianCurrency from "../../app/formatHungarianCurrency";
import formatPhoneNumber from "../../app/formatPhoneNumber";
import Facilities from "../../components/Facilities";

function AccommodationPagePublic() {
    const { accommodationID } = useParams();
    const mb = useContext(MbContext);
    const [accommodation, setAccommodation] = useState(null);
    const [images, setImages] = useState([]);
    const [bathroomFacilities, setBathroomFacilities] = useState([]);
    const [kitchenFacilities, setKitchenFacilities] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [childFacilities, setChildFacilities] = useState([]);
    const [spokenLanguages, setSpokenLanguages] = useState([]);
    const [specialLocations, setSpecialLocations] = useState([]);
    const [roomFacilities, setRoomFacilities] = useState([]);

    // Function to fetch accommodation data
    const getAccommodation = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-public/" + accommodationID);
            const json = await response.json();
            console.log(json);
            setAccommodation(json);
        } catch (err) {
            console.log("Error fetching accommodation: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    // Function to fetch accommodation images
    const getAccommodationImages = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/accommodation-images/" + accommodationID);
            const json = await response.json();
            setImages(json);
        } catch (err) {
            console.log("Error fetching accommodation images: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getAccommodation();
        getAccommodationImages();
        getData("bathroom-facilities-by-accommodation/" + accommodationID, setBathroomFacilities);
        getData("kitchen-facilities-by-accommodation/" + accommodationID, setKitchenFacilities);
        getData("facilities-by-accommodation/" + accommodationID, setFacilities);
        getData("child-friendly-facilities-by-accommodation/" + accommodationID, setChildFacilities);
        getData("special-locations-by-accommodation/" + accommodationID, setSpecialLocations);
        getData("spoken-languages-by-accommodation/" + accommodationID, setSpokenLanguages);
        getData("room-facilities-by-accommodation/" + accommodationID, setRoomFacilities);
    }, [accommodationID]);

    const displayValue = (value) => (value != null && value !== "" ? value : "-");

    return (
        <div className="container-xl">
            <div className="row">
                <div className="p-md col-md-6">
                    <div className="box-light-grey p-md">
                        <div className="rounded-5 col-lg-12 text-center">
                            <h2>{displayValue(accommodation?.accommodationName)}</h2>
                        </div>

                        <div className="p-md rounded-5 col-lg-12 text-center">
                            <div className="box-white p-xs rounded-5">
                                <label className="mr-xs">{displayValue(accommodation?.countryName)},</label>
                                <label className="mr-xs">{displayValue(accommodation?.postalCode)}</label>
                                <label className="mr-xs">{displayValue(accommodation?.settlement)}</label>
                                <label className="mr-xs">{
                                    accommodation?.countyRegion != "" 
                                    && accommodation?.countyRegion != null
                                    ? accommodation?.countyRegion : ""
                                }</label>
                                <label className="mr-xs">{displayValue(accommodation?.street)}</label>
                                <label className="mr-xs">{displayValue(accommodation?.houseNumber)}</label>
                            </div>
                        </div>

                        <Carousel images={images} />

                        <div className="text-center row">
                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Mobil</div>
                                    <label>{accommodation?.mobile}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Vezetékes telefon</div>
                                    <label>{displayValue(accommodation?.tel)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Email</div>
                                    <label>{displayValue(accommodation?.contactEmail)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Nyitvatartás</div>
                                    <label>
                                        {getMonthName(accommodation?.openMonthFrom) + " " + accommodation?.openDayFrom} 
                                        <span className="mr-sm ml-sm">-</span>
                                        {getMonthName(accommodation?.openMonthTo) + " " + accommodation?.openDayTo}
                                    </label>
                                </div>
                            </div>

                            <div className="p-md col-lg-12">
                                <h3>Kapcsolat</h3>

                                <ContactForm
                                    userID={accommodation?.userID}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-md col-md-6 text-center">
                    <div className="box-light-grey p-md row">
                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <div className="font-14">Leírás</div>
                                <p>{displayValue(accommodation?.description)}</p>
                            </div>
                        </div>

                        <div className="text-center row">
                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Típus</div>
                                    <label>{displayValue(accommodation?.accommodationTypeName)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Ár/fő/éj</div>
                                    <label>{displayValue(formatHungarianCurrency(accommodation?.pricePerPerson))}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Ellátás</div>
                                    <label>{displayValue(accommodation?.mealPlanName)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Minimum maradás</div>
                                    <label>{displayValue(accommodation?.minimumStay + " nap")}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Minimum fő</div>
                                    <label>{displayValue(accommodation?.minimumPerson + " fő")}</label>
                                </div>
                            </div>


                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Kapacitás</div>
                                    <label>{displayValue(accommodation?.capacity)} Fő</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Szobák száma</div>
                                    <label>{displayValue(accommodation?.roomCount)}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Külön konyha</div>
                                    <label>{displayValue(accommodation?.separateKitchen ? "Igen" : "Nem")}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Külön fürdőszoba</div>
                                    <label>{displayValue(accommodation?.separateBathroom ? "Igen" : "Nem")}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Külön mosdó</div>
                                    <label>{displayValue(accommodation?.separateWC ? "Igen" : "Nem")}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Extra ágyak</div>
                                    <label>{displayValue(accommodation?.extraBed ? "Igen" : "Nem")}</label>
                                </div>
                            </div>

                            <div className="p-md rounded-5 col-lg-6">
                                <div className="box-white p-xs rounded-5">
                                    <div className="font-12">Hűtőszekrény</div>
                                    <label>{displayValue(accommodation?.refrigerator ? "Igen" : "Nem")}</label>
                                </div>
                            </div>

                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-sm rounded-5">
                                <Facilities
                                    facilities={facilities}
                                    fKey="facilityName"
                                    fName="Szállás jellemzők"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-sm rounded-5">
                                <Facilities
                                    facilities={specialLocations}
                                    fKey="locationName"
                                    fName="Speciális elhelyezkedés"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-sm rounded-5">
                                <Facilities
                                    facilities={spokenLanguages}
                                    fKey="languageName"
                                    fName="Beszélt nyelvek"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <Facilities
                                    facilities={roomFacilities}
                                    fKey="facilityName"
                                    fName="Szoba jellemzők"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <Facilities
                                    facilities={childFacilities}
                                    fKey="facilityName"
                                    fName="Gyerekbarát jellemzők"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <Facilities
                                    facilities={bathroomFacilities}
                                    fKey="facilityName"
                                    fName="Fürdőszobai jellemzők"
                                />
                            </div>
                        </div>

                        <div className="p-md rounded-5 col-lg-12">
                            <div className="box-white p-xs rounded-5">
                                <Facilities
                                    facilities={kitchenFacilities}
                                    fKey="facilityName"
                                    fName="Konyhai jellemzők"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccommodationPagePublic;