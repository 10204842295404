import React, { useContext, useEffect, useState } from 'react';
import MessageBox from '../../components/MessageBox';
import { MbContext } from '../../components/Nav';
import SideMenuUser from '../../components/SideMenuUser';
import { sBaseUrl } from '../../app/url';
import { Link } from 'react-router-dom';
import PageContent from './PageContent'; // Import the PageContent component
import SideMenuAdmin from '../../components/SideMenuAdmin';

function PageContentsPage() {
    const [pageContents, setPageContents] = useState([]);
    const mb = useContext(MbContext);

    const getPageContents = async () => {
        try {
            const response = await fetch(sBaseUrl + "/api/page-contents", {
                method: "GET",
                headers: { "authorization": localStorage.getItem("token") },
                credentials: "include"
            });
            const json = await response.json();
            
            setPageContents(json);
        } catch (err) {
            console.log("PageContentsPage.getPageContents: ", err);
            mb.setDisplayMb(true);
            mb.setMessages("Váratlan hiba történt!");
        }
    };

    useEffect(() => {
        getPageContents();
    }, []);

    return (
        <div className="admin-grid">
            <SideMenuAdmin />

            <div className="p-xl text-center">
                <h1>Oldaltartalmak</h1>

                <Link to="/admin/oldal-szerkesztese">
                    <button className="input-md btn-secondary cursor-pointer">
                        Oldal hozzáadása
                    </button>
                </Link>

                <div className="row">
                    {
                        pageContents.map((pageContent, i) =>
                            <PageContent
                                key={i}
                                pageContent={pageContent}
                                setPageContents={setPageContents}
                                pageContents={pageContents}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default PageContentsPage;