import React from 'react';
import SideMenuAdmin from '../../components/SideMenuAdmin';
import UpdatePass from '../common/UpdatePass';
import UpdateRegEmail from '../common/UpdateRegEmail';

function AdminProfile() {
    return (
        <div className="admin-grid">
            <SideMenuAdmin/>

            <div className="p-xl text-center">
                <h1>Profil</h1>

                <div className="row mw-800 margin-auto">
                    <div className="col-lg-6 p-md">
                        <UpdateRegEmail/>
                    </div>

                    <div className="col-lg-6 p-md">
                        <UpdatePass/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AdminProfile;