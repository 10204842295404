import { Link, useLocation } from "react-router-dom";
import Logout from "./Logout";
import selectMenu from "../app/selectMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SideMenuProfessional() {
    const location = useLocation();

    return(
        <div className="side-menu">
            <ul>
                <li>
                    <Link to="/">
                        <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-home" /> Főoldal
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szakember/profil")}>
                    <Link to="/szakember/profil">
                        <FontAwesomeIcon icon="fa-solid fa-user" className="color-primary-lighter" /> Profil
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szakember/szolgaltatasaim")}>
                    <Link to="/szakember/szolgaltatasaim">
                        <FontAwesomeIcon icon="fa-solid fa-toolbox" className="color-primary-lighter"/> Szolgáltatásaim
                    </Link>
                </li>
                <li className={selectMenu(location.pathname, "/szakember/kiemelesek")}>
                    <Link to="/szakember/kiemelesek">
                        <FontAwesomeIcon icon="fa-solid fa-star" className="color-primary-lighter" /> Kiemelések
                    </Link>
                </li>
                <Logout/>
            </ul>
        </div>
    );
}

export default SideMenuProfessional;