import { Link, useNavigate } from "react-router-dom";
import { MbContext } from "./Nav";
import { useContext } from "react";
import { sBaseUrl } from "../app/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Logout() {
    const navigate = useNavigate();
    const mb = useContext(MbContext);

    const logout = async ()=> {
        try {
            const response = await fetch(sBaseUrl + "/api/logout", {
                method:"DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':localStorage.getItem("token")
                },
                credentials:"include"
            });
            const json = await response.json();
    
            if(response.ok) {
                navigate("/");
                localStorage.removeItem("sessionInfo");
                localStorage.removeItem("token");
                window.location.reload();
            }
        } catch(err) {
            console.log(err);
            mb.setDisplayMb(true);
            mb.setMessages(["Nem várt hiba történt!"]);
        }
    };

    return(
        <li>
            <Link onClick={logout}>
                <FontAwesomeIcon className="color-primary-lighter" icon="fa-solid fa-right-from-bracket" /> Logout
            </Link>
        </li>
    );
}

export default Logout;